import { DataFeedInstance } from "../../chart-datafeed/udf-compatible-datafeed";

export var common = {
  setNameAndRequiredField: function (name, indicatorTemplate) {
    var metaInfo = indicatorTemplate.metainfo 
    metaInfo['id'] = name + '@tv-basicstudies-1',(metaInfo)._metainfoVersion = 51, metaInfo.name = name, metaInfo['description'] = name, metaInfo['shortDescription'] = name, metaInfo['is_hidden_study'] = false, metaInfo['is_price_study'] = true, metaInfo.isCustomIndicator = true, metaInfo.format = { type: "price", precision: 2 },indicatorTemplate.metainfo = metaInfo;
    indicatorTemplate.constructor.prototype.constructor = indicatorTemplate.constructor
    return indicatorTemplate 
  }, getDefaultStyle: function (plots) {
    let styleMap = { linestyle: 0, visible: true, linewidth: 2, plottype: "2", trackPrice: false }, defaultStyle = {};
    for (var key in plots) styleMap.color = plots[key], defaultStyle[key] = JSON.parse(JSON.stringify(styleMap));
    return defaultStyle;
  }, getStyle: function (plots) {
    let styleMap = { histogramBase: 1 }, style = {};
    for (var key in plots) styleMap.title = key, style[key] = JSON.parse(JSON.stringify(styleMap));
    return style;
  }, getPlots: function (plots) {
    let plotArray = [];
    for (var plotId in plots) {
      let plotObject = { id: "", type: "line" };
      plotObject.id = plotId, plotArray.push(JSON.parse(JSON.stringify(plotObject)));
    }
    return plotArray;
  }, getDateObject: function (_0x3e644a) {
    const dateArray = new Date(_0x3e644a).toString()["split"](" ");
    var timeArray = dateArray[4]["toString"]().split(":"), month;
    let dateObject = {};
    return dateObject["Year"] = parseInt(dateArray[3]), dateObject["Month"] = (month = dateArray[1], ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"].indexOf(month) + 1), dateObject["Day"] = parseInt(dateArray[2]), dateObject["Hour"] = parseInt(timeArray[0]), dateObject["Minute"] = parseInt(timeArray[1]), dateObject;
  }, getUnixTimeStamp: function (time) {
    return time = time.Month + "/" + time.Day + "/" + time.Year + " " + time.Hour + ":" + time.Minute + ":00", Date.parse(time);
  }
}, customIndicator = function () {
  let symbolBars = {};
  let weeklySymbolBars = {};
  function dateMonthYear(date) {
    var dateArray
    return dateArray = new Date(date).toString().split(" "), dateArray[2] + "_" + dateArray[1] + "_" + dateArray[0] + "_" + dateArray[3];
  }
  function weekMonthYear(utcSeconds){
    var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    var dateArray;
    d.setUTCMilliseconds(utcSeconds);
    var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
    return dateArray = new Date(d.setDate(diff)).toString().split(" "), dateArray[2] + "_" + dateArray[1] + "_" + dateArray[0] + "_" + dateArray[3];
  }
  return {
    getIndicatorTemplates: function (PineJS) {
      let templates = [];
      return templates.push(cpr_weekly.getIndicatorTemplates(PineJS), cpr.getIndicatorTemplates(PineJS)), templates.push(ohCL["getIndicatorTemplates"](PineJS)), templates.push(boring["getIndicatorTemplates"](PineJS)), Promise.resolve(templates);
    }, getIndicatorValues: function (symbolInfo) {
      return new Promise((resolve, reject) => {
        var curTime;
        symbolBars['hasOwnProperty'](symbolInfo['ticker']) && weeklySymbolBars['hasOwnProperty'](symbolInfo['ticker']) ? resolve() : (symbolBars[symbolInfo['ticker']] = null,weeklySymbolBars[symbolInfo['ticker']] = null, curTime = Math.round((new Date).getTime() / 1e3), 
        DataFeedInstance._historyProvider.getBars(symbolInfo, "1D",{from: curTime - 15552e3, to: curTime,firstDataRequest: false}).then(response => {
          symbolBars[symbolInfo["ticker"]] = function (bars) {
            let timeWiseBars = {};
            try {
              for (let i = 0; i < bars.length; i++) {
                var barTime = dateMonthYear(bars[i].time), barOpen = bars[i].open, barHigh = bars[i].high, barLow = bars[i].low, barClose = bars[i].close;
                let previousDate;
                previousDate = 0 === i ? NaN : dateMonthYear(bars[i - 1].time), timeWiseBars[barTime] = { open: barOpen, high: barHigh, low: barLow, previousDate: previousDate, close: barClose };
              }
            } catch (error) {
              console.log("Error in convertBarsToObject : ", error);
            }
            return timeWiseBars;
          }(response['bars']);
          return DataFeedInstance._historyProvider.getBars(symbolInfo, "1W",{from: curTime - 15552e3, to: curTime,firstDataRequest: false})
        }).then((response)=>{
          resolve(),weeklySymbolBars[symbolInfo["ticker"]] = function (bars) {
            let timeWiseBars = {};
            try {
              for (let i = 0; i < bars.length; i++) {
                var barTime = weekMonthYear(bars[i].time), barOpen = bars[i].open, barHigh = bars[i].high, barLow = bars[i].low, barClose = bars[i].close;
                let previousDate, previousDateUTC;
                previousDate = 0 === i ? NaN : weekMonthYear(bars[i - 1].time),previousDateUTC = 0 === i ? NaN : bars[i - 1].time, timeWiseBars[barTime] = { open: barOpen, high: barHigh, low: barLow, previousDate: previousDate,previousDateUTC: previousDateUTC,  close: barClose };
              }
            } catch (error) {
              console.log("Error in convertBarsToObject : ", error);
            }
            return timeWiseBars;
          }(response['bars']);
        })["catch"](function (error) {
          console.log("getIndicatorValues : failed to load all bars", error), resolve();
        }));
      });
    }, getMapping: function () {
      return symbolBars;
    }, getWeeklyMapping: function(){
      return weeklySymbolBars
    },getDateMonthYear: dateMonthYear,
    getWeekMonthYear: weekMonthYear,
  };
}(), boring = {
  getIndicatorTemplates: function (PineJS) {
    return common.setNameAndRequiredField("Boring candle", {
      metainfo: { defaults: { palettes: { palette_0: { colors: [{ color: "#0000FF" }] } }, inputs: { "Candle Range <=": 50 } }, inputs: [{ id: "Candle Range <=", name: "Candle Range <=", type: "integer", isHidden: false, defval: 50, max: 95, min: 5 }], plots: [{ id: "plot_0", type: "bar_colorer", palette: "palette_0" }], palettes: { palette_0: { colors: [{ name: "Color 0" }], valToIndex: { 100: 0 } } } }, constructor: function () {
        this.main = function (context, inputCallback) {
          var range,perc
          return this._context = context, (range = (this._input = inputCallback)(0), function (open, high, low, close, range) {
            perc = 100 * Math.abs(open - close) / Math.abs(high - low);
            let bool;
            return bool = perc <= range, bool;
          }(PineJS.Std.open(this._context), PineJS.Std.high(this._context), PineJS.Std.low(this._context), PineJS.Std.close(this._context),range)) ? [100] : [NaN];
        };
      }
    });
  }
}, smi = function(){
  const plots = {}
  return {
    getIndicatorTemplates: function (PineJS) {
      var indicatorTemplate = {
        metainfo: {defaults: {
          inputs: {"BB Length": 20, "BB MultFactor": 2.0, "KC Length": 20, "KC MultFactor": 1.5,"Use TrueRange (KC)": true},
          inputs:[
            {id: "BB Length", name: "BB Length", type: "integer", isHidden: false, defval: 20 },
            {id: "BB MultFactor", name: "BB MultFactor", type: "float", isHidden: false, defval: 2.0 },
            {id: "KC Length", name: "KC Length", type: "integer", isHidden: false, defval: 20 },
            {id: "KC MultFactor", name: "KC MultFactor", type: "float", isHidden: false, defval: 1.5 },
            {id: "Use TrueRange (KC)", name: "Use TrueRange (KC)", type: "boolean", isHidden: false, defval: true }]
      }}}
      return common.setNameAndRequiredField("Squeeze Momentum Indicator", indicatorTemplate);
    }
  }
 
}, cpr = function () {
  const plots = { daily_pivot: "#0000FF", daily_bc: "#0000FF", daily_tc: "#0000FF", daily_s1: "#FF0000", daily_r1: "#008000", daily_s2: "#FF0000", daily_r2: "#008000", daily_s3: "#FF0000", daily_r3: "#008000", daily_s4: "#FF0000", daily_r4: "#008000", previous_day_high: "#FF7F00", previous_day_low: "#FF7F00" };
  return {
    getIndicatorTemplates: function (PineJS) {
      var indicatorTemplate = {
        metainfo: { plots: common.getPlots(plots), defaults: { styles: common['getDefaultStyle'](plots), precision: 2, inputs: {} }, styles: common['getStyle'](plots), inputs: [] }, constructor: function () {
          this.init = function (context, inputCallback) {
            var symbol
            this._context = context, this._input = inputCallback,symbol = PineJS.Std['ticker'](this._context), this._context.new_sym(symbol, PineJS.Std.period(this._context));
          }, this.main = function (context, inputCallback) {
            try {
              this._context = context, this._input = inputCallback, this._context.select_sym(1);
              var time = PineJS.Std.time(this._context);

              if (isNaN(time)) return [NaN];
              var dmyTime = customIndicator.getDateMonthYear(time), symbol = PineJS.Std.ticker(this._context), bar = customIndicator['getMapping']()[symbol][dmyTime];
              var prev_day_high, prev_day_low,prev_day_close,pivot,bottomCPR,s1,r1,s2,r2,s3,r3
              if (!bar) return [NaN];
              var previousDate = bar.previousDate, previousBar = customIndicator['getMapping']()[symbol][previousDate];
              return previousBar ? (prev_day_high = previousBar['high'], prev_day_low = previousBar['low'], prev_day_close = previousBar.close, [pivot = (prev_day_high + prev_day_low + prev_day_close) / 3, bottomCPR = (prev_day_high + prev_day_low) / 2, 2 * pivot - bottomCPR, s1 = 2 * pivot - prev_day_high, r1 = 2 * pivot - prev_day_low, s2 = pivot - prev_day_high + prev_day_low, r2 = pivot + prev_day_high - prev_day_low, s3 = s1 - prev_day_high + prev_day_low, r3 = r1 + prev_day_high - prev_day_low, s3 + s2 - s1, r3 + r2 - r1, prev_day_high, prev_day_low]) : [NaN];
            } catch (error) {
              return console.error("Error in cpr module indicator : ", error), [NaN];
            }
          };
        }
      };
      return common.setNameAndRequiredField("CPR with Daily Pivot levels", indicatorTemplate);
    }
  };
}(),cpr_weekly = function () {
  const plots = { weekly_pivot: "#0000FF", weekly_bc: "#0000FF", weekly_tc: "#0000FF", weekly_s1: "#FF0000", weekly_r1: "#008000", weekly_s2: "#FF0000", weekly_r2: "#008000", weekly_s3: "#FF0000", weekly_r3: "#008000", weekly_s4: "#FF0000", weekly_r4: "#008000", previous_week_high: "#FF7F00", previous_week_low: "#FF7F00" };
  return {
    getIndicatorTemplates: function (PineJS) {
      var indicatorTemplate = {
        metainfo: { plots: common.getPlots(plots), defaults: { styles: common['getDefaultStyle'](plots), precision: 2, inputs: {} }, styles: common['getStyle'](plots), inputs: [] }, constructor: function () {
          this.init = function (context, inputCallback) {
            var symbol
            this._context = context, this._input = inputCallback,symbol = PineJS.Std['ticker'](this._context), this._context.new_sym(symbol, PineJS.Std.period(this._context));
          }, this.main = function (context, inputCallback) {
            try {
              this._context = context, this._input = inputCallback, this._context.select_sym(1);
              
              var time = PineJS.Std.time(this._context);
              if (isNaN(time)) return [NaN];
              var dmyTime = customIndicator.getWeekMonthYear(time), symbol = PineJS.Std.ticker(this._context), bar = customIndicator['getWeeklyMapping']()[symbol][dmyTime];
              var prev_week_high, prev_week_low,prev_week_close,pivot,bottomCPR,s1,r1,s2,r2,s3,r3
              if (!bar) return [NaN];
              var previousDate = bar.previousDate, previousBar = customIndicator['getWeeklyMapping']()[symbol][previousDate];
              var ret_val = previousBar ? (prev_week_high = previousBar['high'], prev_week_low = previousBar['low'], prev_week_close = previousBar.close, [pivot = (prev_week_high + prev_week_low + prev_week_close) / 3, bottomCPR = (prev_week_high + prev_week_low) / 2, 2 * pivot - bottomCPR, s1 = 2 * pivot - prev_week_high, r1 = 2 * pivot - prev_week_low, s2 = pivot - prev_week_high + prev_week_low, r2 = pivot + prev_week_high - prev_week_low, s3 = s1 - prev_week_high + prev_week_low, r3 = r1 + prev_week_high - prev_week_low, s3 + s2 - s1, r3 + r2 - r1, prev_week_high, prev_week_low]) : [NaN];
              return ret_val
            } catch (error) {
              return console.error("Error in cpr module indicator : ", error), [NaN];
            }
          };
        }
      };
      return common.setNameAndRequiredField("CPR with Weekly Pivot levels", indicatorTemplate);
    }
  };
}(), ohCL = function () {
  const plots = { previous_day_high: "#4CAF50", previous_day_close: "#2196F3", previous_day_open: "#00BCD4", previous_day_low: "#FF5252" };
  return {
    getIndicatorTemplates: function (PineJS) {
      var indicatorTemplate = {
        metainfo: { plots: common.getPlots(plots), defaults: { styles: common.getDefaultStyle(plots), precision: 2, inputs: {} }, styles: common.getStyle(plots), inputs: [] }, constructor: function () {
          this.init = function (context, inputCallback) {
            var symbol
            this._context = context, this._input = inputCallback, symbol = PineJS.Std.ticker(this._context), this._context.new_sym(symbol, PineJS.Std.period(this._context));
          }, this.main = function (context, inputCallback) {
            try {
              this._context = context, this._input = inputCallback, this._context.select_sym(1);
              var time = PineJS.Std.time(this._context);
              var prev_day_high, prev_day_low,prev_day_close,prev_day_open
              if (isNaN(time)) return [NaN];
              var curDate = customIndicator.getDateMonthYear(time), symbol = PineJS.Std.ticker(this._context), curMapping = customIndicator["getMapping"]()[symbol][curDate];
              if (!curMapping) return [NaN];
              var previousDate = curMapping.previousDate,prev_day_high, prevMapping = customIndicator["getMapping"]()[symbol][previousDate];
              return prevMapping ? (prev_day_high = prevMapping.high, prev_day_low = prevMapping.low, prev_day_close = prevMapping.close, prev_day_open = prevMapping.open, [prev_day_high, prev_day_close, prev_day_open, prev_day_low]) : [NaN];
            } catch (error) {
              return console.log("Error ohcl module indicator: ", error), [NaN];
            }
          };
        }
      };
      return common.setNameAndRequiredField("Previous day OHLC", indicatorTemplate);
    }
  };
}();