import {BehaviorSubject, Subject} from 'rxjs';

const subject = new BehaviorSubject(0);

export const SpotService = {
    sendSpot: data => subject.next(data),
    getSpot: () => subject.asObservable()
};


// PEIO

let maxIoPE = 0
const subjectIoPE = new BehaviorSubject(0);
export const peIoService = {
    sendMaxIo: data => {
        if(data > maxIoPE){
            maxIoPE = data
            subjectIoPE.next(maxIoPE)
        }
    },
    getSubIo: () => subjectIoPE.asObservable(),
    resetIoPE: () => {
        maxIoPE = 0
        subjectIoPE.next(0)
    }
};

// CEIO

let maxIoCE = 0
const subjectIoCE = new BehaviorSubject(0);
export const ceIoService = {
    sendMaxIo: data => {
        if(data > maxIoCE){
            maxIoCE = data
            subjectIoCE.next(maxIoCE)
        }
    },
    getSubIo: () => subjectIoCE.asObservable(),
    resetIoCE: () => {
        maxIoCE = 0
        subjectIoCE.next(0)
    }
};

// PE

let maxVolumePE = 0
const subjectPE = new BehaviorSubject(0);
export const peService = {
    sendMax: data => {
        if(data > maxVolumePE){
            maxVolumePE = data
            subjectPE.next(maxVolumePE)
        }
    },
    getSub: () => subjectPE.asObservable(),
    resetVolumePE: () => {
        maxVolumePE = 0
        subjectPE.next(0)
    }
};

//   CE

let maxVolumeCE = 0
const subjectCE = new BehaviorSubject(0);
export const ceService = {
    sendMax: data => {
        if(data > maxVolumeCE){
            maxVolumeCE = data
            // console.log("mxCE",maxVolumeCE);
            subjectCE.next(maxVolumeCE)
        }
    },
    getSub: () => subjectCE.asObservable(),
    resetVolumeCE: () => {
        maxVolumeCE = 0
        subjectCE.next(0)
    }
};


//   BuySellService

let buySellData = {model_open: false,data: null}
const subjectBuySell = new BehaviorSubject(buySellData)
export const BuySellServices = {
    setBuySell: data => {
       let val = {model_open: true,data: data}
        subjectBuySell.next(val)
    },
    getSub: () => subjectBuySell.asObservable(),
    clearBuySell: () => {
        subjectBuySell.next(buySellData)
    }
};


// layOut subServices
const layoutSub = new BehaviorSubject(null)
export const layoutSubServices = {
    setLayOut: data => {
        layoutSub.next(data)
    },
    getSub: () => layoutSub.asObservable(),
};


const OrederSub = new Subject(null)
export const OrderOMSFeedSub = {
    NotifyOrder: data => {
        OrederSub.next(data)
    },
    OrderListener: () => OrederSub.asObservable(),
};

const ResponceStatusSubject = new Subject({});
export const ResponceStatusService = {
    sendRes: data => subject.next(data),
    getSub: () => subject.asObservable()
};
