import { Subject} from 'rxjs';


const BasketAddSub = new Subject(null)
export const basketSubServices = {
    setNewBasket: data => {
        BasketAddSub.next(data)
    },
    getSub: () => BasketAddSub.asObservable(),
};
