import React from 'react';
import heart from "../../assets/images/heart-circle.svg";

const MadeInLoveIndia = () => {
    return (
        <div className=""> Made with <img width={25} src={heart} alt=''/> in India </div>
    );
};

export default MadeInLoveIndia;
