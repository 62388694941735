import React, { useEffect,useState } from 'react';
import {useDispatch,useSelector} from "react-redux";
import {setBuilderSortBy,setGlobalStrategyTypeFilters,setGlobalStrategyTypeList,setBuilderDetails,setBuilderStrategyList,setGlobalStrategyNameFilters,setBuilderStrategyDetails,setStrategyState,setCurrentMultilier,setKeepDefaultindex, setRequiredGlobalMargin,setInitialGlobalMargin} from "../../../slices/optionStrategySlice";
import {DataFeedInstance} from "../../../chart-datafeed/udf-compatible-datafeed";
import {SpotService} from "../../../sub";
import {NumberColor} from "../../common/number_color";

const HeaderSubscribedData=({token,segmentId,symbol='',description='',exchange='',location="header",getLtp='',callGetLtp=false})=>{
    const {current_strategy_state} = useSelector((state) => state.optionStrategy);

    const [ subData, setSubData ] = useState(null);
    const [value,setValue]=useState(0)

    const dispatch = useDispatch();

    useEffect(()=>{

        if(callGetLtp) getLtp(subData?subData.lp:0)
    },[value])

    useEffect(() => {
        headerData()
        return () => {
            removeSub()
        }
    }, [token])

    let listenerGuid = undefined
    function headerData() {
        listenerGuid = `top-${token}-${segmentId}`
        DataFeedInstance.subcscribeQuotesFromTokens([
            {market_segment_id: segmentId , token: token }],(quotes)=>{
            setSubData(quotes[0].v)
            setValue(1)
        },listenerGuid)
    }

    function removeSub(){
        DataFeedInstance.unsubscribeQuotes(listenerGuid)
    }
    const onBackClick =()=>{
        dispatch(setRequiredGlobalMargin(null))
        dispatch(setInitialGlobalMargin(null))
        if(current_strategy_state.url=="strategyDetails"){
            let state = {id:3,url:'allStrategies'}
            dispatch(setKeepDefaultindex(true))
            dispatch(setStrategyState(state))
            dispatch(setCurrentMultilier(1))
        }else{
            let state = {id:3,url:'allBuilder'}
            dispatch(setKeepDefaultindex(true))
            dispatch(setStrategyState(state))
            dispatch(setCurrentMultilier(1))
            dispatch(setBuilderStrategyDetails(null))
            dispatch(setGlobalStrategyNameFilters(null))
            dispatch(setBuilderStrategyList(null))
            dispatch(setBuilderDetails(null))
            dispatch(setGlobalStrategyTypeList(null))
            dispatch(setGlobalStrategyTypeFilters(null))
            dispatch(setBuilderSortBy(null))
        }
    }
    return (
        <React.Fragment>
            {location=="header" && (
                <React.Fragment>
                <div className='flex headerText'> <i onClick={onBackClick} className="fa fa-arrow-left c-pointer" aria-hidden="true"/>
                    <span className='pl-3'>{symbol}</span>&nbsp;<span className='exc' style={{"top":"27px"}}>{exchange}</span>
                </div>
                <div className='desc' style={{"position":"absolute","left":"49px","top":"46px"}}>{description}</div>
                <div className='stltp ltp'>
                    <div>₹ {subData?subData.lp.toFixed(2):0}</div>
                    <div><NumberColor number={subData?subData.chp:0} returnSign={true}/>&nbsp;<NumberColor number={subData?subData.ch.toFixed(2):0}/>&nbsp;<NumberColor number={subData?subData.chp.toFixed(2):0} returnBracket={true} percentageAdd={true}/></div>
                </div>
            </React.Fragment>
            )}
            {location=="analysis" && (
                <div className='currentPrice'>Current Price : {subData?subData.lp.toFixed(2):0}</div>
            )}

            {location=="strategy" && (
                <React.Fragment>
                    <div className='strategyLtp' style={{"paddingTop":"12px","paddingRight":"14px"}}>{subData?subData.lp.toFixed(2):0}</div>
                    <div className='strategyLtp'><NumberColor number={subData?subData.chp.toFixed(2):0} returnBracket={false} percentageAdd={true}/></div>
                </React.Fragment>

            )}
        </React.Fragment>
        )   
}

export default HeaderSubscribedData
