import React, {useEffect,useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {CloseOptionModel } from "../../slices/optionChainSlice";
import {DataFeedInstance} from "../../chart-datafeed/udf-compatible-datafeed";
import {currentUser} from "../../slices/authSlice";
import authService from "../../services/authService";
import {numDifferentiation, segmentToInstrumentType} from "../../comman/status";
import nextId from "react-id-generator";
import './optionModel.scss'
import CEGroup from "./CEGroup";
import PEGroup from "./PEGroup";
import TopSymbolSub from "./TopSymbolSub";
import HiddenTable from "./HiddenTable";
import moment from 'moment'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Draggable from "react-draggable";



const OptionModel = ({optionData}) => {

    // console.log("OPT",optionData)

    const { model_open,option_key,socket_open,user } = optionData
    const dispatch = useDispatch();
    // const user = useSelector(state => state.authUser.user);
    const [ OptionData, setOptionData ] = useState(null)
    const [ keys, setKeys ] = useState([])
    const [ Selkeys, setSelkeys ] = useState(null)
    const [ SelectedData, setSelectedData ] = useState([])
   

    useEffect(() => {
    }, [OptionData])



    useEffect(() => {

    }, [Selkeys,keys.value])


    useEffect(() => {
        if (option_key) showOrderDialog()
        return () => {
        }
    }, [])


    useEffect(() => {
        if(socket_open === false) onModelClose();
    }, [socket_open])



    function showOrderDialog(){
        DataFeedInstance.resolveSymbol(option_key, (symbolInfo)=>{
            var instrumentType = segmentToInstrumentType(symbolInfo.odin_exchange)
            var scrip_name = symbolInfo.odin_symbol
            getOptionData(instrumentType,parseInt(symbolInfo.odin_token),scrip_name)
        }, undefined, undefined)

    }

    function setSelectedDataFunction(key,data){
        setSelkeys(key)
       setSelectedData(data.data[key])
    }


     function extractDataSet(data) {
        const keysData = Object.entries(data.data).map(([key, value]) =>  {return key});
        setSelectedDataFunction(keysData[0],data);
         setSelkeys(keysData[0])
         MakeDateFormate(keysData)
         setOptionData(data)
    }

    function MakeDateFormate(keysData = []) {
     let val = keysData.map(d => {
            let now = moment(d, "YYYYMMDD").format('DD MMM');
            return {
                key: now,
                value: d,
            }
        })
        setKeys(val)
    }

    function getOptionData(instrumentType,scrip_name) {

        const data = {symbol: scrip_name,instrumentType: instrumentType}
        authService.optionChaindata(data,user.access_token).then(value => {
            return value.data.response
        }).then((data)=>{
           extractDataSet(data)
        }).catch(reason => {
            onModelClose()
        })

    }
    

    const onModelClose = () => {
        dispatch(CloseOptionModel())
    }

    const makeSelectedData = (d) => {
        setSelectedDataFunction(d.value,OptionData)
    }



    function getTableData() {
        
        // getMessage()
        const marketSegment = OptionData.parrentStockData.marketSegmentId;

        return SelectedData.map( (d,index) =>  {
            let strikePrice = d.strikePrice / 100

            if(marketSegment === '13' || marketSegment === '14'){
                strikePrice = d.strikePrice / 10000000
            }

            const id1 = nextId("R-");
            const id2 = nextId("PE-");
            const id3 = nextId("CE-");
            const id4 = nextId("ST-");

            return (
                <tr id={strikePrice} key={id1}>
                    <CEGroup key={id3} data={d.CE} price={strikePrice}/>
                    <td key={id4} className="strike_price">{strikePrice}</td>
                    <PEGroup key={id2} data={d.PE} price={strikePrice}/>
                </tr>


            );
        });

    }

    function getStyle() {
       let data = {}
        model_open ?  data = { display: 'block', visibility: 'visible' } : data = { display: 'none', visibility: 'hidden' }
      return data
    }

    const handleChange = (e) => {
        makeSelectedData(e.target)
    }

    function getHeaderData() {

        if(keys.length > 5){
            return (


                <form className="d-flex flex-row w-100 justify-content-start">

                    <div className="">Select Date: </div>
                    <div className="ml-1">
                        <select  id="" className="form-select form-select-sm" value={Selkeys} onChange={handleChange}>
                            {
                                keys.map(d => {
                                    return(
                                        <option value={d.value}>{d.key}</option>
                                    )
                                })
                            }
                        </select>
                    </div>

                </form>
            );
        }else {
            return keys.map(d => {
                return(
                    <div onClick={ () => makeSelectedData(d)} className={ (d.value === Selkeys) ? 'ml-2 mt-1 thumbnail text_dark':'ml-2 mt-1 thumbnail'}>{d.key}</div>
                );
            });
        }

    }

    function getMessage() {
      return 'close'
    }

    return (

        <Draggable
            handle=".option"
            scale={1}
            bounds="parent"
            allowAnyClick={true}>
            <div className="custom-order-dialog_option" id="" style={getStyle()}>

                {/*<button onClick={onModelClose}>{getMessage()}</button>*/}

                <div className="d-flex flex-row w-100 position-relative option">
                    { OptionData  ? <TopSymbolSub data={OptionData.parrentStockData}/> : null}
                </div>


                <div className="d-flex flex-row padding_from_heading justify-content-between option">
                    <div className="d-flex flex-row ml-2 my-1">
                        { keys.length > 0 && getHeaderData()}
                    </div>
                </div>


                <div className="d-flex w-100 scrollable-table-wrapper position-relative">

                    <HiddenTable SelectedData={SelectedData}/>
                    <table id="MAIN_TABLE" className="roundedCorners mt-0">


                        <thead id="sticky_header">
                                <tr>
                                    <th colSpan="7">
                                        <Row className="border_no_bottom mx-1">
                                            <Col className="">
                                                <div className="p-0">CALL</div>
                                            </Col>
                                            <Col className="">
                                                <div className="p-0">PUT</div>
                                            </Col>
                                        </Row>
                                        <Row className="border_all p-1 mx-1 flex-nowrap">
                                            <Col className="">
                                                <div className="p-0">Volume</div>
                                            </Col>
                                            <Col className="">
                                                <div className="p-0">OI <br/>  (Chg%)</div>
                                            </Col>
                                            <Col className="">
                                                <div className="p-0">LTP <br/> (Chg%)</div>
                                            </Col>
                                            <Col className="">
                                                <div className="p-0">Strike Price</div>
                                            </Col>
                                            <Col className="">
                                                <div className="p-0">LTP <br/> (Chg%)</div>
                                            </Col>
                                            <Col className="">
                                                <div className="p-0">OI <br/> (Chg%)</div>
                                            </Col>
                                            <Col className="">
                                                <div className="p-0">Volume</div>
                                            </Col>
                                        </Row>
                                    </th>
                                </tr>
                        </thead>
                          <tbody>
                        { SelectedData.length > 0  ? getTableData() : null}
                          </tbody>
                    </table>



                </div>



            </div>
        </Draggable>

    );
}

export default OptionModel;






