import {remoteConfigGetter} from './remoteConfig'

export const X_API_KEY = remoteConfigGetter("vortex_tv_api_key").asString()
// export const X_API_KEY = process.env.REACT_APP_X_API_KEY
export const API_KEY = remoteConfigGetter("wave_2_Api_Key") .asString()
// export const API_KEY = process.env.REACT_APP_WAVE_2_API_KEY
export const SOURCE = 'MOBILEAPI'
export const UD_ID = "e4b64de6f8a1a96b"
export const VERSION = '2.0.0'

// local storage keys
export const STORAGE_KEY = 'ACS_TOKEN'
export const STORAGE_KEY_REFRESH = 'ACS_TOKEN_REFRESH'
export const MOMENT_KEY = 'SESSION_TIME'
export const DEVICE_ID_KEY = 'DEVICE_ID'
export const QUERY_PARAM = 'Q_PARAM_ALL'


export const SESSION_TIME = Number(process.env.REACT_APP_SESSION_TIME)
export const API_URL = remoteConfigGetter("live_base_url").asString()
// export const API_URL = process.env.REACT_APP_LIVE_BASE_URL
export const TV_APPLICATION_ID = process.env.REACT_APP_TV_APPLICATION_ID
export const DOCK_BASE_URL = process.env.REACT_APP_DOCK_BASE_URL
export const FLOW_BACKEND_BASE_URL = process.env.REACT_APP_VORTEX_URL
export const STAGE = process.env.REACT_APP_ENV
export const QR_SOCKET_URL = process.env.REACT_APP_QR_SOCKET_URL

// npm run start:staging run this way
