import { getErrorMessage, logMessage, } from '../utils/helpers';
import {Zlib} from '../js/zlib'
import { remoteConfigGetter } from "../utils/remoteConfig";
import {HandleSocket} from "../components/common/handleSocket";
import ReconnectingWebSocket from 'reconnecting-websocket';
import Queue from "queue"
// import {moment} from 'moment'
const moment = require('moment');
var worker



var baOldData 

// var socket = new WebSocket(remoteConfigGetter("ltp_socket_url").asString())

var socket
const channelToSubscription = new Map();
const singleQuoteMap = new Map();
const guidToSubscription = new Map();

var backlog = []
const queue = Queue({ results: [] })
queue.autostart = true 
queue.timeout = 1000
queue.concurrency = 1


function recreateMap(){
    for (const entry of channelToSubscription.entries()) {
        var channelString = entry[0]
        var subscriptionItem = entry[1]
        const myArray = channelString.split("-");
        if( subscriptionItem.isSubscribed){
            if(subscriptionItem.isMarketDepth){
                SendMessage(_getSubscribeDepthRequest({market_segment_id: myArray[0], token: myArray[1]}))
            }else{

                SendMessage(_getSubscribeTouchRequest({market_segment_id: myArray[0], token: myArray[1]}))
            }
        }
      }
}
function isChrome() {
    return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
}
var socketUrl
export function  openWebSocketConnection(ocToken,clientCode){
    if (window.Worker){
        if (worker != undefined){
            worker.terminate();
        }
        if (isChrome()){
            // socketUrl = remoteConfigGetter("ltp_socket_url").asString()
            socketUrl = remoteConfigGetter("ltp_socket_url").asString()
        }else{
            socketUrl = remoteConfigGetter("ltp_proxy_url").asString()
        }
        worker = new Worker('./worker.js');
        worker.postMessage({type: 'initialize', socketUrl: socketUrl, ocToken: ocToken, clientCode: clientCode});
        worker.onmessage = function(e) {
            if (e.data.type == "socket_opened"){
                console.log("Worker socket opened")
                HandleSocket(true)
                recreateMap()
            }else if (e.data.type == "close"){
                HandleSocket(false)
            }else if (e.data.type == "tick"){
                ProcessPacketString(e.data.data)
            }
        }
        worker.onmessageerror = function(e) {
            console.trace("Worker error",e)
        }
    }
}

export function closeConnection(){
    logMessage(`Streaming Provider: Close connection called`)
    if( worker != null){
        worker.postMessage({type: 'close'})
        worker.terminate()
        worker = undefined
    }
    channelToSubscription.clear();
    singleQuoteMap.clear();
    guidToSubscription.clear();
}
export function closeConnectionTemporarily(){
    logMessage(`Streaming Provider: Closing connection temporarily`)
    if( worker != null){
        worker.postMessage({type: 'temporary_close'})
    }
}
export function subscribeOnOdinStream(symbols,
	resolution,
	onRealtimeCallback,
	subscribeUID,
	onResetCacheNeededCallback,
	lastDailyBar, type){
    var request = ""
    var final_request = ""
    // console.log("subscribeOnOdinStream",symbols,type,subscribeUID)
    queue.push((cb)=>{
        try{
            // console.log(type)
            if(type == 'depth'){
                _subscribeDepth(symbols[0],onRealtimeCallback,subscribeUID)
            }else if (type =='quotes' || type == 'tt-quotes'){
                // console.log("quotes subscription",symbols)
                _subscribeQuotes(symbols,onRealtimeCallback,subscribeUID,type)
            }else if (type =='bar'){
                _subscribeBars(symbols,onRealtimeCallback,subscribeUID,lastDailyBar,resolution)
            }else if(type =='single-quotes'){
                _subscribeSingleQuotes(symbols,onRealtimeCallback,subscribeUID)   
            }
            symbols.forEach((symbol)=>{
                let channelString = symbol.odin_market_segment_id+"-"+symbol.odin_token
                let subs = channelToSubscription.get(channelString)
                var needsDepth
                var needsTouchline
                subs.handlers.forEach((handler)=>{
                    if( handler.type == 'depth'){
                        needsDepth = true 
                    }else{
                        needsTouchline = true 
                    }
                })
                if (subs){
                    if (!subs.isSubscribed){

                        if (needsDepth){
                            var request = _getSubscribeDepthRequest(symbol)
                            SendMessage(request)
                            subs.isSubscribed = true 
                            subs.isMarketDepth = true 
                        }else{
                            var request = _getSubscribeTouchRequest(symbol)
                            SendMessage(request)
                            subs.isSubscribed = true 
                            subs.isTouchline = true 
                        }
                        channelToSubscription.set(channelString,subs)
                        // console.log("subs is ",subs)
                    }else{
                        if (needsDepth ){
                            if (!subs.isMarketDepth ){
                                var request = _getUnsubscribeTouchRequest(symbol)
                                SendMessage(request)
                                request = _getSubscribeDepthRequest(symbol)
                                SendMessage(request)
                                subs.isTouchline = false 
                                subs.isMarketDepth = true 
                                channelToSubscription.set(channelString,subs)
                            }
                        }
                        ProcessPacketString(subs.lastPacket)
                    }
                    
                }
            })
        }catch(e){
            console.log("subscription error",e)
        }
       
        cb()
    })
}

export function unsubscribe(listenerGuid){
    queue.push((cb)=>{
        let channelString = guidToSubscription.get(listenerGuid); 
        if(!channelString){
            cb()
            return
        }
        const  [market_segment_id, token] = channelString.split("-")
        let subscriptionItem = channelToSubscription.get(channelString)
        if(!subscriptionItem){
            return cb()
        }
        if(subscriptionItem){
            subscriptionItem.handlers = subscriptionItem.handlers.filter(function(handler){
                cb()
                return handler.id != listenerGuid ; 
            })
            channelToSubscription.set(channelString,subscriptionItem)
            if (subscriptionItem.handlers ){
                if (subscriptionItem.handlers.length == 0 ){  
                    if (subscriptionItem["isSubscribed"]){
                        if (subscriptionItem["isMarketDepth"]){
                            var request = _getSubscribeDepthRequest({odin_market_segment_id: market_segment_id,odin_token: token})
                            SendMessage(request)
                        }else{
                            var request = _getUnsubscribeTouchRequest({odin_market_segment_id: market_segment_id,odin_token: token})
                            SendMessage(request)
                        }
                    }
                    channelToSubscription.delete(channelString)
                }else{
                    var needsDepth = false 
                    subscriptionItem.handlers.forEach((handler)=>{
                        if (handler.type == 'depth'){
                            needsDepth = true 
                        }
                    })
                    if( !needsDepth){
                        if (subscriptionItem["isMarketDepth"]){
                            var request = _getUnsubscribeDepthRequest({odin_market_segment_id: market_segment_id,odin_token: token})
                            SendMessage(request)
                            request = _getSubscribeTouchRequest({odin_market_segment_id: market_segment_id,odin_token: token})
                            SendMessage(request)
                            subscriptionItem["isSubscribed"] = true 
                            subscriptionItem["isTouchline"] = true 
                            subscriptionItem["isMarketDepth"] = false
                            channelToSubscription.set(channelString,subscriptionItem)
                        }
                    }
                }
            }
        }
        cb()
    })
    
}

//Varibales name change done
function SendMessage(odinMessage){
    if (worker != null){
        worker.postMessage({type: 'message', message: odinMessage});
    }
}


function ProcessPacketString(data){
    let data_map = marketDepthProcessMessage(data);
    
    let message_code = parseInt(data_map.get('64'));
    if(message_code != 128 && message_code != 209){
        return
    }

    let tradePrice = parseFloat(data_map.get('8'));
    let openPrice = parseFloat(data_map.get('75'));
    let highPrice = parseFloat(data_map.get('77'));
    let lowPrice = parseFloat(data_map.get('78'));
    let closePrice = parseFloat(data_map.get('76'));
    let volume = parseFloat(data_map.get('79'));
    let totalBuyQty=data_map.get('81');
    let totalSellQty=data_map.get('82');
    let divider = parseFloat(data_map.get('399') || 100)
    let token = data_map.get('7')
    let market_segment = data_map.get('1')
    let lastTradeQuantity = parseFloat(data_map.get('9'));
    let dpr = data_map.get('380');
    let oi = data_map.get('88');
    let oi_ch = data_map.get('465');
    let depth = data_map.get('depth')
    // let buy_qty = parseFloat(  depth.asks[0]?.volume ||data_map.get('2') || "0");
    // let buy_price = 0.0
    let buy_qty, buy_price, sell_qty,sell_price =0.0
    if( data_map.has('3') && data_map.has('5')){
        buy_price = parseFloat(data_map.get('3'))/divider
        sell_price =  parseFloat(data_map.get('6'))/divider
        buy_qty = parseFloat(data_map.get('2'))
        sell_qty = parseFloat(data_map.get('5'))
    }else{
        depth.asks.forEach((row,index)=>{
            depth.asks[index].price  = depth.asks[index].price /divider
        })
        depth.bids.forEach((row,index)=>{
            depth.bids[index].price  = depth.bids[index].price /divider
        })
        buy_qty = depth.asks[0]?.volume 
        sell_qty = depth.asks[0]?.volume
        buy_price = depth.bids[4]?.price 
        sell_price = depth.asks[0]?.price
    }
    var tradeTime = odinDateParse(data_map.get('74'));

    let channelString = market_segment+"-"+token;

    let subscriptionItem = channelToSubscription.get(channelString);
    if (!subscriptionItem) {
        return;
    }
    if (!subscriptionItem.handlers) {
        return;
    }
    subscriptionItem.lastPacket = data
    channelToSubscription.set(channelString, subscriptionItem);
    // console.log(subscriptionItem)
    subscriptionItem.handlers.forEach((handler)=>{
        if( handler.type == 'quotes'){
            let quote = {
                s: 'ok', 
                n: handler.symbol,
                v: {
                    ch: (tradePrice - closePrice)/divider,
                    chp: ((tradePrice - closePrice)/closePrice * 100 ),
                    short_name: handler.symbol.split(":")[1],
                    exchange: handler.symbol.split(":")[0],
                    description: handler.symbol,
                    lp: tradePrice/divider, 
                    open_price: openPrice/divider, 
                    high_price: highPrice/divider, 
                    low_price: lowPrice/divider, 
                    prev_close_price: closePrice/divider, 
                    volume: volume,
                    dpr: dpr,
                    token: token,
                    market_segment_id: market_segment,
                    oi: oi,
                    oi_ch: oi_ch,
                    ask: sell_price, 
                    ask_qty: sell_qty,
                    bid: buy_price,
                    bid_qty: buy_qty
                }

            }
            try {
                handler.handler.callback([quote])  
            }
              catch(err) {
                  console.info(err)
              }
            
        }else if(handler.type == 'tt-quotes'){
            let quote = {
                s: 'success', 
                n: handler.symbol,
                v: {
                    ch: (tradePrice - closePrice)/divider,
                    chp: ((tradePrice - closePrice)/closePrice * 100 ),
                    short_name: handler.symbol.split(":")[1],
                    exchange: handler.symbol.split(":")[0],
                    description: handler.symbol,
                    lp: tradePrice/divider, 
                    open_price: openPrice/divider, 
                    high_price: highPrice/divider, 
                    low_price: lowPrice/divider, 
                    prev_close_price: closePrice/divider, 
                    volume: volume,
                    dpr: dpr,
                    ask: sell_price, 
                    ask_qty: sell_qty,
                    bid: buy_price,
                    bid_qty: buy_qty
                }

            }
            try {
                handler.handler.callback(quote)                
            }
            catch(err) {
                console.info(err)
            }
        }else if(handler.type == 'bar'){
            const lastDailyBar = handler.lastDailyBar;
            const resolution = handler.resolution; 
            var nextDailyBarTime
            if (resolution == '1' || resolution == 1){
                nextDailyBarTime = getNextMinBarTime((lastDailyBar !== null && lastDailyBar !== undefined ) ? lastDailyBar.time : null );
            }else if (resolution == '1D'){
                tradeTime = moment(tradeTime).startOf('day').toDate().getTime()+19800000
                nextDailyBarTime = getNextDailyBarTime((lastDailyBar !== null && lastDailyBar !== undefined ) ? lastDailyBar.time : null);
            }
            else if (resolution == '1M'){
                tradeTime = moment(tradeTime).startOf('month').toDate().getTime()+19800000
                nextDailyBarTime = getNextMonthBarTime((lastDailyBar !== null && lastDailyBar !== undefined ) ? lastDailyBar.time : null);
            }else if (resolution == '1Y'){
                tradeTime = moment(tradeTime).startOf('year').toDate().getTime()+19800000
                nextDailyBarTime = getNextyearBarTime((lastDailyBar !== null && lastDailyBar !== undefined ) ? lastDailyBar.time : null);
            }else if (resolution == '1S'){
                tradeTime = moment(tradeTime).startOf('second').toDate().getTime()
                nextDailyBarTime = getNextSecBarTime((lastDailyBar !== null && lastDailyBar !== undefined ) ? lastDailyBar.time : null);
            }
            let bar;
            if (tradeTime >= nextDailyBarTime) {
                if( resolution == '1D'){
                    if(openPrice>0 && highPrice>0 && lowPrice>0 && closePrice>0 && tradePrice>0){
                        bar = {
                            time: nextDailyBarTime,
                            open: openPrice/divider,
                            high: highPrice/divider,
                            low: lowPrice/divider,
                            close: tradePrice/divider,
                            volume: volume
                        };  
                    }else{
                        bar = {
                            time: nextDailyBarTime,
                        }
                    }  
                }else{
                    bar = {
                        time: nextDailyBarTime,
                        open: tradePrice/divider,                        
                        high: tradePrice/divider,
                        low: tradePrice/divider,
                        close: tradePrice/divider,
                        volume: volume - (lastDailyBar != undefined ?  lastDailyBar?.totalLastDayVolumeTillBar : 0  ), 
                        totalLastDayVolumeTillBar: volume
                    };
                }
            } else {
                if( resolution == '1D'){
                    bar = {
                        ...lastDailyBar,
                        high: highPrice>0?highPrice/divider:lastDailyBar.high,
                        low: lowPrice>0?lowPrice/divider:lastDailyBar.low,
                        open: openPrice>0?openPrice/divider:lastDailyBar.open,
                        close: tradePrice/divider,
                        volume: volume
                    };
                }else{
                    bar = {
                        ...lastDailyBar,
                        high: Math.max((lastDailyBar? lastDailyBar.high : 0), tradePrice/divider),
                        low: Math.min(lastDailyBar ? lastDailyBar.low : 0, tradePrice/divider),
                        close: tradePrice/divider,
                        volume: volume - lastDailyBar?.totalLastDayVolumeTillBar,
                    };
                }
            }
            if (bar.volume < 0){
                bar.volume = 0 
                console.log("negative vol", volume,lastDailyBar.totalLastDayVolumeTillBar )
            }
            handler.handler.callback(bar)

            handler.lastDailyBar = bar;
        }else if(handler.type == 'single-quotes'){
            let quote = {
                s: 'success', 
                n: handler.symbol,
                v: {
                    ch: (tradePrice - closePrice)/divider,
                    chp: ((tradePrice - closePrice)/closePrice * 100 ),
                    lp: tradePrice/divider, 
                    open_price: openPrice/divider, 
                    high_price: highPrice/divider, 
                    low_price: lowPrice/divider, 
                    prev_close_price: closePrice/divider, 
                    volume: volume, 
                    dpr: dpr,
                    ask: sell_price, 
                    ask_qty: sell_qty,
                    bid: buy_price,
                    bid_qty: buy_qty
                }

            }
            let mapper = singleQuoteMap.get(handler.id)
            if(mapper){
                let all = true 
                let callbackResp = []
                mapper[market_segment+"-"+token].quote = quote
                for (const [key, value] of Object.entries(mapper)) {
                    if (Object.keys(value.quote).length == 0) {
                        all = false 
                    }else{
                        callbackResp.push({
                            "s" : "ok",
                            "n" : value.quote.n,
                            "v": {
                                "ch": value.quote.v.ch,
                                "chp": value.quote.v.chp,
                                "short_name": value.quote.n.split(":")[1],
                                "exchange": value.quote.n.split(":")[0],
                                "description": value.quote.n,
                                "lp": value.quote.v.lp,
                                "ask": value.quote.v.ask || 0,
                                "bid": value.quote.v.bid || 0 ,
                                "open_price": value.quote.v.open_price,
                                "high_price": value.quote.v.high_price,
                                "low_price": value.quote.v.low_price,
                                "prev_close_price": value.quote.v.prev_close_price,
                                "volume": value.quote.v.volume,
                                "dpr": value.quote.v.dpr
                            }
                        })
                    }
                }
                if(all){
                    handler.handler.callback(callbackResp)
                    singleQuoteMap.delete(handler.handler.id)
                    unsubscribe(handler.handler.id)
                }
            }
            

        }
        else if (handler.type =='depth'){
            depth['ch']=(tradePrice - closePrice)/divider
            depth['chp']=((tradePrice - closePrice)/closePrice * 100 )
            depth['openPrice']=openPrice/divider
            depth['closePrice']=closePrice/divider
            depth['highPrice']=highPrice/divider
            depth['lowPrice']=lowPrice/divider
            depth['volume']=volume
            depth['lp']=tradePrice/divider
            depth['totalBuyQty']=totalBuyQty
            depth['totalSellQty']=totalSellQty
            depth['LTQ']=lastTradeQuantity
            handler.handler.callback(depth)
        }
        
    });
}

function getNextDailyBarTime(barTime) {
    if (barTime == null){
        var new_date = moment().startOf('day').toDate()
        return  new_date.getTime()+ 19800000
    }
    const date = new Date(barTime);
    var new_date = moment(date).add('1','d').startOf('day').toDate()
    return new_date.getTime()+ 19800000;
}

function getNextMonthBarTime(barTime) {
    const date = new Date(barTime);
    var new_date = moment(date).add('1','M').startOf('month').toDate()
    return new_date.getTime() + 19800000;
}

function getNextyearBarTime(barTime){
    const date = new Date(barTime);
    var new_date = moment(date).add('1','Y').startOf('year').toDate()
    return new_date.getTime()+ 19800000;
}

function getNextSecBarTime(barTime){
    const date = new Date(barTime);
    var new_date = moment(date).add('1','s').startOf('second').toDate()
    return new_date.getTime();
}
function getNextMinBarTime(barTime){
    const date = new Date(barTime);
    const curTime = moment(); 
    var duration = moment.duration(curTime.diff(date)).asMinutes(); 
    if( duration > 1){
        return curTime.startOf('minute').toDate()
    }else{
        var new_date = moment(date).add('1','m').startOf('minute').toDate()
        return new_date.getTime();
    }
}

function odinDateParse(rand){
    let [dateStr,timeStr] = rand.split(" ")
    let final_time = "T"+timeStr[0]+timeStr[1]+":"+timeStr[2]+timeStr[3]+":"+timeStr[4]+timeStr[5]+"+05:30"
    return Date.parse(dateStr+final_time)
}

//Return odin request parameters for depth Subscription
function _getSubscribeDepthRequest(symbol){
    var request = "63=FT3.0|64=127|65=80|"
    if (symbol.odin_market_segment_id === undefined){
        if( symbol.market_segment_id !== undefined){
            symbol.odin_market_segment_id = symbol.market_segment_id 
        }
    }
    if (symbol.odin_token === undefined){
        symbol.odin_token = symbol.token
    }
    return request + "1="+symbol.odin_market_segment_id + "|7="+symbol.odin_token+"|230=1"
}

//Return odin request parameters for depth Unsubscription
function _getUnsubscribeDepthRequest(symbol){
    var request = "63=FT3.0|64=206|65=80|"
    return request + "1="+symbol.odin_market_segment_id + "|7="+symbol.odin_token+"|230=2"
}

//Return odin request parameters for touchLine Unsubscription
function _getUnsubscribeTouchRequest(symbol){
    var request = "63=FT3.0|64=127|65=80|"
    return request + "1="+symbol.odin_market_segment_id + "&7="+symbol.odin_token+"|230=2"
}

//Return odin request parameters for touchLine Subscription
function _getSubscribeTouchRequest(symbol){
    if (symbol.odin_market_segment_id === undefined){
        if( symbol.market_segment_id !== undefined){
            symbol.odin_market_segment_id = symbol.market_segment_id 
        }
    }
    if (symbol.odin_token === undefined){
        symbol.odin_token = symbol.token
    }
    return "63=FT3.0|64=206|65=80|1="+symbol.odin_market_segment_id+"$7="+symbol.odin_token+"|"+"4=1000|230=1"
}

function  _subscribeDepth(symbol,onRealtimeCallback,subscribeUID){
    var channelString = symbol.odin_market_segment_id+"-"+symbol.odin_token
    _setChannelMap(symbol,channelString,onRealtimeCallback, subscribeUID, 'depth')
    // SendMessage(_getSubscribeDepthRequest(symbol))
}

function _subscribeQuotes(symbols,onRealtimeCallback,subscribeUID,type){
    symbols.forEach((symbol)=>{
        let channelString = symbol.odin_market_segment_id+"-"+symbol.odin_token
        _setChannelMap(symbol,channelString,onRealtimeCallback,subscribeUID,type)
    })
    // SendMessage(_getTouchRequest(symbols))
}

function _subscribeBars(symbols,onRealtimeCallback,subscribeUID,lastDailyBar,resolution){
    symbols.forEach((symbol)=>{
        let channelString = symbol.odin_market_segment_id+"-"+symbol.odin_token
        _setChannelMap(symbol,channelString,onRealtimeCallback,subscribeUID,'bar', resolution,lastDailyBar) 
    })
    // SendMessage(_getTouchRequest(symbols))
}

function _subscribeSingleQuotes(symbols,onRealtimeCallback,subscribeUID){
    symbols.forEach((symbol)=>{
        let channelString = symbol.odin_market_segment_id+"-"+symbol.odin_token
        let existing = singleQuoteMap.get(subscribeUID)
        if(existing){
            existing[symbol.odin_market_segment_id+"-"+symbol.odin_token]= {quote: {}}
        }else{
            singleQuoteMap.set(subscribeUID, new Object() )
            singleQuoteMap.get(subscribeUID)[symbol.odin_market_segment_id+"-"+symbol.odin_token] =  {quote: {}, symbol: symbol.symbol}
        }
        _setChannelMap(symbol,channelString,onRealtimeCallback,subscribeUID,'single-quotes')
    })
    // SendMessage(_getTouchRequest(symbols))
}
//Map the other requirements like id,resolution , type etc. if subscribeUID doesn't exist
function _setChannelMap(symbol,channelString,onRealtimeCallback, subscribeUID,type, resolution,lastDailyBar){
    let subscriptionItem = channelToSubscription.get(channelString);
    let handler = {
        id: subscribeUID,
        callback: onRealtimeCallback,
    };
    if (subscriptionItem) {
        var index = subscriptionItem.handlers.findIndex(object =>{
            return object.id == subscribeUID
        })
        if (index == -1 ){
            subscriptionItem.handlers.push({handler: handler, type: type, resolution: resolution, lastDailyBar: lastDailyBar, symbol: symbol.symbol,id: subscribeUID});
        }else{
            subscriptionItem.handlers[index] = {handler: handler, type: type, resolution: resolution, lastDailyBar: lastDailyBar, symbol: symbol.symbol,id: subscribeUID}
        }
    }else{
        subscriptionItem = {
            handlers: [{handler: handler, type: type, resolution: resolution, lastDailyBar: lastDailyBar, symbol: symbol.symbol,id: subscribeUID}],
            isSubscribed: false, 
            isMarketDepth: false, 
            isTouchline: false,
            lastPacket: ""
        };
    }
    channelToSubscription.set(channelString, subscriptionItem);
    guidToSubscription.set(subscribeUID, channelString)
    if (!guidToSubscription.has(subscribeUID)){
        console.error("guid fail")
    }
}

//Change the market depth data in a particular format from Odin's response
function marketDepthProcessMessage(message) {
    var scrip = message.split("|");
    let quantity_a=[]
    let price_a=[]
    let order_a=[]
    let depth = {
        snapshot: true, 
        asks: [], 
        bids: []
    }
    let data_map = new Map()

    for (var i = 0, len = scrip.length; i < len; i++) {
        var data = scrip[i];
        var pos = data.indexOf('=');
        if (pos >= 0) {
            var key = data.substring(0, pos);
            var value = data.substring(pos + 1, scrip[i].length);
            if (key == '11') {
            var buySell = data.substring(pos + 1, scrip[i].length);
                //BuySell==1 means buy market depth 
                if (buySell =='1'){
                    for(var j = 0 , len2 = 5 ; j <len2;j++){
                        let marketDepthData = scrip[i+j+1].split("$")
                        marketDepthData.map((d,index)=>{
                            if(index==0){
                                quantity_a  = d.split("=")
                            }else if(index==1){
                                price_a = d.split("=")
                            }else if(index==2){
                                order_a = d.split("=")
                            }
                        })
                        depth.bids.push({
                            price: price_a.length>1?parseFloat(price_a[1]):0, 
                            volume: quantity_a.length>1?parseInt(quantity_a[1]):0,
                            orders: order_a.length>1?parseInt(order_a[1]):0
                        })
                    }
                }else {
                    for(var j = 0 , len2 = 5 ; j <len2;j++){
                        let marketDepthData = scrip[i+j+1].split("$")
                        marketDepthData.map((d,index)=>{
                            if(index==0){
                                quantity_a  = d.split("=")
                            }else if(index==1){
                                price_a = d.split("=")
                            }else if(index==2){
                                order_a = d.split("=")
                            }
                        })
                        depth.asks.push({
                            price: price_a.length>1?parseFloat(price_a[1]):0, 
                            volume: quantity_a.length>1?parseInt(quantity_a[1]):0,
                            orders: order_a.length>1?parseInt(order_a[1]):0
                        })
                    }

                }

            }else{
                data_map.set(key,value)
            }
            // processMarketDepthKeyValuePair(scripDetailModel, key, value);
        }
    }
    depth.asks.sort((a,b) => (a.price > b.price) ? 1 : ((b.price > a.price) ? -1 : 0))
    depth.bids.sort((a,b) => (a.price > b.price) ? 1 : ((b.price > a.price) ? -1 : 0))
    data_map.set("depth",depth)
    return data_map
}