import React, { useEffect,useState } from 'react';
import Spinner from "react-bootstrap/Spinner";


const CustomSpinner = ()=>{
    return (
        <div className="d-flex flex-row justify-content-center align-items-center">
            <Spinner style={{width: "4rem", height: "4rem",position:"absolute","top":"57%"}} className="mr-2 pri" as="span" animation="border" variant="primary" size="sm" role="status" aria-hidden="true"/>
        </div>
    )
}

export default CustomSpinner