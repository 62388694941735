import { logMessage } from "../utils/helpers"
import ReconnectingWebSocket from 'reconnecting-websocket';
export class Omsfeed{
    constructor(host,broker, queue,auth_token){
        this._socket = null 
        this._host =host
        this._broker = broker
        this.auth_token = auth_token
        this.q = queue
    }
    setupSocket(){
        // var socket = new WebSocket(remoteConfigGetter("interactive_feed_url").asString()+"?auth_token="+this.auth_token)
        const rws = new ReconnectingWebSocket("wss://updates.rupeezy.in/vortex?auth_token="+this.auth_token,[],{
            maxReconnectionDelay: 2000,
            minReconnectionDelay: 100,
            reconnectionDelayGrowFactor: 1.3,
            maxRetries: 100
        });
        rws.addEventListener('error',(error)=>{
            logMessage(`Internal Oms Feed Provider: Socket Error: ${JSON.stringify(error)}`)
        })
        rws.addEventListener('close',(reason)=>{
        })
        var broker = this._broker
        var host = this._host 
        var _getOrderType = this._getOrderType
        var _getOrderProduct = this._getOrderProduct
        var _getOrderStatus = this._getOrderStatus
        var _getGtdOrderStatus = this._getGtdOrderStatus
        var q = this.q
        rws.addEventListener('message',(message)=>{
            var order = JSON.parse(message.data)
            q.push(function (cb){
                
                if( order.type == "order" && order.data.order_identifier.toString().indexOf("GTD") == -1){
                    var sfall_index = order.data.status_message.indexOf("SFall=")
                    var shortfall_index = order.data.status_message.indexOf("ShortFall=") 
                    if(shortfall_index != -1 || sfall_index != -1){
                        if (shortfall_index != -1){
                            var new_str = order.data.status_message.slice(shortfall_index+10, order.data.status_message.length)
                        }else{
                            var new_str = order.data.status_message.slice(sfall_index+6, order.data.status_message.length)
                        }
                        var new_index = new_str.indexOf(" ")
                        var shortfall = new_str.slice(0,new_index)
                        order.data.status_message = "Margin Shortfall of Rs "+shortfall
                    }
                    if(order.data.status_message.indexOf("not allowed by  member") != -1){
                        order.data.status_message = "Trading in this scrip is not allowed"
                    }
                    broker._quotesProvider.tokenInfo({market_segment_id:order.data.market_segment_id, token: order.data.token }).then((symbol)=>{
                        let no = {
                            id: order.data.order_id, 
                            exchange: order.data.exchange,
                            symbol: symbol.symbol,
                            side: (order.data.transaction_type == "BUY" ? 1 : -1),
                            order_type: order.data.variety,
                            type: (order.data.variety) == 'RL-MKT' ? 2 : (order.data.variety == 'RL' ? 1 : (order.data.variety == 'SL-MKT' ? 3 : 4)),
                            product: order.data.product,
                            qty: order.data.total_quantity,
                            remQty: order.data.pending_quantity,
                            traded_quantity: (order.data.total_quantity - order.data.pending_quantity),
                            displayMessage: (order.data.status_message == "" ? "Placed" : order.data.status_message),
                            status: _getOrderStatus(order.data.status), 
                            tradedPrice: (order.data.traded_price ) , 
                            limitPrice: (order.data.order_price),
                            stopPrice: (order.data.trigger_price),
                            order_id: order.data.order_id,
                            duration: (order.data.order_identifier.indexOf("GTD") != -1 ? "GTT" :  order.data.validity),
                            is_amo_order: order.data.is_amo
                        }
                        broker.updateOrder(no,"update")
                        cb()
                    })
                }else if(order.type == 'trade'){
                    host.orderPartialUpdate(order.data.order_id, { remQty: order.data.pending_quantity });
                    if( order.data.market_segment_id == 13){
                        order.data.traded_price =order.data.traded_price/10000000
                    }else{
                        order.data.traded_price =order.data.traded_price/100
                    }
                    var nt = {
                        id: order.data.trade_number, 
                        exchange: order.data.exchange,
                        market_segment_id: order.data.market_segment_id,
                        token: order.data.token,
                        side: (order.data.transaction_type == "BUY" ? 1 : -1),
                        type: (order.data.variety),
                        product: order.data.product,
                        tradedQty: (order.data.total_quantity - order.data.pending_quantity),
                        tradedPrice: order.data.traded_price,
                        tradeTime: order.data.trade_time,
                        orderNumber: order.data.order_id,
                        time: Date.now()
                    }
                    broker.addExecution(nt)
                    cb()
                }else if(order.type == "gtt_order"){
                    broker._quotesProvider.tokenInfo({market_segment_id:order.data.market_segment_id, token: order.data.token }).then((symbol)=>{
                        let no = {
                            id: order.data.order_id, 
                            exchange: order.data.exchange,
                            symbol: symbol.symbol,
                            side: (order.data.transaction_type == "BUY" ? 1 : -1),
                            order_type: order.data.variety,
                            type: (order.data.variety) == 'RL-MKT' ? 2 : (order.data.variety == 'RL' ? 1 : (order.data.variety == 'SL-MKT' ? 3 : 4)),
                            product: order.data.product,
                            qty: order.data.total_quantity,
                            remQty: order.data.pending_quantity,
                            traded_quantity: (order.data.total_quantity - order.data.pending_quantity),
                            displayMessage: (order.data.status_message == "" ? "Placed" : order.data.status_message),
                            status: _getGtdOrderStatus(order.data.gtd_order_status), 
                            tradedPrice: (order.data.traded_price ) , 
                            limitPrice: (order.data.order_price),
                            stopPrice: (order.data.trigger_price),
                            order_id: order.data.order_id,
                            validity_days: order.data.validity_days,
                            duration: (order.data.order_identifier.indexOf("GTD") != -1 ? "GTT" :  order.data.validity),
                            is_amo_order: order.data.is_amo
                        }
                        broker.updateOrder(no,"update")
                        cb()
                    })
                }else if (order.type == "sl_trigger"){
                    broker._quotesProvider.tokenInfo({market_segment_id:order.data.market_segment_id, token: order.data.token }).then((symbol)=>{
                        let no = {
                            id: order.data.order_id, 
                            symbol: symbol.symbol,
                            side: (order.data.transaction_type == "BUY" ? 1 : -1),
                            order_type: order.data.variety,
                            type: (order.data.variety) == 'RL-MKT' ? 2 : (order.data.variety == 'RL' ? 1 : (order.data.variety == 'SL-MKT' ? 3 : 4)),
                            product: order.data.product, 
                            status: 6, 
                            displayMessage: "SL Trigger hit",
                            qty: order.data.total_quantity,
                            remQty: order.data.pending_quantity,
                        }
                        broker.updateOrder(no,"sl-update")
                        cb()
                    })
                }
                
            })
        })
        // socket.onerror = function(error){
        //     logMessage(`Internal Oms Feed Provider: Socket Error: ${error}`)
        // }  
        // var classInstance = this
        // socket.onclose = function(reason){
        //     if (reason.code !=1000){
        //         setTimeout(function () {
        //             classInstance.disconnectSocket()
        //             classInstance.setupSocket()
        //         }(), 1000);
        //     }
        // }

       
        // socket.onmessage = function(message){
            
        // }

        this._socket = rws
    }

    disconnectSocket(){
        if (this._socket != undefined && this._socket != null ){
			logMessage('existing internal socket is disconnected ')
			this._socket.close(1000)
			this._socket = null 
		}
    }

    _getOrderStatus(status){
        const dict = {
            "PENDING" : 6,
            "CANCELLED" : 1,
            "REJECTED" : 5,
            "COMPLETED" : 2,
        }
        return dict[status.toString()]
    }
    _getGtdOrderStatus(status){
        const dict = {
            "ACTIVE": 6, 
            "COMPLETED": 2, 
            "WITHDRAW": 1, 
            "EXPIRED": 1, 
            "REJECTED": 5
        }
        return dict[status.toString()]
    }

}