import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import authService from "../../../services/authService";
import {toast} from "react-toastify";
import {setOtpData} from "../../../slices/authSlice";
import Background from "../../BackGround/background";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import MadeInLoveIndia from "../../common/madeInLoveIndia";
import OtpModel from "../../ForgetPassword/otpModel";
import flowBackEndServices2 from "../../../services/flowBackendServices";
import {useLocation} from "react-router";

var panRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/

const FlowForgetPassword = () => {

    const { state } = useLocation();
    const [formState, setFormState] = useState({user_id: '',pan:''});
    const [ errors, setErrors ] = useState({})
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { data,user_id } = useSelector((state) => state.authUser);

    const [ apiData, setApiData ] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    // const [Otp, setOtp] = useState('');

    const childRef = useRef();

    useEffect(() => {
        if(data === null){
            navigate("/")
        }
    },[])


    useEffect(() => {
        if (user_id) setFormState({...formState,user_id: user_id})
    }, [])


    function handleFormStateChange(event) {
        const { name, value } = event.target;

        setFormState({ ...formState, [name]: value.toUpperCase() });

        if ( !!errors[name] ) setErrors({
            ...errors,
            [name]: null
        })
    }


    function handleOnClick(event) {
        event.preventDefault();

        // get our new errors
        const newErrors = findFormErrors()
        // Conditional logic:
        if ( Object.keys(newErrors).length > 0 ) {
            setErrors(newErrors)
        } else {
            requestOtp();
        }

    }

    function resendOtp() {
        let sendData = {requestId: apiData.id}
        setIsLoading(true);
        flowBackEndServices2.forgotPasswordResendOTP(sendData)
            .then(value => {
                if(value.status === 200 && value.data.status === 'success'){
                    toast.success(`OTP Resent`)
                    childRef.current.toggleRestBtn()
                }else {
                    toast.info(value.data.message)
                }
            })
            .catch(error => {
                toast.error(`${error.response.data.message}`)
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    function requestOtp() {
        setIsLoading(true)
        let dataSend = {pan: formState.pan,clientCode: formState.user_id}
        flowBackEndServices2.forgotPasswordOtpRequest(dataSend)
            .then(value => {
                if(value.status === 200 && value.data.status !== 'error'){
                    setApiData(value.data)
                    OpenChildModel()
                }else {
                    toast.info(value?.data?.message)
                }
            })
            .catch(error => {
                // toast.error(`${error.response.data.message}`)
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    function callForgetPassword(modelOpen) {
        resendOtp()
    }

    const findFormErrors = () => {
        const { user_id,pan} = formState
        const newErrors = {}
        // name errors
        if ( !user_id || user_id === '' ) newErrors.user_id = 'User id is required!'
        else if ( user_id.length < 4 ) newErrors.user_id = 'User id is too short!'

        if ( !pan || pan === '' ) newErrors.pan = 'Pan Number is required!'
        else if ( pan.length !== 10 ) newErrors.pan = 'Enter valid Pan Number'
        else if ( pan.length === 10 && !panRegex.test(pan) ) newErrors.pan = 'Enter valid Pan Number'

        return newErrors
    }

    const optVerify = (Otp) => {
        if(Otp.length === 6){
            setIsLoading(true);
            let sendData = {requestId: apiData.id,otp: Otp}
            flowBackEndServices2.verifyForgotOtp(sendData)
                .then(value => {
                    if(value.data.status === "success"){
                        toast.success(value.data.message)
                        let newData = {...data,...value.data,...apiData};
                        let Settingdata = {data : newData, user_id: formState.user_id};
                        dispatch(setOtpData(Settingdata))
                        CloseChildModel()
                        navigate('/FlowCreateNewPassword');
                    }else{
                        toast.error(value.data.message)
                    }
                })
                .catch(error => {
                    toast.error(`${error.response.data.message}`)
                    setIsLoading(false);
                })
                .finally(() => {
                    setIsLoading(false);
                })
        }
    }

    // const [show, setShow] = useState(false);
    //
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);



    const OpenChildModel = () => {
        childRef.current.callChildMethodOpen();
    }

    const CloseChildModel = () => {
        childRef.current.callChildMethodClose();
    }


    function getName(state) {
        if(state.data && state.data.pwdNeedsChange){
            return 'Reset'
        }else {
            return 'Forgot'
        }
    }

    return (
        <Background>

            <div className="h-100 p-5 p-lg-5 d-flex flex-column justify-content-between">

                <div className="">
                    <div className="py-2">
                        <Link to="/login"><i className="fas fa-arrow-left fas fa-lg"/></Link>
                    </div>

                    <h3 className="heading1 py-2"> {state?.data?.pwdNeedsChange ? 'Reset':'Forgot'} Password </h3>


                    <div className="colorGrey pb-3">Recover your password for your Account with the following:</div>

                </div>

                <div className="align-self-start flex-fill w-100 pt-2">

                    <Form className="w-100" onSubmit={handleOnClick}>
                        <Form.Group className="mb-2" controlId="formBasicEmail">
                            <Form.Label className="text1 w-100">
                                <div className="d-flex flex-row justify-content-between align-items-center">
                                    <div className="">Mobile Number / Client Code</div>
                                    <div className=""/>
                                    {/*<div className="colorBlue"><Link to='/ForgetUserID'>Forgot user ID ?</Link></div>*/}
                                </div>
                            </Form.Label>
                            <Form.Control type="text"
                                          name="user_id"
                                          required
                                          isInvalid={ !!errors.user_id }
                                          value={formState.user_id}
                                          onChange={handleFormStateChange}
                                          placeholder="Mobile Number / Client Code" />
                            <Form.Control.Feedback type='invalid'>
                                { errors.user_id }
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-2" controlId="formBasicPAN">
                            <Form.Label className="text1 w-100">
                                <div className="d-flex flex-row justify-content-between align-items-center">
                                    <div className="">Pan Number</div>
                                    <div className=""/>
                                    {/*<div className="colorBlue"><Link to='/ForgetUserID'>Forgot user ID ?</Link></div>*/}
                                </div>
                            </Form.Label>
                            <Form.Control type="text"
                                          name="pan"
                                          required
                                          isInvalid={ !!errors.pan }
                                          value={formState.pan}
                                          onChange={handleFormStateChange}
                                          placeholder="Pan Number" />
                            <Form.Control.Feedback type='invalid'>
                                { errors.pan }
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Button disabled={isLoading} className="blue_btn w-100 my-3" onClick={handleOnClick} variant="primary" type="submit">
                        {isLoading && (
                            <Spinner className="mr-2" as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                        )}
                        Get OTP
                    </Button>
                    </Form>


                </div>

                <div className="">
                    

                    <MadeInLoveIndia/>
                </div>



            </div>

            <OtpModel ref={childRef} optVerify={optVerify} isLoading={isLoading} callForgetPassword={callForgetPassword}/>

        </Background>

    );
};

export default FlowForgetPassword;
