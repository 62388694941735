import { createSlice } from "@reduxjs/toolkit";

const basketOrderSlice = createSlice({
    name: "basketOrder",
    initialState: {
        model_open_basket: false,
        current_basket: null,
        basketDetails: null,
        currentModification: null,
        listDataMain: [],
        sharedBasketId:null,
        orderListenerData: null,
        public_basket_data:null,
        basket_old_id_data:null,
        open_public_basket:false,
        execute_public_basket:false,
        public_basket_margin:null,
        basket_state:[
            {id: 1,param: null,url:'BucketList/EmptyState'},
            {id: 2,param: null,url:'CreateBasket'},
            {id: 3,param: null,url:'basketDetails'},
            {id: 4,param: null,url:'sharedBasketDetails'},
            {id: 5,param: null,url:'basketAnalyse'}
        ]
    },
    reducers: {
        BasketModelToggle: (state, action) => {
            state.model_open_basket = action.payload;
        },
        OpenPublicBasket: (state, action) => {
            state.open_public_basket = action.payload;
        },
        ExecutePublicBasket: (state, action) => {
            state.execute_public_basket = action.payload;
        },
        setSharedBasketId(state,action){
            state.sharedBasketId=action.payload
        },
        setPublicBasketData: (state, action) => {
            state.public_basket_data = action.payload;
        },
        setPublicBasketMargin: (state, action) => {
            state.public_basket_margin = action.payload;
        },
        setBasketOldIdData: (state, action) => {
            state.basket_old_id_data = action.payload;
        },
        setCurrentBasketState: (state, action) => {
            state.current_basket = action.payload;
        },
        setBasketDetails: (state, action) => {
            state.basketDetails = action.payload;
        },
        setCurrentModification: (state, action) => {
            state.currentModification = action.payload;
        },
        setListDataMain: (state, action) => {
            state.listDataMain = action.payload;
            if(state.basketDetails){
              let data =  state.listDataMain.find(d => d.id === state.basketDetails.id)
               state.basketDetails = data
            }
            // if(state.current_basket?.id === 3){
            //     let data =  state.listDataMain.find(d => d.id === state.current_basket.param.id)
            //     state.current_basket = data
            // }
        },
        setOrderListenerData: (state, action) => {
            state.orderListenerData = action.payload;
        },
        basketOrderAnalyse(state){
            window.GtmPusher({
                event: 'tv-basket-order-analyse',
            })
        },
        sharedBasketAnalyse(state){
            window.GtmPusher({
                event: 'tv-basket-shared-analyse',
            })
        },
        sharedBasketExecute(state){
            window.GtmPusher({
                event: 'tv-basket-shared-execute',
            })
        },
        sharedBasketSave(state){
            window.GtmPusher({
                event: 'tv-basket-shared-save',
            })
        },
        basketShareEvent(state){
            window.GtmPusher({
                event: 'tv-basket-share-link',
            })
        },
        sharedBasketOpenEvent(state){
            window.GtmPusher({
                event: 'tv-basket-shared-open',
            })
        },
    },
});

export const { BasketModelToggle,setCurrentBasketState,setBasketDetails,setListDataMain,
    setCurrentModification,setOrderListenerData,OpenPublicBasket,setPublicBasketData,
    ExecutePublicBasket,setSharedBasketId,setBasketOldIdData,setPublicBasketMargin,basketOrderAnalyse,sharedBasketAnalyse
,sharedBasketExecute,sharedBasketSave,basketShareEvent,sharedBasketOpenEvent} = basketOrderSlice.actions;
export default basketOrderSlice.reducer;
