import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector,shallowEqual} from "react-redux";
import authService from "../../services/authService";
import {useNavigate} from "react-router-dom";
import {currentUser} from "../../slices/authSlice";
import {TVChartContainer} from "../TVChartContainer";
import {ChartBuySell} from "../ChartBuySell";
import { Charges } from '../Charges';
import OptionModel from "../OptionChain/OptionModel";
import NewOptionModel from "../NewOptionChain/NewOptionModel";
import FutureChainModel from "../FutureChain/FutureChainModel"
import {OpenOptionModel as OpenModel,OpenNewOptionModel as OpenNewModel,OpenFutureChain} from "../../slices/optionChainSlice";
import {OptionStrategyToggle} from "../../slices/optionStrategySlice";
import DraggableWidget from "../DraggableWidget/DraggableWidget";
import {useLocation} from "react-router";
import {HandleSocket} from "../common/handleSocket";
import BasketModel from "../BasketOrder/basketModel";
import OptionStategyModel from "../OptionStrategy/optionStategyModel";

import {BrokerApiInstance} from "../../chart-brokerapi/broker"
import { messaging } from "../../utils/remoteConfig";
import { getToken } from "firebase/messaging";
import { isSupported } from "firebase/messaging";
import {remoteConfigGetter} from "../../utils/remoteConfig";
import WalkThrough from './WalkThrough'
import {setSharedBasketId,basketShareEvent,BasketModelToggle,OpenPublicBasket} from "../../slices/basketOrderSlice";



const getOption = state => ({ model_open: state.optionChain.model_open,
    option_key: state.optionChain.option_key,socket_open: state.optionChain.socket_open,
    user: state.authUser.user,basket_id:state.authUser.sharedBasketId,new_model_open:state.optionChain.new_model_open,open_future_chain:state.optionChain.open_future_chain,future_key: state.optionChain.future_key});

function Dashboard() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state => state.authUser.user),shallowEqual);

    useEffect(() => {
        HandleSocket(true)
        // console.log("Dashbord mount")
        return () => {
            // console.log("Dashbord Unmount")
        }
    }, [])

    useEffect(()=>{
        var basketId=sessionStorage.getItem("public_basket_id")
        if(basketId && basketId!=""){
            dispatch(basketShareEvent())
            dispatch(setSharedBasketId(basketId))
            dispatch(BasketModelToggle(true))
            dispatch(OpenPublicBasket(true))
        }
    },[])


    useEffect(() => {

    }, [user])

    useEffect(()=>{
        let ack = localStorage.getItem("acknowledge_disclosure")
        if(ack!=null && ack!=="false"){
            handleLogout()
        }
    },[])

    // handle click event of logout button
    const handleLogout = async () => {
        if( messaging != null && Notification.permission === 'granted' && await isSupported() ){
            var supported = await isSupported()
            if(supported){
                try {
                    const token = await getToken(messaging,{vapidKey: remoteConfigGetter("vapidkey").asString()})
                    if(token) {
                        if( BrokerApiInstance != undefined && BrokerApiInstance != null){
                            await BrokerApiInstance.unSubscribeFcmByToken(token)
                        }
                    }
                    else {
                        console.log('No Instance ID token available. Request permission to generate one.');
                    }
                } catch (error) {
                    const token = await getToken(messaging,{vapidKey: remoteConfigGetter("vapidkey").asString()})
                    if(token) {
                        if( BrokerApiInstance != undefined && BrokerApiInstance != null){
                            await BrokerApiInstance.unSubscribeFcmByToken(token)
                        }
                        
                        return token;
                    }
                    else {
                        console.log('No Instance ID token available. Request permission to generate one.');
                    }
                }
            }
            
        }
        dispatch(authService.logout())
        navigate("/",{ replace: true});
    }

    const OpenOptionModel = (key) => {
        const  data = {model_open: true, option_key: key};
        dispatch(OpenModel(data))
    }

    const OpenNewOptionModel = (key) => {
        const  data = {new_model_open: true, option_key: key};
        dispatch(OpenNewModel(data))
    }
    const OpenFutureChainModel = (key) => {
        const  data = {open_future_chain: true, future_key: key};
        dispatch(OpenFutureChain(data))
    }

    const handleLogoutCallBack = useCallback(() => {
        handleLogout()
    }, []);

    const OpenOptionModelCallBack = useCallback((val) => {
        OpenOptionModel(val)
    }, []);
    const OpenNewOptionModelCallBack = useCallback((val) => {
        OpenNewOptionModel(val)
    }, []);

    const OpenFutureChainModelCallBack = useCallback((val) => {
        OpenFutureChainModel(val)
    }, []);


    return (
        <div>

            {/*<input type="button" onClick={handleLogout} value="Logout" />*/}

             	<div className={ 'App' }>
            		<TVChartContainer OpenFutureModel={OpenFutureChainModelCallBack} data={user} logoutFunction={handleLogoutCallBack} OpenOptionModel={OpenOptionModelCallBack}  OpenNewOptionModel={OpenNewOptionModelCallBack}/>
             		<ChartBuySell token={user.access_token}/>
                    <Charges />
                    <OpntionRenderOne/>
                    <NewOptionRender data={user}/>
                    <RenderFutureChain />
                    <BasketRenderOne/>
                    <OptionStrategyRender/>
                    <DraggableWidget/>
                    <WalkThrough/>
             	</div>

        </div>
    );
}



export default Dashboard;




const OpntionRenderOne = () => {
    const  model_open  = useSelector((state => state.optionChain.model_open),shallowEqual);
    const optionData = useSelector(getOption,shallowEqual);

    useEffect(() => {

    }, [model_open])


    return (
            model_open ? <OptionModel optionData={optionData}/> : null
    )
};

const NewOptionRender = ({data}) => {
    const  new_model_open  = useSelector((state => state.optionChain.new_model_open),shallowEqual);
    const optionData = useSelector(getOption,shallowEqual);
    useEffect(() => {

    }, [new_model_open])


    return (
        new_model_open ? <NewOptionModel optionData={optionData} userdata={data}/> : null
    )
};

const RenderFutureChain = ({data}) => {
    const  open_future_chain  = useSelector((state => state.optionChain.open_future_chain),shallowEqual);
    const futureData = useSelector(getOption,shallowEqual);
    useEffect(() => {

    }, [open_future_chain])


    return (
        open_future_chain ? <FutureChainModel futureData={futureData}/> : null
    )
};

const BasketRenderOne = () => {
    // console.log("BasketRenderOne.....")
    const model_open_basket = useSelector((state => state.basketOrder.model_open_basket), shallowEqual);

    useEffect(() => {

    }, [model_open_basket])


    return (
            model_open_basket ? <BasketModel/> : null
    )
};

const OptionStrategyRender = () => {
    const model_open_option_strategy = useSelector((state => state.optionStrategy.model_open_option_strategy), shallowEqual);

    useEffect(() => {

    }, [model_open_option_strategy])


    return (
        model_open_option_strategy ? <OptionStategyModel/> : null
    )
};



