import { createSlice } from "@reduxjs/toolkit";

const optionStrategySlice = createSlice({
    name: "optionStrategy",
    initialState: {
        model_open_option_strategy: false,
        current_strategy_state:{id:1,url:"tools"},
        strategy_state:[
            {id:1,url:'tools'},
            {id:2,url:'store/builder'},
            {id:3,url:'allStrategies'},
            {id:4,url:'strategyDetails'},
            {id:5,url:'analyse'},
            {id:6,url:'execution'},
            {id:7,url:'builderStrategyDetails'}
        ],
        recent_searches:[], 
        current_strategy:null,
        current_symbol_details:null,
        expiryDates:null,
        current_expiry_date:null,
        strategy_name_details:null,
        analyse_data:null,
        current_multiplier:1,
        active_index:0,
        available_funds:null, 
        required_margin:null,
        initial_margin:null,
        executable_options:null,
        keep_default_index:true,
        builder_input:null,
        builder_strategy_details:null,
        predict_range:null,
        strategy_name_filters:null,
        builder_strategy_list:null,
        builder_details:null,
        strategy_type_list:null,
        strategy_type_filters:null,
        builder_sort_by:null
    },
    reducers: {
        OptionStrategyToggle: (state, action) => {
            state.model_open_option_strategy = action.payload;
        },
        CloseOptionStrategy: (state, action) => {
            state.model_open_option_strategy = false;
        },
        setStrategyState:(state,action)=>{
            state.current_strategy_state = action.payload
        },
        setRecentSearches:(state,action)=>{
            state.recent_searches = action.payload
        },
        setCurrentStrategy:(state,action)=>{
            state.current_strategy=action.payload
        },
        setCurrentSymbolDetails:(state,action)=>{
            state.current_symbol_details = action.payload
        },
        setExpiryDates:(state,action)=>{
            state.expiryDates=action.payload
        },
        setCurrentExpiryDate:(state,action)=>{
            state.current_expiry_date=action.payload
        },
        setStrategyNameDetails:(state,action)=>{
            state.strategy_name_details=action.payload
        },
        setAnalyseData:(state,action)=>{
            state.analyse_data=action.payload
        },
        setCurrentMultilier:(state,action)=>{
            state.current_multiplier=action.payload
        },
        setActiveIndexGlobal:(state,action)=>{
            state.active_index=action.payload
        },
        setGlobalFunds:(state,action)=>{
            state.available_funds=action.payload
        },
        setRequiredGlobalMargin:(state,action)=>{
            state.required_margin=action.payload
        },
        setInitialGlobalMargin:(state,action)=>{
            state.initial_margin=action.payload
        },
        setExecutableOptions:(state,action)=>{
            state.executable_options=action.payload
        },
        setKeepDefaultindex:(state,action)=>{
            state.keep_default_index=action.payload
        },
        setBuilderInput:(state,action)=>{
            state.builder_input=action.payload
        },
        setBuilderStrategyDetails:(state,action)=>{
            state.builder_strategy_details=action.payload
        },
        setPredictRange:(state,action)=>{
            state.predict_range=action.payload
        },
        setGlobalStrategyNameFilters:(state,action)=>{
            state.strategy_name_filters=action.payload
        },
        setBuilderStrategyList:(state,action)=>{
            state.builder_strategy_list=action.payload
        },
        setBuilderDetails:(state,action)=>{
            state.builder_details=action.payload
        },
        setGlobalStrategyTypeList:(state,action)=>{
            state.strategy_type_list=action.payload
        },
        setGlobalStrategyTypeFilters:(state,action)=>{
            state.strategy_type_filters=action.payload
        },
        setBuilderSortBy:(state,action)=>{
            state.builder_sort_by=action.payload
        },
        openOptionStrategy(state){
            window.GtmPusher({
                event: 'tv-strategy',
            })
        },
        openOptionStrategyStore(state){
            window.GtmPusher({
                event: 'tv-strategy-store',
            })
        },
        openOptionStrategyBuilder(state){
            window.GtmPusher({
                event: 'tv-strategy-builder',
            })
        },
        optionBuilderAnalyse(state){
            window.GtmPusher({
                event: 'tv-strategy-builder-analyse',
            })
        },
        optionStoreAnalyse(state){
            window.GtmPusher({
                event: 'tv-strategy-store-analyse',
            })
        },
        optionStoreExecute(state){
            window.GtmPusher({
                event: 'tv-strategy-store-execute',
            })
        },
        optionBuilderExecute(state){
            window.GtmPusher({
                event: 'tv-strategy-builder-execute',
            })
        },
    },
});

export const { OptionStrategyToggle,CloseOptionStrategy,setStrategyState,setRecentSearches,setCurrentStrategy,
    setCurrentSymbolDetails,setExpiryDates,setCurrentExpiryDate,setStrategyNameDetails,setAnalyseData,
    setCurrentMultilier,setActiveIndexGlobal,setGlobalFunds,setRequiredGlobalMargin,setExecutableOptions
    ,setKeepDefaultindex,setBuilderInput,setBuilderStrategyDetails,setPredictRange,setGlobalStrategyNameFilters,
    setBuilderStrategyList,setBuilderDetails,setGlobalStrategyTypeList,setGlobalStrategyTypeFilters,setBuilderSortBy,
    openOptionStrategy,openOptionStrategyStore,openOptionStrategyBuilder,optionBuilderAnalyse,optionStoreAnalyse,
    optionStoreExecute,optionBuilderExecute,setInitialGlobalMargin} = optionStrategySlice.actions;
export default optionStrategySlice.reducer;
