/**
 * If you want to enable logs from datafeed set it to `true`
 */
var isLoggingEnabled = false;
var ClientCode = "Anonymous User"
export function logMessage(message,level = 0) {
    if (isLoggingEnabled) {
        const now = new Date();
        // tslint:disable-next-line:no-console
        if( level ==  0){
            console.info(`${ClientCode}>${now.toLocaleTimeString()}.${now.getMilliseconds()}> ${message}`);
        }else if(level = 2){
            console.warn(`${ClientCode}>${now.toLocaleTimeString()}.${now.getMilliseconds()}> ${message}`)
        }
        
    }
}

export function setLogging(flag){
    isLoggingEnabled = flag
}

export function setLoggingCC(client_code){
    ClientCode = client_code
}
export function getErrorMessage(error) {
    if (error === undefined) {
        return '';
    }
    else if (typeof error === 'string') {
        return error;
    }
    return error.message;
}
