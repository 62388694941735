import axios from 'axios';
import {remoteConfigGetter} from "../utils/remoteConfig";

const apiFlowBackend = axios.create({
    baseURL: remoteConfigGetter('inhouse_base_url_prod').asString(),
    headers: {
        'Content-Type': 'application/json',
    },
});


async function getBuckets (token)  {
    let headers =  {'Content-Type': 'application/json','Authorization': "Bearer "+token, 'x-api-key': process.env.REACT_APP_VORTEX_KEY};
    return apiFlowBackend.get('aux/baskets',{headers: headers});
}

async function AddBasket (data,token)  {
    let headers =  {'Content-Type': 'application/json','Authorization': "Bearer "+token, 'x-api-key': process.env.REACT_APP_VORTEX_KEY};
    return apiFlowBackend.post('aux/basket',data,{headers: headers});
}

async function UpdateBasket (data,token,id)  {
    /// this line can be remove if api allow this this flag not set
    // if(data['isExecuted'] === undefined) data['isExecuted'] = false;
    let headers =  {'Content-Type': 'application/json','Authorization': "Bearer "+token, 'x-api-key': process.env.REACT_APP_VORTEX_KEY};
    return apiFlowBackend.put('aux/basket/'+ id,data,{headers: headers});
}

async function DeleteBasket (data,token,id)  {
    let headers =  {'Content-Type': 'application/json','Authorization': "Bearer "+token, 'x-api-key': process.env.REACT_APP_VORTEX_KEY};
    return apiFlowBackend.delete('aux/basket/'+ id,{headers: headers});
}

async function basketShareLink (data,token)  {
    let headers =  {'Content-Type': 'application/json','Authorization': "Bearer "+token, 'x-api-key': process.env.REACT_APP_VORTEX_KEY};
    return apiFlowBackend.post('aux/basket/sharing',data,{headers: headers});
}

async function getSharedBasket (data,token)  {
    let headers =  {'Content-Type': 'application/json','Authorization': "Bearer "+token, 'x-api-key': process.env.REACT_APP_VORTEX_KEY};
    return apiFlowBackend.get('aux/basket/shared?basket_id='+data,{headers: headers});
}

async function SaveBasket (data,token)  {
    let headers =  {'Content-Type': 'application/json','Authorization': "Bearer "+token, 'x-api-key': process.env.REACT_APP_VORTEX_KEY};
    return apiFlowBackend.post('aux/basket/save',data,{headers: headers});
}

const flowBackEndService = {
    getBuckets,AddBasket,UpdateBasket,DeleteBasket,basketShareLink,getSharedBasket,SaveBasket
};

export default flowBackEndService;