import React, { useEffect,useState } from 'react';
import {useDispatch,useSelector,shallowEqual} from "react-redux";
import OptionStrategyHeader from "../OptionStrategyHeader"
import flowBackEndServices2 from "../../../services/flowBackendServices";
import Buy from "../../../assets/icons/Buy.svg"
import Sell from "../../../assets/icons/Sell.svg"
import OptionsCombinations from "./OptionsCombinations"
import HeaderSubscribedData from "./HeaderSubscribedData"
import {ReactComponent as UpArrow} from "../../../assets/icons/arrow-square-up.svg"
import {ReactComponent as FundCircle} from "../../../assets/icons/fund-circle.svg"
import {ReactComponent as DownArrow} from "../../../assets/icons/arrow-square-down.svg"
import {optionStoreAnalyse,setStrategyState,setAnalyseData,setCurrentMultilier,setActiveIndexGlobal,setRequiredGlobalMargin,setGlobalFunds,setExecutableOptions,setKeepDefaultindex,setInitialGlobalMargin} from "../../../slices/optionStrategySlice";
import {DOCK_BASE_URL} from "../../../utils/config";
import {currentUser} from "../../../slices/authSlice";
import {ReactComponent as NoStrategy} from "../../../assets/icons/NoStrategy.svg"



const StrategyDetails = ({})=>{
    const dispatch = useDispatch()
    const {current_strategy,current_symbol_details,strategy_name_details,current_multiplier,active_index,required_margin,initial_margin,available_funds,keep_default_index} = useSelector((state) => state.optionStrategy);
    const user = useSelector((state => state.authUser.user),shallowEqual);
    const user_key = useSelector(currentUser);  
    const [currentStrategyDetails,setCurrentStrategyDetails]=useState(null)
    const [currentMultiplier,setCurrentMultiplier]=useState(current_multiplier?current_multiplier:1)
    const [activeIndex, setActiveIndex] = useState(active_index);
    const [marginInputFormat,setMarginInputFormat]=useState(null)
    const [requiredMargin,setRequiredMargin]=useState(required_margin)
    const [initialMargin,setInitialMargin]=useState(initial_margin)
    const [availableFunds,setAvailableFunds]=useState(available_funds)


    useEffect(()=>{
        const data = current_strategy.find(p => p.strategy_name === strategy_name_details);
        setCurrentStrategyDetails(data)
    },[])

    useEffect(()=>{
        let temp ={}
        if(currentStrategyDetails) {
            if(requiredMargin==null && currentStrategyDetails.trading_opportunities.length!=0) createMarginInput(currentStrategyDetails.trading_opportunities[active_index]["legs"],0)
            if(availableFunds==null) getAvailableBalance()
            if(currentStrategyDetails.trading_opportunities.length!=0){
                temp={
                    "strategy_name":currentStrategyDetails.strategy_name+" "+(active_index+1),
                    "options":currentStrategyDetails.trading_opportunities[active_index]["legs"]
                }
            }
            dispatch(setExecutableOptions(temp))
        }
    },[currentStrategyDetails])

    useEffect(()=>{
        if(keep_default_index){
            setActiveIndex(0)
            dispatch(setActiveIndexGlobal(0))
        }
    },[])

    useEffect(()=>{
        if(marginInputFormat) getTotalMargin()
    },[marginInputFormat])


    const addMultiplier=()=>{
        let value=current_multiplier?current_multiplier:currentMultiplier
        value+=1
        setCurrentMultiplier(value)
        dispatch(setCurrentMultilier(value))
    }

    const subtractMultiplier=()=>{
        if(currentMultiplier>1){
            let value=current_multiplier?current_multiplier:currentMultiplier
            value-=1
            setCurrentMultiplier(value)
            dispatch(setCurrentMultilier(value))
        }
    }

    const showOptionsCombinations=(data,index,strategy_name,bool)=>{
         if(data.legs){
            return data.legs.map(option=>{
                return(
                    <OptionsCombinations options={option} currentindex={index} currentMultiplier={currentMultiplier} strategy_name={strategy_name} bool={bool}/>
                )
            })
         }
    }

    const openAnalyse = (analyseData) =>{
        let data = {id:5,url:'analyse'}
        dispatch(setAnalyseData(analyseData))
        dispatch(setStrategyState(data))
        dispatch(optionStoreAnalyse())
    }

    const checkBreakEven=(breakevens)=>{
        if(Array.isArray(breakevens)){
            const modifiedArray = breakevens.map((element) => {
                return (element).toFixed(2);
              });
            return modifiedArray.join(',')
        }else{
            (breakevens).toFixed(2)
        }
    }

    const openDock = () =>{
        let doc_url_pay_in = `${DOCK_BASE_URL}dashboard?path=pay-in&sso_auth_token=${user.ssoToken}&version=v2`
        window.open(doc_url_pay_in, '_blank')
    }

    const createMarginInput = (legs,index) =>{
        let input = []
        let elements=document.getElementsByClassName(currentStrategyDetails.strategy_name+(index+1))
        const values = [];
        for(var i=0;i<elements.length;i++)
            {
                values.push(Number(elements[i].textContent))
            }
        legs.map((d,index)=>{
            // var divider = 1.0
            // if( d.option.exchange == "NSE_FO"){
            //     divider = d.option.lot_size
            // }
            let data = {
                "transaction_type": d.action,
                "exchange": d.option.exchange,
                "variety": "RL",
                "price": values[index],
                "product": "DELIVERY",
                "quantity": d.quantity * currentMultiplier,
                "token":d.option.token
            }
            input.push(data)
        })
        setMarginInputFormat(input)
        
    }

    function getTotalMargin(){
        let marginInput = {"orders":marginInputFormat}
        flowBackEndServices2.getTotalMargin(marginInput,user_key.access_token).then(value => {
            return value.data
        }).then((data)=>{
            setRequiredMargin(data.required_margin)
            setInitialMargin(data.initial_margin)
            dispatch(setInitialGlobalMargin(data.initial_margin))
            dispatch(setRequiredGlobalMargin(data.required_margin))
        }).catch(reason => {
            console.log("Error in margin data",reason);
        })

    }

    function getAvailableBalance(){
        let funds = []
        flowBackEndServices2.getAvailableFunds(user_key.access_token).then(value => {
            return value.data
        }).then((data)=>{
            setAvailableFunds(data.nse.net_available)
            dispatch(setGlobalFunds(data.nse.net_available))
            return  funds
            
        }).catch(reason => {
            console.log("Error in margin data",reason);
        })


    }

    function handleItemClick(index,data,strategy_name) {
        if(activeIndex!=index){
            dispatch(setKeepDefaultindex(false))
            setCurrentMultiplier(1)
            dispatch(setCurrentMultilier(1))
            let temp={
                "strategy_name":strategy_name+" "+(index+1),
                "options":data.legs
            }
            createMarginInput(data.legs,index)
            dispatch(setExecutableOptions(temp))
            if(availableFunds){

            }else{
                getAvailableBalance()
            }
        }
        setActiveIndex(index === activeIndex ? null : index);
        dispatch(setActiveIndexGlobal(index === activeIndex ? null : index))
      }

      const openExecution = () =>{
        let data = {id:6,url:'execution'}
        dispatch(setStrategyState(data))
      }


    const renderStrategyOpportunities=()=>{
        return currentStrategyDetails.trading_opportunities.map((data,index)=>{
            return (
                <div className='eachStrategy mb-2' id={index}>
                    <div className='nameQuantity'>
                        <div className='px-3 stratName'>{currentStrategyDetails.strategy_name} {index+1}</div>
                        <div className='toggle_button c-pointer' onClick={() => handleItemClick(index,data,currentStrategyDetails.strategy_name)} id={index}>{activeIndex === index ?<UpArrow /> :<DownArrow />}</div>
                        {activeIndex === index && (
                        <div className='multiplier'>
                            <div className='multText'>Multiplier</div>
                            <div className='addSubtractBox ml-4 c-pointer' onClick={subtractMultiplier}><i className="fa-solid fa-minus"></i></div>
                            <div className='addSubtractBox' style={{"background":"#FFFFFF","width":"32px"}}>{currentMultiplier}</div>
                            <div className='addSubtractBox c-pointer' onClick={addMultiplier}><i className="fa-solid fa-plus"></i></div>
                        </div>
                        )}
                    </div>
                        <div className='container'>
                            <div className='row my-3'>
                                <div className='col-6' style={{"borderRight":"0.5px solid #D3D3D3"}}>
                                    <div style={{"display":"flex","flexDirection":"row","justifyContent":"space-between"}}>
                                        <div style={{"textAlign":"left"}}>
                                            <div className='detailshead'>Max Profit</div>
                                            <div className='profitValue'>{data.is_infinite_profit?"Unlimited":((data.max_profit).toFixed(2))*(activeIndex === index?currentMultiplier:1)}</div>
                                        </div>
                                        <div style={{"textAlign":"center"}}>
                                            <div className='detailshead'>Breakevens</div>
                                            <div className='breakevensValue'>{checkBreakEven(data.breakeven)}</div>
                                        </div>
                                        <div style={{"textAlign":"right"}}>
                                            <div className='detailshead'>Max Loss</div>
                                            <div className='lossValue'>{data.is_infinite_loss?'Unlimited':((data.max_loss).toFixed(2))*(activeIndex === index?currentMultiplier:1)}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                {showOptionsCombinations(data,index,currentStrategyDetails.strategy_name,activeIndex === index)}
                                </div>
                            </div>
                        </div>
                    {activeIndex === index && (
                        <React.Fragment>
                        {initialMargin*currentMultiplier>availableFunds && (<div className='addFunds pl-3'>
                            <div style={{"alignSelf":"center"}}><FundCircle /></div>
                            <div className='fundWarning' style={{"alignSelf":"center"}}>Add more funds to execute this strategy</div>
                            <div className='addFundButton text-nowrap c-pointer' style={{"alignSelf":"center"}} onClick={openDock}>+Add funds</div>
                        </div>)}
                     <div className='margin pt-4'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-6 p-0 p-0'>
                                    <div className='details'>
                                        <div className='pl-3'>
                                            <div className='detailshead'>Initial</div>
                                            <div className='ltp_change' style={{"textAlign":"right"}}>{initialMargin?(Number(initialMargin)*currentMultiplier).toFixed(2):0}</div>
                                        </div>
                                        <div style={{"paddingLeft":"20%"}}>
                                            <div className='detailshead'>Required</div>
                                            <div className='ltp_change' style={{"textAlign":"left"}}>{requiredMargin?(Number(requiredMargin)*currentMultiplier).toFixed(2):0}</div>
                                        </div>
                                        <div style={{"paddingLeft":"20%"}}>
                                            <div className='detailshead'>Available</div>
                                            <div className='ltp_change' style={{"textAlign":"left"}}>{availableFunds?availableFunds:0}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='Buttons'>
                                        <div className='analyse mr-1 c-pointer' onClick={()=>openAnalyse(data)}>
                                            Analyse
                                        </div>
                                        <div className='continue ml-1 c-pointer' onClick={openExecution}>
                                            Continue
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                     </div>
                     </React.Fragment>
                    )}
                </div>
        )
        })
    }

    const renderBlankPage =()=>{
        return(
            <div className='mt-4'>
                <NoStrategy/>
                <div className='mt-3 noStrategyHeader'>No option strategy available</div>
                <div className='noStrategyDesc'>Try changing search parameters</div>
            </div>
        )
      }
    


    return (
        <React.Fragment>
            <OptionStrategyHeader>
                <HeaderSubscribedData token={current_symbol_details.nToken} segmentId={current_symbol_details.nMarketSegmentId} symbol={current_symbol_details.sSymbol} description={current_symbol_details.sSecurityDesc!=""?current_symbol_details.sSecurityDesc:current_symbol_details.sSymbol+" Index"} exchange={current_symbol_details.sExchange}/>
            </OptionStrategyHeader>
            {currentStrategyDetails==null &&  (<div className='builderTradingOpportunies'>
                {renderBlankPage()}
            </div>)}
            <div className='tradingOpportunies'>
                {currentStrategyDetails && renderStrategyOpportunities()}
            </div>
        </React.Fragment>
    )   
}

export default StrategyDetails