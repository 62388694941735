import { logMessage } from '../utils/helpers';
export class OdinRequester {
    constructor(headers,logout_function) {
        if (headers) {
            this._headers = headers;
        }
        this.logout_function = logout_function
    }
    async sendRequest(odinUrl, urlPath, body, method="GET") {
        logMessage('Odin New request: ' + urlPath);
        // Send user cookies if the URL is on the same origin as the calling script.
        const options = {};
        if (this._headers) {
            options.headers = this._headers;
        }
        if (body){
            options.body = JSON.stringify(body)
        }
        options.method = method
        
        try {
            const response = await fetch(`${odinUrl}${urlPath}`, options);
            if( response.status == 401){
                this.logout_function()
                return
            }
            const responseTest = await response.text();
            return JSON.parse(responseTest);
        } catch (error) {
            return console.log(error);
        }
    }
}
