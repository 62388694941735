import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import Background from "../../BackGround/background";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import MadeInLoveIndia from "../../common/madeInLoveIndia";
import {regularExpression} from "../../../comman/status";
import flowBackEndServices2 from "../../../services/flowBackendServices";
import {useLocation} from "react-router";

const FlowCreateNewPassword = () => {

    const { state } = useLocation();

    const [formState, setFormState] = useState({user_id: '',new_password: '',new_password_copy: ''});
    const [ errors, setErrors ] = useState({})
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { data } = useSelector((state) => state.authUser);


    useEffect(() => {
        if(data === null){
             navigate("/")
        }
    },[])



    const [isLoading, setIsLoading] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);

    const togglePassword = () => {
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setPasswordShown(!passwordShown);
    };

    function handleFormStateChange(event)  {
        const { name, value } = event.target;

        setFormState({ ...formState, [name]: value });

        if ( !!errors[name] ) setErrors({
            ...errors,
            [name]: null
        })
    }


    function changePassword(formState) {

        const dataPost = {
            token: data.token,
            password: formState.new_password,
            requestId: data.id,
        }


        setIsLoading(true);
        flowBackEndServices2.resetForgotPassword(dataPost)
            .then(value => {
                if(value.data.status === "success"){
                    toast.success('password change success')
                    navigate("/",{ replace: true});
                }else {
                    toast.error(`${value.data.message}`)
                }
            })
            .catch(error => {
                toast.error(`${error.response.data.message}`)
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            })

    }


    const findFormErrors = () => {
        const { new_password,new_password_copy } = formState
        const newErrors = {}
        // name errors

        if (!regularExpression.test(new_password)) {

            if(new_password.length < 8){
                newErrors.new_password = 'Must be between 8 to 16 character'
                newErrors.new_password_copy = 'Must be between 8 to 16 character'
            }else if(new_password.length > 16){
                newErrors.new_password = 'Must be between 8 to 16 character'
                newErrors.new_password_copy = 'Must be between 8 to 16 character'
            }else {
                newErrors.new_password = 'Strong Password Needed'
                newErrors.new_password_copy = 'Strong Password Needed'
            }

        }else {
            if(new_password !== new_password_copy){
                newErrors.new_password = 'Both passwords should match'
                newErrors.new_password_copy = 'Both passwords should match'
            }
        }

        return newErrors
    }

    function handleOnClick(event) {
        event.preventDefault();
        const newErrors = findFormErrors()
        if ( Object.keys(newErrors).length > 0 ) {
            setErrors(newErrors)
        } else {
            setErrors({})
            changePassword(formState);
        }

    }

    return (
        <Background>
            <div className="h-100 p-lg-5 p-5 d-flex flex-column justify-content-between " >

                <div className="">

                    <div className="py-2">
                        <Link to="/FlowForgetPassword"><i className="fas fa-arrow-left fas fa-lg"/></Link>
                    </div>

                    <h3 className="heading1 py-1">Create New Password</h3>

                    <div className="colorGrey pt-0">You need to create your own new password</div>



                </div>


                <div className="align-self-start flex-fill w-100">

                    <div className="pt-2">

                        <Form>

                            <Form.Group className="mb-2" controlId="formBasicpass">
                                <Form.Label className="text1 w-100">
                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <div className="">New Password</div>
                                        <div className="colorBlue">&nbsp;</div>
                                    </div>
                                </Form.Label>
                                <InputGroup className="">
                                    <Form.Control type={passwordShown ? "text" : "password"}
                                                  name="new_password"
                                                  required
                                                  isInvalid={ !!errors.new_password }
                                                  value={formState.new_password}
                                                  onChange={handleFormStateChange}
                                                  placeholder="New Password" />
                                    <InputGroup.Text>
                                        {
                                            passwordShown ?  <i onClick={togglePassword} className="fa-solid fa-eye"/>  :
                                                <i onClick={togglePassword} className="fas fa-eye-slash"/>
                                        }
                                    </InputGroup.Text>
                                    <Form.Control.Feedback type='invalid'>
                                        { errors.new_password }
                                    </Form.Control.Feedback>
                                </InputGroup>

                            </Form.Group>


                            <Form.Group className="mb-2" controlId="formBasiccopy">
                                <Form.Label className="text1 w-100">
                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <div className="">Confirm password</div>
                                        <div className="colorBlue">&nbsp;</div>
                                    </div>
                                </Form.Label>
                                <InputGroup className="">
                                    <Form.Control type={passwordShown ? "text" : "password"}
                                                  name="new_password_copy"
                                                  required
                                                  isInvalid={ !!errors.new_password_copy }
                                                  value={formState.new_password_copy}
                                                  onChange={handleFormStateChange}
                                                  placeholder="Confirm password" />
                                    <Form.Control.Feedback type='invalid'>
                                        { errors.new_password_copy }
                                    </Form.Control.Feedback>
                                </InputGroup>

                            </Form.Group>


                            <small className="form-text text-muted">Should contain at least 1 uppercase and 1 lowecase alphabet,1 number,1 special character and 8 to 16 character long</small>


                        </Form>

                    </div>

                </div>


                <div className="">
                    <Button disabled={isLoading} className="blue_btn w-100 my-3" onClick={handleOnClick} variant="primary" type="submit">
                        {isLoading && (
                            <Spinner className="mr-2" as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                        )}
                        Change password
                    </Button>

                    <MadeInLoveIndia/>
                </div>



            </div>
        </Background>

    );
}

export default FlowCreateNewPassword;

