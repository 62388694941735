import React from 'react';
import ReactDOM from "react-dom/client";
import store from "./store";
import { Provider } from 'react-redux';
import App from './App';
import ProdRedirection from './components/ProdRedirection/ProdRedirection';
import './index.css';
import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: '778a52c3-6713-42f0-af19-0fe4e86b9129',
    clientToken: 'pub4225b131f419017e6a89a7b27ea26696',
    site: 'datadoghq.eu',
    service: 'tv-terminal',
    env: process.env.REACT_APP_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: process.env.REACT_APP_ENV+":"+process.env.REACT_APP_COMMIT_VERSION, 
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
	
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
	beforeSend: (event, context) => {
        // collect a RUM resource's response headers
        if (event.type === 'resource' && event.resource.type === 'fetch' && context?.requestInput?.origin == "https://vortex-api.rupeezy.in") {
            event.context.responseHeaders = Object.fromEntries(context.response.headers)
			
        }
        return true
    },
});


  

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<>
		<Provider store={store}>
			{process.env.REACT_APP_ENV=="staging"?<ProdRedirection/>:<div></div>}
			<App />
		</Provider>
	</>
);


// ReactDOM.render(
// 	<React.StrictMode>
// 		<Provider store={store}>
// 			<App />
// 		</Provider>
// 	</React.StrictMode>,
// 	document.getElementById('root')
// );
