import React,{useState,useEffect} from 'react';
import { ReactComponent as Tick } from '../../assets/icons/tick-circle.svg';

const KraStatus=(kraStatus)=>{
    const [link,setLink] = useState("https://www.sebi.gov.in/reports-and-statistics/research/jan-2023/study-analysis-of-profit-and-loss-of-individual-traders-dealing-in-equity-fando-segment_67525.html")
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
    
    const handleResize = () => {
        setIsDesktop(window.innerWidth > 768);
      };
    
      useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

      console.log("isDesktop",isDesktop)

    if(isDesktop){
        const recipient = 'support@rupeezy.in';
        const subject = ''; // You can fill this if needed
        const body = ''; // You can fill this if needed
      
        const gmailLink = `https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${encodeURIComponent(recipient)}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}&tf=1`;
      
        if(kraStatus.kraStatus==1){
            return(
                <div className='discContent'>
                <div className='discHeader'>
                    KRA on Hold
                </div>
                <div className='discPointers mt-5 kra-info'>
                    Your KRA is on hold. Please visit the respective KRA portal to validate your mobile and email address. If you need to change your mobile number or email, visit <a href='https://dock.rupeezy.in/' className='a-text' target={"_blank"}>https://dock.rupeezy.in/</a> and update your information.
                </div>
                <div className='kra-info' style={{"marginTop":"40px","marginBottom":"30px"}}>
                <p>NDML KRA - <a href='https://kra.ndml.in/ClientInitiatedKYC-webApp/#/ClientinitiatedKYC' className='a-text' target={"_blank"}>Click Here</a></p>
                <p>CVL KRA - <a href='https://validate.cvlindia.com/CVLKRAVerification_V1/' className='a-text' target={"_blank"}>Click Here</a></p>
                <p>KARVY KRA - <a href='https://www.karvykra.com/KYC_Validation/Default.aspx' className='a-text' target={"_blank"}>Click Here</a></p>
                <p>DOTEX KRA - <a href='https://www.nsekra.com/' className='a-text' target={"_blank"}>Click Here</a></p>
                <p>CAMS KRA - <a href='https://camskra.com/' className='a-text' target={"_blank"}>Click Here</a></p>
                </div>    
                <div className='kra-info' style={{"fontWeight":"400"}}>For further assistance, contact Rupeezy support at <a href={gmailLink} className='a-text' target={"_blank"}>support@rupeezy.in</a> or call 0755-4268599.</div>
            </div>
            )
        }else{
            return(
                <div className='discContent'>
                <div className='discHeader'>
                    KRA Rejected
                </div>
                <div className='discPointers mt-5 kra-info' style={{"marginBottom":"100px"}}>
                    Your KRA application has been rejected. Please check the relevant KRA portal for the reason. 
                </div>
 
                <div className='kra-info' style={{"fontWeight":"400"}}>For further assistance, contact Rupeezy support at <a href={gmailLink} className='a-text' target={"_blank"}>support@rupeezy.in</a> or call 0755-4268599.</div>
            </div>
            )
        }
    }else{
        return(
            <div className='discContent'>
            <div className='discHeader' style={{"fontSize":"18px"}}>
                Risk Disclosures on Derivatives
            </div>
            <div className='discPointers mt-5'>
                <div className='pointers-flex'>
                    <Tick />
                    <div className='pl-3' style={{"fontSize":"12px"}}>9 out of 10 individual traders in equity Future and Options Segment, incurred net losses. </div>
                </div>
                <div className='pointers-flex pt-4'>
                    <Tick />
                    <div className='pl-3' style={{"fontSize":"12px"}}>Over and above the net trading losses incurred, loss makers expended an additional 28% of net trading<br/> losses as transactions costs. </div>
                </div>
                <div className='pointers-flex pt-4'>
                    <Tick />
                    <div className='pl-3' style={{"fontSize":"12px"}}>On an average, loss makers registered net trading loss close to ₹ 50,000.</div>
                </div>
                <div className='pointers-flex pt-4'>
                    <Tick />
                    <div className='pl-3' style={{"fontSize":"12px"}}>Those making net trading profits, incurred between 15% to 50% of such profits as transaction cost.</div>
                </div>
            </div>
            <div className='discInfo' style={{"marginTop":"5px"}}>
                <div className='infoHead'>For more Information:</div>
                <div className='hyperlink' style={{"fontSize":"8px"}}><a href="https://www.sebi.gov.in/reports-and-statistics/research/jan-2023/study-analysis-of-profit-and-loss-of-individual-traders-dealing-in-equity-fando-segment_67525.html" target="_blank">{link}</a></div>
            </div>
        </div>
        )
    }

}

export default KraStatus