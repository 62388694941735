import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import Draggable from "react-draggable";
import {DataFeedInstance} from "../../chart-datafeed/udf-compatible-datafeed";
import flowBackEndServices2 from "../../services/flowBackendServices";
import {numDifferentiation, segmentToInstrumentType} from "../../comman/status";
import {openFutureChain, OpenFutureChain,openHighLowChain,openMarketDepthChain} from "../../slices/optionChainSlice";
import FutureHeader from "./FutureHeader"
import './FutureChainModel.scss';
import FutureChainTableData from "./FutureChainTableData"
import FutureSubsData from "./FutureSubsData"
import MarketDepthData from "./MarketDepthData"
import LoadingSpinner from "./LoadingSpinner";
import HighLow from "./HighLow";




const FutureChainModel = ({futureData})=> {

    const { open_future_chain,future_key,socket_open,user} = futureData
    const dispatch = useDispatch();

    const [odinToken,setOdinToken] = useState(null)
    const [scripName,setScripName]=useState(null)
    const [marketSegmentId,setMarketSegmentId]=useState(null)
    const [futuresData,setFuturesData]=useState([])
    const [showFutureChain,setShowFutureChain]=useState(true)
    const [showMarketDepth,setShowMarketDepth]=useState(false)
    const [showHighLow,setShowHighLow] = useState(false)
    const [futuresAvailable,setFuturesAvailable]=useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [historicOHLC,setHistoricOHLC]=useState(null)


    useEffect(()=>{
       if(future_key) showOrderDialog()
       return () => {
    }
    },[])


    function showOrderDialog(){
            DataFeedInstance.resolveSymbol(future_key, (symbolInfo)=>{
                var scrip_name = symbolInfo.odin_symbol
                var instrumentType = segmentToInstrumentType(symbolInfo.odin_exchange)
                setScripName(scrip_name)
                setOdinToken(symbolInfo.odin_token)
                setMarketSegmentId(symbolInfo.odin_market_segment_id)
                getFutureData(scrip_name,instrumentType)
                getHistoricalOHLC(symbolInfo.odin_token,symbolInfo.odin_market_segment_id)
            }, undefined, undefined)
    }

    function getHistoricalOHLC(token,segmentId){
        flowBackEndServices2.getHistoricalOHLC(token,segmentId,user.access_token).then(value => {
            return value.data
        }).then((data)=>{
            setHistoricOHLC(data)
        }).catch(reason => {
            console.log("Error in OHLC data",reason);
        })
    }

    function getFutureData(scripName,instrumentType){
        dispatch(openFutureChain())
        const data = {symbol: scripName,instrumentType: instrumentType}
        setIsLoading(true)
        flowBackEndServices2.getFutures(data,user.access_token).then(value => {
            return value.data
        }).then((data)=>{
                if(data.hasParentStock){
                    setFuturesData(data.Futures)
                }else{
                    setFuturesAvailable(false)
                }
              setIsLoading(false)
        }).catch(reason => {
            setIsLoading(false)
            console.log("Error in F&O data",reason);
        })
    }
    function iterateFutureData(){
        return futuresData.map((key,value)=>{
            return(
                <FutureSubsData key={value}  data={key}/>
            )
        })
    }

    function availableFuturesData(){
        return(
            <div className="container mt-4 mb-4 dataBackground">
                {futuresData?<FutureChainTableData data={futuresData} symbol={future_key} odinToken={odinToken} mktId={marketSegmentId}/>:null}
                {iterateFutureData()}
            </div>
        )
    }
    function notavailableFuturesData(){
        return(
            <div className="container notavailable">
                <h4>Futures are not available for {futuresAvailable?scripName:future_key}</h4>
            </div>
        )
    }
    
    function getFutureChainTable(){
        return(
            <React.Fragment>
                <div className="container mt-2" style={{"paddingLeft":"4.5%","paddingRight":"4.5%"}}>
                    <div className="row headerBackground"   >
                        <div className="col-5 p-3 tableHeader" style={{"justifyContent":"left","paddingLeft":"5%"}}>Symbol</div>
                        <div className="col-2 p-3 tableHeader" style={{"justifyContent":"left"}}>Expiry</div>
                        <div className="col-2 p-3 tableHeader" style={{"justifyContent":"left"}}>LTP</div>
                        <div className="col-3 p-3 tableHeader" style={{"justifyContent":"right","paddingRight":"5%"}}>Change</div>
                    </div>
                </div>
                { isLoading?<LoadingSpinner/>:futuresAvailable ?availableFuturesData():notavailableFuturesData()}

            </React.Fragment>
        )
    }

    function getMarketDepthData(){
        dispatch(openMarketDepthChain())
        return(
            <div className="container mt-2" >
                <div className="row">
                    <div className="col-12">
                        <div className="headerBackground" style={{"width":"48%","display":"inline-block","marginRight":"1%"}}>
                            <div className="row">
                                <div className="col-4 mktHeader alignMktDepth">Orders</div>
                                <div className="col-4 mktHeader alignMktDepth">Qty</div>
                                <div className="col-4 mktHeader alignMktDepth">Bid</div>
                            </div>
                        </div>
                        <div className="headerBackground" style={{"width":"48%","display":"inline-block","marginLeft":"1%"}}>
                        <div className="row">
                                <div className="col-4 mktHeader alignMktDepth">Offer</div>
                                <div className="col-4 mktHeader alignMktDepth">Qty</div>
                                <div className="col-4 mktHeader alignMktDepth">Orders</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-4">
                        <MarketDepthData symbol={future_key}/>
                    </div>
                </div>
            </div>
        )
    }

    function getHighLow(){
        dispatch(openHighLowChain())
        return(
            <React.Fragment>
                <div className="container mt-2" style={{"paddingLeft":"4.5%","paddingRight":"4.5%"}}>
                    <div className="row headerBackground my-4">
                        <div className="col-2 p-3 tableHeader" style={{"justifyContent":"left","paddingLeft":"5%"}}>Period</div>
                        <div className="col-6 p-3 tableHeader" style={{"position":"relative"}}>
                            <div style={{"position":"absolute","left":"0px"}}>Low</div>
                            <div style={{"position":"absolute","right":"-49px"}}>High</div>
                        </div>
                        <div className="col-4 p-3 tableHeader" style={{"justifyContent":"right","paddingRight":"5%"}}>Returns</div>
                    </div>
                </div>
                {historicOHLC?<HighLow data={historicOHLC} odinToken={odinToken} mktId={marketSegmentId}/>:null}
            </React.Fragment>
        )

    }

    function toggleTab(tab){
        const fut = document.getElementById("future")
        const mkt = document.getElementById("market")
        const hl = document.getElementById("highLow")
        if(tab==="future"){
            fut.classList.replace("toggleButton","activetoggleButton");
            mkt.classList.replace("activetoggleButton","toggleButton");
            hl.classList.replace("activetoggleButton","toggleButton");
            setShowFutureChain(true)
            setShowMarketDepth(false)
            setShowHighLow(false)
        }
        if(tab==="market"){
            mkt.classList.replace("toggleButton","activetoggleButton");
            fut.classList.replace("activetoggleButton","toggleButton");
            hl.classList.replace("activetoggleButton","toggleButton");
            setShowFutureChain(false)
            setShowMarketDepth(true)
            setShowHighLow(false)
        }
        if(tab==="highLow"){
            hl.classList.replace("toggleButton","activetoggleButton");
            mkt.classList.replace("activetoggleButton","toggleButton");
            fut.classList.replace("activetoggleButton","toggleButton");
            setShowFutureChain(false)
            setShowMarketDepth(false)
            setShowHighLow(true)
        }
    }


  return (
    <Draggable
    handle=".draggable"
    bounds="parent"
    allowAnyClick={true}
    >
        <div className={`futureChain`}>
            <div className="draggable">
                <FutureHeader data={future_key}/>
            </div>
            <div className="container py-2">
                <div className="row">
                    <div className="col-12">
                        <div className="toggleSection">
                            <div className="activetoggleButton mx-2 c-pointer" onClick={()=>toggleTab("future")} id="future">Futures Chain</div>
                            <div className="toggleButton mx-2 c-pointer" onClick={()=>toggleTab("market")} id="market">Market Depth</div>
                            <div className="toggleButton mx-2 c-pointer" onClick={()=>toggleTab("highLow")} id="highLow">Highs & Lows</div>
                            {/* <div className="toggleButton mx-2 c-pointer">Futures Chains</div>
                            <div className="toggleButton mx-2 c-pointer">Futures Chains</div> */}
                        </div>
                    </div>
                </div>
            </div>
            {showFutureChain && getFutureChainTable()}
            {showMarketDepth && getMarketDepthData()}
            {showHighLow && getHighLow()}
        </div>
    </Draggable>
  );
}

export default FutureChainModel;