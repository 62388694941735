import React, { useEffect,useState } from 'react';
import {useDispatch,useSelector} from "react-redux";
import Buy from "../../../assets/icons/Buy.svg"
import Sell from "../../../assets/icons/Sell.svg"
import moment from 'moment';
import {DataFeedInstance} from "../../../chart-datafeed/udf-compatible-datafeed";
import {SpotService} from "../../../sub";
import {NumberColor,ExchangeSegmentMap} from "../../common/number_color";


const AnalyseOptions = ({options,currentindex,minQuantity,currentMultiplier}) =>{
    const {analyse_data} = useSelector((state) => state.optionStrategy);

    const [ subData, setSubData ] = useState(null);

    useEffect(() => {
        if(subData) sendMessage();
    })


    function sendMessage() {
        SpotService.sendSpot(subData);
    }
    

    useEffect(() => {
        headerData()
        return () => {
            removeSub()
        }
    }, [options])

    let listenerGuid = undefined
    function headerData() {
        listenerGuid = `cepe-${currentindex}-${options.token}-${ExchangeSegmentMap[analyse_data.legs[0].option["exchange"]]}`
        DataFeedInstance.subcscribeQuotesFromTokens([
            {market_segment_id: ExchangeSegmentMap[analyse_data.legs[0].option["exchange"]] , token: options.token }],(quotes)=>{
            setSubData(quotes[0].v)
        },listenerGuid)
    }

    function removeSub(){
        DataFeedInstance.unsubscribeQuotes(listenerGuid)
    }

    
    function formatdate(date){
        let now = moment(date, "YYYYMMDD").format('DD MMM');
        return now
    }


    if(subData){
        return(
            <div style={{"display":"flex","flexDirection":"row","justifyContent":"space-between"}} className="mb-2">
                <div className='multvalue' style={{"fontSize":"18px"}}>{(options.quantity/minQuantity)*currentMultiplier}</div>
                <div  style={{"alignSelf": "center","height":"100%"}} className="px-2 cross"><i className="fa-solid fa-xmark"></i></div>
                <div style={{"position":"relative","top":"2px"}} className="px-1"><img height={"17px"} width={"17px"} src={options.action=="BUY"?Buy:Sell}/></div>
                <div style={{"alignSelf": "center","textAlign":"center","height":"100%","width":"60px"}} className=" sPrice">{(options.strike_price).toFixed(2)}</div>
                <div style={{"alignSelf": "center","height":"100%"}} className="cepe">{options.option_type}</div>
                <div style={{"alignSelf": "center","height":"100%","textAlign":"right","width":"50px"}} className="date">{formatdate(options.expiry_date)}</div>
                <div style={{"alignSelf": "center","height":"100%","width":"100px","textAlign":"center"}} className="ltp_change">{subData?subData.lp.toFixed(2):0}</div>
                <div style={{"alignSelf": "center","height":"100%","width":"100px","textAlign":"right"}} className="ltp_change"><NumberColor number={subData?subData.chp:0} returnSign={true}/>&nbsp;<NumberColor number={subData?subData.chp.toFixed(2):0} returnBracket={true} percentageAdd={true}/></div>
            </div>
        )
    }else{
        return(
            <div style={{"display":"flex","flexDirection":"row","justifyContent":"space-between"}} className="mb-2">
                <div style={{"fontSize":"18px"}}>1</div>
                <div style={{"alignSelf": "center","height":"100%"}} className="px-2"><i className="fa-solid fa-xmark"></i></div>
                <div style={{"position":"relative","top":"2px"}} className="px-1"><img height={"17px"} width={"17px"} src={Buy}/></div>
                <div style={{"alignSelf": "center","textAlign":"center","height":"100%","width":"60px"}} className=" sPrice">80000</div>
                <div style={{"alignSelf": "center","height":"100%"}} className="cepe">CE</div>
                <div style={{"alignSelf": "center","height":"100%","textAlign":"right","width":"50px"}} className="date">25 Mar</div>
                <div style={{"alignSelf": "center","height":"100%","color":"#171D27","width":"100px","textAlign":"center"}} className="ltp_change">24.5</div>
                <div style={{"alignSelf": "center","height":"100%","color":"#171D27","width":"100px","textAlign":"right"}} className="ltp_change">45.6</div>
            </div>
        )
    }
}

export default AnalyseOptions;