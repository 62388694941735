import apiServer from "./apiServer";
import {decodeBase64, STATUSES} from "../comman/status";
import {
    setStatus,
    setUser,
    clearMessage,
    setMessage, setOtpData,
    logout as logOutApp,
    setEmailAndMobile, setSessionTime,
    loginEvent,
} from "../slices/authSlice";
import {
    API_KEY,
    API_URL,
    MOMENT_KEY,
    SESSION_TIME,
    SOURCE,
    STORAGE_KEY, STORAGE_KEY_REFRESH,
    UD_ID,
    VERSION
} from "../utils/config";
import { remoteConfigGetter } from "../utils/remoteConfig";
import {toast} from "react-toastify";
import axios from "axios";
import * as moment from 'moment';

const fetchAuthUser = (formValues,navigate) => async (dispatch, getState) => {
    const data = getConfigData(formValues);
    dispatch(setStatus(STATUSES.LOADING));
    dispatch(clearMessage());

    try {
       const response = await apiServer.post('authentication/v1/user/session',data);
        dispatch(setStatus(STATUSES.IDLE));
        if(response.data.status === 'success'){
            saveUserSession(response.data.data,response.data.data.mpin_enabled,dispatch)
            console.info(response.data.data.user_id)
            await dispatch(GetUserProfile(response.data.data.user_id))
            dispatch(loginEvent())
            if(response.data.data.mpin_enabled === false) return navigate("/MPinSet",{ state: {type: 'FIRST_TIME'},replace: true});
            navigate("/tvTerminal",{ replace: true});
        }
    } catch (error) {
        dispatch(setStatus(STATUSES.ERROR));
        toast.error(`${error.response.data.message}`)
        dispatch(setMessage(error.response.data.message));
        if(error.response.status === 400 && error.response.data.errors === "e-1") {
            dispatch(setOtpData({data: formValues,user_id: formValues.user_id }));
            dispatch(setEmailAndMobile({mobile: formValues.second_auth,email: null }));
            navigate("/OldPasswordReset");
        }
    }
};



 const  GetUserProfile = (userId) => async (dispatch,getState) =>   {
    try {
        const response = await apiServer.get(`authentication/v1/getUserDetails/${userId}`,{headers: getHeader()});
        if(response.data.status) {
           let data = getMobileEmail(response.data.result)
            return dispatch(setEmailAndMobile(data));
        }
    } catch (error) {
    }
}


const logout = () => async (dispatch,getState) => {

    const key = JSON.parse(localStorage.getItem(STORAGE_KEY))
    var token = key?.access_token;
    logoutTerminal({},token).then(value => {
        return value.data
    }).then((data)=>{
        if(data.status=="success"){
            localStorage.removeItem(STORAGE_KEY);
            localStorage.removeItem(MOMENT_KEY);
            localStorage.removeItem(STORAGE_KEY_REFRESH);
        }
    }).catch(reason => {
        console.log("Error in logout api",reason)
    })
    dispatch(logOutApp())
};


async function ForgetPasswordOTP(formValues){
    const data = getConfigDataBasic(formValues);
    return apiServer.post('authentication/v1/user/password/reset/send-otp',data);
}

async function ChangePassword(formValues){
    const data = getConfigDataBasic(formValues);
    return apiServer.put('authentication/v1/user/password/reset',data);
}
async function NewPasswordSet(formValues){
    const data = getConfigDataBasic(formValues);
    return apiServer.put('authentication/v1/user/password',data);
}

async function MPinLogin(formValues){
    const data = getConfigDataBasic(formValues);
    return apiServer.post('authentication/v1/user/session',data);
}

async function ForgetMpinOtpSend(formValues){
    const data = getConfigDataBasic(formValues);
    return apiServer.post('authentication/v1/user/mpin/reset/send-otp',data);
}
async function MpinOtpVerify(formValues){
    const data = getConfigDataBasic(formValues);
    return apiServer.post('authentication/v1/user/otp/verify',data);
}

async function ForgotMpinSet(formValues){
    const data = getConfigDataBasic(formValues);
    return apiServer.post('authentication/v1/user/mpin/reset/verify-otp',data);
}

async function ChangeMpinFirstTime(formValues,headers){
    const data = getConfigDataBasic(formValues);
    return apiServer.post('authentication/v1/user/mpin',data,{headers: headers});
}


// DEPRECATED NO USE
async function SendUserId(formValues){
    return axios.post(remoteConfigGetter('inhouse_base_url_prod').asString()+'aux/forgotuserid',formValues,{headers: headers});
}

function logoutTerminal(formValues,token){

    var headers = {
        'Content-Type': 'application/json',
        'Authorization': "Bearer "+token
         // 'api_key': API_KEY,
      }
    return axios.delete(remoteConfigGetter('inhouse_base_url_prod').asString()+'user/session',{headers: headers});
}

async function optionChaindata(formValues,token){
    var headers = {
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_VORTEX_KEY,
        "X-Application-Id": process.env.REACT_APP_TV_APPLICATION_ID,
        "X-Stage": process.env.REACT_APP_ENV,
        'Authorization': "Bearer "+token
         // 'api_key': API_KEY,
      }
    return axios.post(remoteConfigGetter('inhouse_base_url_prod').asString()+'aux/scrip/optiondata',formValues,{headers: headers});
}

async function newOptionChaindata(formValues,token){
    var headers = {
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_VORTEX_KEY,
        "X-Application-Id": process.env.REACT_APP_TV_APPLICATION_ID,
        "X-Stage": process.env.REACT_APP_ENV,
        'Authorization': "Bearer "+token
         // 'api_key': API_KEY,
      }
    return axios.post(remoteConfigGetter('inhouse_base_url_prod').asString()+'strategies/option_chain',formValues,{headers: headers});
}

async function OtpVerify (otp,user_id,mobileNo)  {
    let formData = {mobileNo,otp,user_id}
    const data = getConfigDataBasic(formData);
    return apiServer.post('authentication/v1/user/otp/verify',data);
}

function getConfigData(formValues) {
    const api_key = API_KEY;
    const version = VERSION;
    const source = SOURCE;
    const login_type = "PASSWORD";
    return {
        ...formValues,
        api_key,
        UDID: UD_ID,
        version,
        source,
        login_type
    };
}

function getConfigDataBasic(formValues) {
    const api_key = API_KEY;
    const source = SOURCE;
    return {
        ...formValues,
        api_key,
        source
    };
}

function getMobileEmail(data) {
    return { email:decodeBase64(data.email),mobile: decodeBase64(data.mobile)}
}

function getHeader() {
  return  {'api_key': API_KEY}
}

function setTimeSession(dispatch) {
    var momentInTime = moment().add(SESSION_TIME,'minutes')
    dispatch(setSessionTime(momentInTime.valueOf()))
    localStorage.setItem(MOMENT_KEY,momentInTime.valueOf().toString())
}

function saveUserSession(user, mpin, dispatch) {
    dispatch(setUser(user));
    setTimeSession(dispatch);
    if (mpin) localStorage.setItem(STORAGE_KEY,JSON.stringify(user))
}

function saveUserSessionFlow(user,refreshData,dispatch) {
    dispatch(setUser(user));
    setTimeSession(dispatch);
    localStorage.setItem(STORAGE_KEY,JSON.stringify(user))
    localStorage.setItem(STORAGE_KEY_REFRESH,JSON.stringify(refreshData))
}



const authService = {
    logout,
    fetchAuthUser,
    ForgetPasswordOTP,
    GetUserProfile,
    OtpVerify,
    ChangePassword,
    SendUserId,
    NewPasswordSet,
    MPinLogin,
    saveUserSession,
    ForgetMpinOtpSend,
    MpinOtpVerify,
    ForgotMpinSet,
    ChangeMpinFirstTime,
    optionChaindata,
    saveUserSessionFlow,
    newOptionChaindata
};

export default authService;
