import React, {useEffect, useRef, useState} from 'react';
import Background from "../../BackGround/background";
import CopyIcon from "../../../assets/images/copy.svg";
import RightIcon from "../../../assets/images/tick-circle.svg";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import flowBackEndServices2 from "../../../services/flowBackendServices";
import {toast} from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import OtpInput from "react-otp-input";
import refresh from "../../../assets/images/refresh-circle.svg";
import Timer from "../../common/timer";
import {STORAGE_KEY, STORAGE_KEY_REFRESH} from "../../../utils/config";

function EnableTotp(props) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data,user } = useSelector((state) => state.authUser);
    const [Otp, setOtp] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const textAreaRef = useRef(null);

    const [formState, setFormState] = useState({key: '', topt: ''});
    const [ errors, setErrors ] = useState({})

    const [apiData, setApiData] = useState(null);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() => {
        if(user === null){
            navigate("/")
        }
        getTotpData()
        // setApiData({image: base64Image})
    },[])

    useEffect(() => {
        if(apiData) setFormState({...formState,key: apiData.key})
    },[apiData])



     function handleOnClick(event) {
        event.preventDefault();

        // get our new errors
        const newErrors = findFormErrors()
        // Conditional logic:
        if ( Object.keys(newErrors).length > 0 ) {
            setErrors(newErrors)
        } else {
            UpdateUser2fA()
        }

    }

    function updateUserStoreForTotp() {
        const refreshData = JSON.parse(localStorage.getItem(STORAGE_KEY_REFRESH));
        let refreshDataNew = {...refreshData,mfaType:'totp'}
        localStorage.setItem(STORAGE_KEY_REFRESH,JSON.stringify(refreshDataNew))
    }

    function UpdateUser2fA() {
        let dataSend = {otp: `${formState.topt}`,type: 'totp'}
        setIsLoading(true)
        flowBackEndServices2.update2faPref(dataSend,user.access_token).then(value => {
            if(value?.status === 200) {
                if(value.data.status === 'success'){
                    handleShow()
                    updateUserStoreForTotp()
                    // navigate("/tvTerminal",{ replace: true});
                }else {
                    toast.error(`${value.data.message}`)
                }
            }
        }).catch(reason => {
            toast.error(`Opps something went wrong`)
            setIsLoading(false)
        }).finally(() =>  setIsLoading(false))

    }




    const findFormErrors = () => {
        const { topt, key } = formState
        const newErrors = {}

        if ( !topt || topt === '' ) newErrors.topt = 'TOTP is required!'
        else if ( topt.length !== 6 ) newErrors.topt = 'TOTP required is 6 digit'


        return newErrors
    }

    function handleFormStateChange(event) {
        const { name, value } = event.target;
        setFormState({ ...formState, [name]: value });

        if ( !!errors[name] ) setErrors({
            ...errors,
            [name]: null
        })
    }

    function getTotpData() {
        const { access_token } = user;
        setIsLoading(true)
        flowBackEndServices2.getTOtpData(access_token).then(value => {
            if(value.status === 200){
                setApiData(value.data)
                // toast.success(`TOTP DATA`)
            }else {
                 toast.info(`Opps something went wrong`)
            }
        }).catch(reason => {
            toast.error(`Failed`)
            setIsLoading(false)
        }).finally(() =>  setIsLoading(false))

    }


    function copyToClipboard(e) {
        textAreaRef.current.select();
        document.execCommand('copy');
        e.target.focus();
    }

    function gotIt() {
        navigate("/tvTerminal",{ replace: true});
    }

    const onlyNumber = (event) => {
            if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
            }
    }


    return (
        <Background>

            <div className="h-100 d-flex card_padding flex-column justify-content-start">


               <div className="align-self-center">
                   <div className="heading1">Setup 2FA TOTP</div>
               </div>

                <div className="d-flex flex-column">
                    <div className="step_badge">Step 1</div>
                    <div className="heading2">Download ‘Authenticator App’ from App Store</div>
                </div>

                <div className="pt-5 d-flex flex-row justify-content-between align-items-end">
                    <div className="d-flex flex-fill flex-column">
                        <div className="step_badge">Step 2</div>
                        <div className="heading2">Copy Setup Key or Scan QR <br/> code on authenticator app</div>
                        <div className="">
                            <Form.Group className="position-relative" controlId="formBasicEmail">
                                <div onClick={copyToClipboard} className="copyKey"><img alt={''} className="mr-1" src={CopyIcon}/>copy</div>
                                <Form.Control type="text"
                                              name="key"
                                              required
                                              ref={textAreaRef}
                                              isInvalid={ !!errors.key }
                                              value={formState.key}
                                              onChange={handleFormStateChange}
                                              placeholder="Key" />
                            </Form.Group>
                        </div>
                    </div>
                    <div className="QRcode_box d-flex flex-row justify-content-center align-items-center">
                        {isLoading ? (
                            <Spinner className="mr-2" as="span" animation="border" variant="primary" role="status" aria-hidden="true"/>
                        ) : (
                            <div className="p-2">
                                { apiData && (
                                    <img className="" src={"data:image/jpeg;base64," + apiData.image}  alt={''}/>
                                )}
                            </div>
                        )}

                    </div>
                </div>

                <div className="pt-5">
                    <div className="step_badge">Step 3</div>
                    <div className="heading2">Enter the 6 digit TOTP generated <br/> in authenticator app</div>
                    <div className="">
                        <Form.Group className="" controlId="">
                            <Form.Control type="text"
                                          name="topt"
                                          required
                                          isInvalid={ !!errors.topt }
                                          value={formState.topt}
                                          onKeyPress={onlyNumber}
                                          onChange={handleFormStateChange}
                                          placeholder="Enter TOTP" />
                            <Form.Control.Feedback type='invalid'>
                                { errors.topt }
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </div>

                <div style={{fontSize:'13px'}} className="pt-3 colorGrey">*Note: Once the TOTP is setup, use the 6 digit TOTP generated in the same app, every time you login.</div>

                <div className="pt-5 w-100 d-flex flex-row justify-content-center">
                    <Button className="blue_btn text-nowrap w-75" onClick={handleOnClick} variant="primary" type="submit">
                        Enable Now
                    </Button>
                </div>


                 {/*Model*/}
                <Modal contentClassName="modal-content2" centered show={show} backdrop="static" onHide={handleClose}>
                    <Modal.Body>

                        <div className="d-flex flex-column justify-content-center align-items-center">


                            {/*<button onClick={handleClose} className="align-self-end btn-close"/>*/}

                            <div className=""><img src={RightIcon} alt={''}/></div>

                            <h3 className="heading1 py-2">TOTP enabled successfully</h3>

                            <Button className="blue_btn text-nowrap w-75 mb-2" onClick={gotIt} variant="primary" type="submit">
                                Got it
                            </Button>

                        </div>

                    </Modal.Body>
                </Modal>

            </div>

        </Background>
    );
}

export default EnableTotp;
