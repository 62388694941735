import React, {useEffect, useState} from 'react';
import Background from "../BackGround/background";
import './mpin.css'
import OtpInput from "react-otp-input";
import MadeInLoveIndia from "../common/madeInLoveIndia";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import {Link, useNavigate} from "react-router-dom";
import authService from "../../services/authService";
import {toast} from "react-toastify";
import {useLocation} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {setMpinEnable} from "../../slices/authSlice";


function MpinSet(props) {
    const { state } = useLocation();

    const navigate = useNavigate();
    const [Otp, setOtp] = useState('');
    const [OtpConform, setOtpConform] = useState('');
    const { user,mobile,data } = useSelector((state) => state.authUser);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();


    useEffect(() => {
        if(state === null){
            navigate("/ForgotMpin")
        }
    },[])

    function callMpinSetApi() {
        const dataPost = {
            user_id: user.user_id,
            mobileNo: mobile,
            otp: data,
            mpin: Otp,
            mobile_udid: "",
        }
        setIsLoading(true);
        authService.ForgotMpinSet(dataPost)
            .then(value => {
                if(value.data.status === "success"){
                    toast.success(value.data.message)
                    navigate("/tvTerminal",{ replace: true});
                }
            })
            .catch(error => {
                toast.error(`${error.response.data.message}`)
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            })

    }

    function callMpinFirstTimeSet() {
        const dataPost = {
            mpin: Otp,
            mobile_udid: "",
        }
        const header = {
            "Authorization": `Bearer ${user.access_token}`
        }
        setIsLoading(true);
        authService.ChangeMpinFirstTime(dataPost,header)
            .then(value => {
                if(value.data.status === "success"){
                    toast.success(value.data.message)
                    dispatch(setMpinEnable())
                    setTimeout(() => {
                        navigate("/tvTerminal",{ replace: true});
                    }, 100);
                }
            })
            .catch(error => {
                toast.error(`${error.response.data.message}`)
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            })

    }

    function handleOnClick(event) {
        event.preventDefault();
        if(Otp.length === 4 && Otp === OtpConform){
            state?.type === "OTP" ?  callMpinSetApi() : callMpinFirstTimeSet();
        }else {
            toast.warning("Both Mpin Not Matched")
        }
    }

    return (
        <Background>
            <div className="h-100 d-flex card_padding flex-column justify-content-between">


                <div className="">

                    {state?.type === "OTP" && (
                        <div className="">
                            <Link to="/"><i className="fas fa-arrow-left fas fa-lg"/></Link>
                        </div>
                    )}
                    <h3 className="heading1 py-0">Set PIN</h3>

                    <div className="colorGrey pt-0 pb-3">Set 4 digit pin</div>

                </div>

                <div className="flex-fill w-100">
                    <div className="colorBlack">Enter pin</div>
                    <div className="w-100 mt-2 d-flex flex-row justify-content-center align-items-center">
                        <OtpInput
                            inputStyle="css_input_box"
                            value={Otp}
                            onChange={setOtp}
                            numInputs={4}
                            isInputNum={true}
                            isInputSecure={true}
                            separator={<span/>}
                        />
                    </div>

                    <div className="colorBlack">Confirm pin</div>
                    <div className="w-100 mt-2 d-flex flex-row justify-content-center align-items-center">
                        <OtpInput
                            inputStyle="css_input_box"
                            value={OtpConform}
                            onChange={setOtpConform}
                            numInputs={4}
                            isInputNum={true}
                            isInputSecure={true}
                            separator={<span/>}
                        />
                    </div>


                </div>




                <div className="w-100">

                    <Button disabled={isLoading || OtpConform.length !== 4} className="blue_btn w-100 my-3" onClick={handleOnClick} variant="primary" type="submit">
                        {isLoading && (
                            <Spinner className="mr-2" as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                        )}
                        Continue
                    </Button>

                    <MadeInLoveIndia/>
                </div>


            </div>



        </Background>
    );
}

export default MpinSet;
