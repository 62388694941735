import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";
import { getRemoteConfig,getValue,fetchConfig } from "firebase/remote-config";
import { STAGE } from "./config";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAYtBD2MpzN-WPRftR9RUEb5dZSs9ShYwY",
  authDomain: "flow-india.firebaseapp.com",
  projectId: "flow-india",
  storageBucket: "flow-india.appspot.com",
  messagingSenderId: "996040433390",
  appId: "1:996040433390:web:3800f4b49135e5ed461dff",
  measurementId: "G-JMJVTV89PG"
};

const remoteConfigVariables = new Map()

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
app.automaticDataCollectionEnabled = true 
export const analytics = getAnalytics(app);
var messaging
try {
    messaging = getMessaging(app);
} catch (error) {
    messaging = null
}

export {messaging}
export const remoteConfig = getRemoteConfig(app);
remoteConfig.defaultConfig = {
    "x_api_key": process.env.REACT_APP_VORTEX_KEY ,
    "live_base_url": process.env.REACT_APP_LIVE_BASE_URL,
    "basket_share_url":process.env.REACT_APP_BASKET_SHARE,
    "inhouse_base_url_prod": process.env.REACT_APP_VORTEX_URL,
    "wave_2_Api_Key": process.env.REACT_APP_WAVE_2_API_KEY,
    "vapidkey": "BHNI0Bx9iMUXbispgUwx4yZtqY-Y9HFvNdeBW6WQTKGmsihfq5-GsVCtkTmNgHXMqik77zhbECrWCr0jd3Cu7NY",
    "ltp_socket_url": "wss://feed.rupeezy.in:4518/",
    "ltp_proxy_url": "wss://feed-proxy.rupeezy.in:4517/",
}
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
if(process.env.REACT_APP_ENV == "production"){
    fetchConfig(remoteConfig)
}


export function remoteConfigGetter(value){
    return getValue(remoteConfig, value);
}