import React from 'react'
import { useState, useEffect } from 'react';

function Timer ({initialMinute,initialSeconds,showResend}) {

    const [ minutes, setMinutes ] = useState(initialMinute);
    const [seconds, setSeconds ] =  useState(initialSeconds);
    let myInterval = undefined;

    useEffect(()=>{

    },[]);

    useEffect(()=>{
        timeset()
    },[seconds]);



    function timeset() {
        if (seconds > 0) {
            setTimeout(() => setSeconds(seconds - 1),1000)
        }
        if (seconds === 0) {
            if (minutes === 0) {
                showResend();
                clearInterval(myInterval)
            } else {
                setMinutes((minutes - 1));
                setSeconds(59);
            }
        }
    }

    return (
        <div>
            { minutes === 0 && seconds === 0
                ? null
                : <h6 className="ml-1"><i style={{color: "#ff9933"}} className="fa-solid fa-stopwatch"/>  {minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</h6>
            }
        </div>
    )
}

export default Timer;
