import React, {useEffect, useRef, useState} from 'react';
import {ReactComponent as NotificationIcon} from "../../assets/icons/notification-status.svg";
import {ReactComponent as EditIconIcon} from "../../assets/icons/edit-2.svg";
import {ReactComponent as ShareIcon} from "../../assets/icons/Share.svg";
import {ReactComponent as DeletIcon} from "../../assets/icons/trash.svg";
import BasketketHeader from "./Header/bacsketHeader";
import {setCurrentBasketState} from "../../slices/basketOrderSlice";
import {shallowEqual,useDispatch, useSelector} from "react-redux";
import {currentUser} from "../../slices/authSlice";
import flowBackEndService from "../../services/flowBackendApi";
import {toast} from "react-toastify";
import Button from "react-bootstrap/Button";
import LoadingSpinner from '../NewOptionChain/LoadingSpinner';
import {basketShareEvent} from "../../slices/basketOrderSlice"

const BucketList = ({listData}) => {
    const textRef = useRef(null);
    const dispatch = useDispatch();
    const user = useSelector(currentUser);
    const [showDisclaimer,setShowDisclaimer]=useState(false)
    const [sharingData,setSharingData]=useState(null)
    const [selectedData, setselectedData ] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const public_basket_data = useSelector((state => state.basketOrder.public_basket_data), shallowEqual);

    const showDetails = (data) => {
        let state =  {id: 3,param: data,url:'BasketDetails'};
        dispatch(setCurrentBasketState(state))
        setselectedData(data)
    }

    useEffect(()=>{
        let basket_id=sessionStorage.getItem("public_basket_id")
        if(basket_id && basket_id!=""){
            let state =  {id: 3,param: public_basket_data,url:'BasketDetails'};
            dispatch(setCurrentBasketState(state))
            setselectedData(public_basket_data)
        }
    },[])



    const startEditMode = (data) => {
        let state =  {id: 2,param: {edit_mode: true,data: data},url:'CreateBasket'};
        dispatch(setCurrentBasketState(state))
    }

    const handleOnClick = (e) => {
        let state =  {id: 2,param: null,url:'CreateBasket'};
        dispatch(setCurrentBasketState(state))
    }

    const acceptDisclaimer = (e) =>{
        getShareLink(sharingData)
        localStorage.setItem('showDisclaimer', true);
        setShowDisclaimer(false)
    }
    const copyShareLink = (data)=>{
        if(data.basketOrders.length>0){
            dispatch(basketShareEvent())
            const disclaimerShowed = localStorage.getItem('showDisclaimer');
            if(disclaimerShowed){
                getShareLink(data)
            }else{
                setSharingData(data)
                setShowDisclaimer(true)
            }
        }else{
            toast.error(`At least one order should be in the basket`)
        }
    }

    const getShareLink =(data)=>{
        var link=""
        setIsLoading(true)
        let input = {
            basketId:(data.id).toString(),
            emailId:user.email,
            basketExpiryDate:getBasketExpiry(data.basketOrders)
        }
        flowBackEndService.basketShareLink(input,user.access_token)
        .then(value => {
            return value.data
        })
        .then(value=>{
            if(value.status=="success"){
                link=value.data.response
                if (!textRef.current) {
                    navigator.clipboard.writeText(link)
                      .then(() => {
                        toast.success("link copied successfully!!!")
                      })
                      setIsLoading(false)
                  }
            }else{
                toast.error(value.data.message);
                setIsLoading(false)
            }
        })
        .catch(error => {
            console.log("Error during share basket link ",error.message)
            toast.error("link not copied");
            setIsLoading(false)
        })
    }

    const getBasketExpiry=(orders)=>{
        let array=[]
        orders.map((data)=>{
            if(data.stockData){
                if(data.stockData.expiryDate){
                    array.push(data.stockData.expiryDate)
                }
            }
        })
        if(array.length>0){
            const minExpiryDate = Math.min(...array);
            const date = new Date(minExpiryDate * 1000);
            const year = date.getFullYear();
            const month = date.getMonth() + 1; // Adding 1 because month index is zero-based
            const day = date.getDate();
            return year.toString()+month.toString().padStart(2, '0')+day.toString()
        }else{
            return ""
        }
    }


    const startDeleteMode = (data) => {
        let state =  {id: 1,param: {fetch_new: true},url:'BucketList/EmptyState'};

        flowBackEndService.DeleteBasket(data,user.access_token,data.id)
            .then(value => {
                if(value.data.status ===  'success'){
                    dispatch(setCurrentBasketState(state))
                }else{
                    BrokerApiInstance._host.showNotification("Error during deleting basket",value.data.message ,0)
                }
            })
            .catch(error => {
                BrokerApiInstance._host.showNotification("Error during deleting basket",error.response.data.message ,0)
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    if(showDisclaimer){
        return(
            <React.Fragment>
                <BasketketHeader>
                   <span className="heading2">&nbsp;Disclaimer</span>
                </BasketketHeader>

                <div className='my-3 disclaimer'>
                    <div style={{"marginTop":"20px"}}>1.The Basket sharing link will create a copy of this basket and will be valid for 30 days from the day its shared. In case any scrip in the basket expires within 30 days, the basket link will also expire.</div>
                    <div style={{"marginTop":"30px"}}>2.The link can be shared multiple times to multiple people from your end and receiver's end also. By sharing your basket, you are granting permission for further sharing.</div>
                    <div class="highlight" style={{"marginTop":"65px"}}>Your original basket will not be affected in any way.</div>
                </div>
                <div className="align-self-end mb-5 create_new_btn mr-5">
                        <Button style={{"width":"209px"}}  className="blue_btn my-3 text-nowrap" onClick={acceptDisclaimer} variant="primary" type="submit">
                            Ok,got it!
                        </Button>
                    </div>
            </React.Fragment>
        )

    }else{
        return (
            <React.Fragment>
                <BasketketHeader>
                    <span className="heading2">&nbsp;Basket Orders ({listData?.length ?? 0}/10)</span>
                </BasketketHeader>
                {isLoading && <LoadingSpinner />}
                {!isLoading && <div className="d-flex srollbox2 mb-0 pt-1 flex-column w-100  justify-content-start align-items-stretch">
                    {
                        listData.map(d => {
                            return(
                                <div key={d.id} className="bucket_name_bg currentBg2 my-1">
                                    <div onClick={() =>showDetails(d)} className="d-flex align-items-center justify-content-start c-pointer">
                                        <div className="mr-1 mb-1"><NotificationIcon width="17" height="16"/></div>
                                        <div className="details_1 currentText">{d.basketName}</div>
                                    </div>
    
                                    <div onClick={() =>showDetails(d)} className="flex-fill c-pointer">&nbsp;</div>
    
                                    <div className="stock_box currentText c-pointer">{d.basketOrders.length} stock</div>
                                    <div className="trash_box">
                                        <div className="d-flex align-items-end justify-content-end">
                                        <div onClick={() => copyShareLink(d)} className="mr-1 mb-1 c-pointer"><ShareIcon width="16" height="16" /></div>
                                         <div className="mx-2">&nbsp;</div>
                                         <div onClick={() => startEditMode(d)} className="mr-1 mb-1 c-pointer"><EditIconIcon width="18" height="18" /></div>
                                         <div className="mx-2">&nbsp;</div>
                                         <div onClick={() =>startDeleteMode(d)}  className="mr-1 mb-1 c-pointer"><DeletIcon width="18" height="18" /></div>
                                        </div>
                                    </div>
                                </div>
    
                            )
                        })
                    }
                </div>}
    
                {listData?.length < 10 && (
                    <div className="align-self-end mb-3 create_new_btn mr-5">
                        <Button  className="blue_btn my-3 text-nowrap" onClick={handleOnClick} variant="primary" type="submit">
                            Create New basket
                        </Button>
                    </div>
                )}
    
    
            </React.Fragment>
    
        );
    }

}

export default BucketList;


