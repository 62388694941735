import axios from 'axios';
import {API_KEY, API_URL, X_API_KEY} from "../utils/config";
import { remoteConfigGetter } from '../utils/remoteConfig';

var config = {
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_VORTEX_KEY,
    "X-Application-Id": process.env.REACT_APP_TV_APPLICATION_ID,
    "X-Stage": process.env.REACT_APP_ENV
     // 'api_key': API_KEY,
  },
}
export default axios.create(config);
