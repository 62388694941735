import * as React from 'react';
import {BrokerApiInstance} from '../../chart-brokerapi/broker'
import Draggable from "react-draggable";
import {ReactComponent as CloserIcon} from "../../assets/images/close-square.svg";
import './index.scss'
export let ChargesInstance = null;
export class Charges extends React.Component{
    constructor(props) {
        super(props);
        this.state = this.defaultStateParams
		this.ref = React.createRef();
        this.closeChargesDialog = this.closeChargesDialog.bind(this)
        ChargesInstance = this
    }
    defaultStateParams = {
        isVisible: false
    }

    closeChargesDialog(){
        this.setState(this.defaultStateParams)
    }

    showChargesDialog(order){
        
        this._showChargesDialog(order)
    }

    _showChargesDialog(order){
        console.log(order)
        BrokerApiInstance.getBrokerage(order).then((result)=>{
            
            var turnover = (order.qty * order.lot_size )*(order.market_type == 1 ?  parseFloat(order.limitPrice) : order.ltp)
            var total = result.brokerage + result.exchangeTransactionCharges + result.gst + result.dpCharges+ result.clearingCharges+ result.stt +result.sebiCharges +result.stampDuty
            this.setState({...this.state, isVisible: true, ...result, turnover: turnover, total: total} )
        })
        
        // NSE_FO
        // NSE_EQ 
        // MCX_FO
        // NSE_CUR

        // INTRADAY
        // MTF
        // DELIVERY
    }
    closeChargesDialog(){
        this.setState({...this.state, isVisible: false})
    }
    render() {
        if(!this.state.isVisible ){
            return (
            
                <div
                    ref={ this.ref }
                    className={ 'custom-order-dialog' }
                >

                </div>
            );
        }else{
            return(
                <Draggable>
                    <div ref={ this.ref } className="charges-container"> 
                        <div className='title'>
                            <span>Charges Summary</span>
                            <div className="close" id="close-button">
                                    {/*<button className="button custom-order-dialog__close_button" id="custom-order-dialog__quote-close_button-id" onMouseUp={this.closeOrderDialog} />*/}
                                    <div onClick={this.closeChargesDialog} className="c-pointer" id=""><CloserIcon/></div>
                            </div>
                        </div>

                        {/* <div className='subtitle'>
                            <span>Transaction Estimates</span>
                        </div> */}

                        
                        <div className='charges-row'>
                            <span>Exchange Turnover</span>
                            <div className="charges-value">
                                <span>&#8377;</span>
                                <span>{Math.round((this.state.turnover+Number.EPSILON)*10000)/10000}</span>
                            </div>
                        </div>
                        
                        <div className='charges-row'>
                            <span>Brokerage</span>
                            <div className="charges-value">
                                <span>&#8377;</span>
                                <span>{Math.round((this.state.brokerage+Number.EPSILON)*10000)/10000}</span>
                            </div>
                            
                        </div>
                        <div className='charges-row'>
                            <span>Exchange Transaction Charges</span>
                            <div className="charges-value">
                                <span>&#8377;</span>
                                <span>{Math.round((this.state.exchangeTransactionCharges+Number.EPSILON)*10000)/10000}</span>
                            </div>
                            
                        </div>
                        <div className='charges-row'>
                            <span>Securities Transaction Tax(STT)</span>
                            <div className="charges-value">
                                <span>&#8377;</span>
                                <span>{Math.round((this.state.stt+Number.EPSILON)*10000)/10000}</span>
                            </div>
                            
                        </div>
                        <div className='charges-row'>
                            <span>GST(on brokerage & exchange charges)</span>
                            <div className="charges-value">
                                <span>&#8377;</span>
                                <span>{Math.round((this.state.gst+Number.EPSILON)*10000)/10000}</span>
                            </div>
                            
                        </div>
                        <div className='charges-row'>
                            <span>SEBI Charges</span>
                            <div className="charges-value">
                                <span>&#8377;</span>
                                <span>{Math.round((this.state.sebiCharges+Number.EPSILON)*10000)/10000}</span>
                            </div>
                            
                        </div>
                        <div className='charges-row'>
                            <span>Clearing Charges</span>
                            <div className="charges-value">
                                <span>&#8377;</span>
                                <span>{Math.round((this.state.clearingCharges+Number.EPSILON)*10000)/10000}</span>
                            </div>
                            
                        </div>
                        <div className='charges-row'>
                            <span>Stamp Duty</span>
                            <div className="charges-value">
                                <span>&#8377;</span>
                                <span>{Math.round((this.state.stampDuty+Number.EPSILON)*10000)/10000}</span>
                            </div>
                            
                        </div>
                        <div className='charges-row'>
                            <span>DP Charges(inclusive of taxes)</span>
                            <div className="charges-value">
                                <span>&#8377;</span>
                                <span>{Math.round((this.state.dpCharges+Number.EPSILON)*10000)/10000}</span>
                            </div>
                            
                        </div>
                        <div className='charges-row '>
                            <span className='bold'>Total tax & Charges</span>
                            <div className="charges-value">
                                <span>&#8377;</span>
                                <span>{Math.round((this.state.total+Number.EPSILON)*10000)/10000}</span>
                            </div>
                        </div>
                        
                        
                    </div>
                </Draggable>
            )
        }
        
    }
}