import React from 'react';
import Background from "../../BackGround/background";
import clockIcon from "../../../assets/images/clock.svg";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {loginEvent, TotpDoLaterEvent} from "../../../slices/authSlice";

function SetTotp(props) {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { themeData } = useSelector((state) => state.authUser);


    function EnableNavigate() {
        navigate('/EnableTOtp')
    }

    function DoLaterNavigate() {
        dispatch(TotpDoLaterEvent())
        if(themeData && (themeData.verifySSO === false)){
            navigate("/tvTerminal",{ replace: true});
        }else {
            toast.error(`No Redirect Url Found`)
        }
    }



    return (
        <Background>

            <div className="h-100 d-flex card_padding flex-column justify-content-start align-items-start">


                <div className="pt-5 d-flex flex-column">
                    <div className="pb-5"><img src={clockIcon} alt='clockIcon'/></div>
                    <div className="heading1">Set TOTP</div>
                    <div style={{fontSize: '14px',color:'#586572'}} className="colorGrey pb-3">Required for Two Factor Authentication (2FA)</div>
                </div>

                <div className="pt-3 d-flex flex-column">
                    <div style={{fontSize: '16px'}} className="heading1">Why should I set TOTP?</div>
                    <div style={{fontSize: '12px',color:'#171D27',paddingLeft:'20px'}} className="colorGrey">
                        <ol>
                            <li>TOTP (Time Based One Time Password) is more reliable as it is not dependent on network to receive the OTP. </li>
                            <li>It is also more secure as it protects against sim cloning.</li>
                        </ol>
                    </div>
                </div>


                <div style={{paddingTop:'50px'}} className="w-100 px-2 d-flex flex-row justify-content-between align-self-baseline">
                   <div className="w-50">
                       <Button className="grey_btn text-nowrap w-100" onClick={DoLaterNavigate} variant="light" type="submit">
                           I’ll do it later
                       </Button>
                   </div>
                    <div className="w-50 ml-3">
                        <Button className="blue_btn text-nowrap w-100" onClick={EnableNavigate} variant="primary" type="submit">
                            Enable Now
                        </Button>
                    </div>
                </div>



            </div>

        </Background>
    );
}

export default SetTotp;
