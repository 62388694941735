import React, {useEffect, useRef, useState} from 'react';
import BasketketHeader from "../Header/bacsketHeader";
import Form from "react-bootstrap/Form";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import authService from "../../../services/authService";
import {toast} from "react-toastify";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import flowBackEndService from "../../../services/flowBackendApi";
import {setCurrentBasketState} from "../../../slices/basketOrderSlice";
import {currentUser} from "../../../slices/authSlice";
import {basketSubServices} from "../basketSub";

const CreateBasket = () => {

    const [formState, setFormState] = useState({name_basket: ''});
    const [ errors, setErrors ] = useState({})
    const dispatch = useDispatch();
    const user = useSelector(currentUser);

    const [isLoading, setIsLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editData, setEditData] = useState(null);

    const { current_basket } = useSelector((state) => state.basketOrder);


    function chekCurrentState() {
        if(current_basket.id === 2 && current_basket.param?.edit_mode){
            setEditMode(current_basket.param.edit_mode)
            setEditData(current_basket.param.data)
        }
    }

    useEffect(() => {
        if (current_basket) chekCurrentState()
    }, [current_basket])

    useEffect(() => {
        if (editData) setFormValue()
    }, [editData])

    function setFormValue() {
        setFormState({ ...formState, name_basket: editData.basketName });
    }

    function handleFormStateChange(event) {
        const { name, value } = event.target;

        setFormState({ ...formState, [name]: value });

        if ( !!errors[name] ) setErrors({
            ...errors,
            [name]: null
        })
    }


    function handleOnClick(event) {
        event.preventDefault();

        // get our new errors
        const newErrors = findFormErrors()
        // Conditional logic:
        if ( Object.keys(newErrors).length > 0 ) {
            setErrors(newErrors)
        } else {
            editMode ? callEditBasket(formState.name_basket) : callCreateBasket(formState.name_basket);
        }

    }

    function callEditBasket(name) {

        if(name.trim() === editData.basketName){
            toast.info(`Try editing basket Name`);
            return
        }

        setIsLoading(true)
        const data = {basketName: name,orders:current_basket.param.data.basketOrders}
        flowBackEndService.UpdateBasket(data,user.access_token,editData.id)
            .then(value => {
                if(value.data.status ===  'success'){
                    onBackFetchNewData()
                }else{
                    toast.error(`Error update ${value?.data?.message}`);
                }
            })
            .catch(error => {
                toast.error(`${error.response.data.message}`)
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    function callCreateBasket(name) {
        setIsLoading(true)
        const data = {name}
        flowBackEndService.AddBasket(data,user.access_token)
            .then(value => {
                if(value.data.basketName.length > 0){
                    // toast.success(`Basket Created ${value.data.basketName}`);
                    // onBackFetchNewData()
                    goforwordSearchPage(value.data)
                }
            })
            .catch(error => {
                toast.error(`${error.response.data.message}`)
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }


    const findFormErrors = () => {
        const { name_basket } = formState
        const newErrors = {}
        // name errors
        if ( !name_basket || name_basket === '' ) newErrors.name_basket = 'Basket name id is required!'
        else if ( name_basket.length > 15 ) newErrors.name_basket = 'Basket name is too large!'
        else if ( name_basket.length < 3 ) newErrors.name_basket = 'Basket name is too sort!'

        return newErrors
    }

    const onBackClick = () => {
        let state =  {id: 1,param: null,url:'BucketList/EmptyState'};
        dispatch(setCurrentBasketState(state))
    }

    const onBackFetchNewData = () => {
        let state =  {id: 1,param: {fetch_new: true},url:'BucketList/EmptyState'};
        dispatch(setCurrentBasketState(state))
    }

    const goforwordSearchPage = (dataRes) => {
        let data = {...dataRes,basketOrders: []}
        basketSubServices.setNewBasket(data)
        let state =  {id: 3,param: data,url:'BasketDetails'};
        dispatch(setCurrentBasketState(state))
    }



    return (
        <React.Fragment>

            <BasketketHeader>
                <span className="heading2"> <i onClick={onBackClick} className="fa fa-arrow-left c-pointer" aria-hidden="true"/> &nbsp;{editMode ? 'Edit':'Create'} Basket</span>
            </BasketketHeader>


            <div className="h-100 d-flex flex-column justify-content-between align-items-stretch">
                <div className="p-5">
                    <div className="align-self-start flex-fill w-100 pt-2">

                        <Form className="w-100">
                            <Form.Group className="mb-2" controlId="formBasicEmail">
                                <Form.Label className="text1 w-100">
                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <div className="currentText">Basket Order Name</div>
                                        <div className="colorGrey">{formState.name_basket.length}/15</div>
                                    </div>
                                </Form.Label>
                                <Form.Control type="text"
                                              name="name_basket"
                                              required
                                              maxLength={15}
                                              isInvalid={ !!errors.name_basket }
                                              value={formState.name_basket}
                                              onChange={handleFormStateChange}
                                              placeholder="Basket Name" />
                                <Form.Control.Feedback type='invalid' className="d-flex flex-row">
                                    { errors.name_basket }
                                </Form.Control.Feedback>
                            </Form.Group>

                        </Form>


                    </div>
                </div>
                <div className="align-self-end ms-auto mb-5 w-25 mr-5">
                    <Button disabled={isLoading} className="blue_btn my-3" onClick={handleOnClick} variant="primary" type="submit">
                        {isLoading && (
                            <Spinner className="mr-2" as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                        )}
                        {editMode ? 'Edit':'Create'}
                    </Button>
                </div>
            </div>


        </React.Fragment>
    );
};

export default CreateBasket;

