import React from 'react';
import Background from "../BackGround/background";
import {Link, NavigateOptions, useNavigate} from "react-router-dom";
import './forgetUserId.css'
import MadeInLoveIndia from "../common/madeInLoveIndia";
import mobileImage from '../../assets/images/mobile.svg'
import sendImage from '../../assets/images/send-2.svg'
import pancardImage from '../../assets/images/personalcard.svg'

function ForgetUserId(props) {

    const navigate = useNavigate();



    function MoveToRecoveryPage(params){
        navigate("/UserIdRecovery",{state:{type:params}})
    }


    return (
        <Background>
            <div className="h-100 p-lg-5 p-5 d-flex flex-column justify-content-between " >

                <div className="">

                    <div className="py-2">
                        <Link to="/login"><i className="fas fa-arrow-left fas fa-lg"/></Link>
                    </div>

                    <h3 className="heading1 py-1">Forgot User ID</h3>

                    <div className="colorGrey pt-0">Use any of the 3 options registered with us & recover your User ID.</div>



                </div>


                <div className="align-self-center flex-fill w-100">

                    <div className="pt-4 w-100">
                        <div onClick={() => MoveToRecoveryPage('phone')} className="cardBox c-pointer">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <div className=""><img src={mobileImage} alt={''}/> Phone number</div>
                                <div className=""><i className="fas fa-angle-right icon_grey"/></div>
                            </div>
                        </div>

                        <div onClick={() => MoveToRecoveryPage('email')} className="cardBox c-pointer">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <div className=""><img src={sendImage} alt={''}/> Email address</div>
                                <div className=""><i className="fas fa-angle-right icon_grey"/></div>
                            </div>
                        </div>


                        <div onClick={() => MoveToRecoveryPage('pan')} className="cardBox c-pointer">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <div className=""><img src={pancardImage} alt={''}/> PAN Card</div>
                                <div className=""><i className="fas fa-angle-right icon_grey"/></div>
                            </div>
                        </div>

                    </div>

                </div>


                <div className="">

                    <MadeInLoveIndia/>
                </div>



            </div>
        </Background>
    );
}

export default ForgetUserId;
