import React, {useEffect, useState} from "react";
import {DataFeedInstance} from "../../chart-datafeed/udf-compatible-datafeed";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {numDifferentiation} from "../../comman/status";
import nextId from "react-id-generator";
import {NumberColor} from "../common/number_color";
import {SpotService, peService, BuySellServices, layoutSubServices} from "../../sub";
import BoxTooltip from "./Tooltips/boxtooltips";


function PEGroup({data,price}) {

    const [ yellowShade, setYellowShade ] = useState(false)
    const [ subData, setSubData ] = useState(null)
    const [ barData, setBarData ] = useState(0);
    const [ max, setMax ] = useState(0);
    const [ buySellSymbol, setBuySellSymbol ] = useState(null);


    const id1 = nextId("PEIn-");

    function calPercentage(minvolume, maxVolume) {
        const width =  (minvolume/maxVolume) * 100
        // console.log("www",width);
        setBarData(width)
    }

    useEffect(() => {
      if(subData) calPercentage(Number(subData.oi),max)
    }, [max])

    useEffect(() => {
        if (subData) {
            // console.log(subData.oi, maxVolumePE)
            peService.sendMax(Number(subData.oi))
            // setVolumePE(Number(subData.oi))
            // calPercentage(Number(subData.oi),maxVolumePE)
        }
    }, [subData])


    useEffect(() => {
        peService.resetVolumePE();
        callSub()
        return () => {
            removeSub()
        }
    }, [data])

    let listenerGuid = undefined
    function callSub() {
        listenerGuid = `pe-${data.token}-${data.marketSegmentId}`
        DataFeedInstance.subcscribeQuotesFromTokens([{market_segment_id: data.marketSegmentId , token: data.token }],(quotes)=>{
              // console.log("getQuotes data PEGroup",quotes);
            setBuySellSymbol(quotes[0].n)
            setSubData(quotes[0].v)
        },listenerGuid)
    }

    let subscription = undefined;
    let subscriptionPE = undefined;
    useEffect(() => {
        subscription = SpotService.getSpot().subscribe(value => {
            const val = value.lp <= Number(price)
            setYellowShade(val)
        });

        subscriptionPE = peService.getSub().subscribe(value => {
            setMax(value)
        });

        return () => {
            subscription?.unsubscribe();
            subscriptionPE?.unsubscribe();
        }
    }, [])


    function removeSub(){
        DataFeedInstance.unsubscribeQuotes(listenerGuid)
    }


    const OpenByWindow = () => {
        let data = { ...subData ,buySellSymbol,"type":"B","option_type:":"PE"};
        BuySellServices.setBuySell(data)
    }

    const OpenSellWindow = () => {
        let data = { ...subData ,buySellSymbol,"type":"S","option_type:":"PE"};
        BuySellServices.setBuySell(data)
    }

    const OpenChartWindow = () => {
        let data = { ...subData ,buySellSymbol,"type":"","option_type:":""};
        layoutSubServices.setLayOut(data)
    }



    if(subData == null){
        return (
            <React.Fragment key={id1}>
                <td colSpan="3" className="">
                    <div>
                        <Row>
                            <Col className="">
                                <div className="p-0">-</div>
                                <div className="p-0">&nbsp;</div>
                            </Col>
                            <Col className="">
                                <div className="p-0">-</div>
                                <div className="p-0">&nbsp;</div>
                            </Col>
                            <Col className="">
                                <div className="p-0">-</div>
                                <div className="p-0">&nbsp;</div>
                            </Col>
                        </Row>
                    </div>
                </td>

            </React.Fragment>
        );
    }



    return(

        <React.Fragment key={id1}>

            <td colSpan="3" className={`position-relative buy_sell_menu_parent ${yellowShade ? 'shade_color':''}`}>
                <div className="bar_right bar_bg_green" style={{width: `${barData}%`}}/>
                <div>
                    <Row>
                        <Col className="">
                            <div className="p-0">{subData.lp}</div>
                            <div className="p-0"> <NumberColor number={subData.chp} numFix={true} /></div>
                        </Col>
                        <Col className="">
                            <div className="p-0"> { numDifferentiation(subData.oi)}</div>
                            <div className="p-0"> <NumberColor number={subData.oi_ch}  /> </div>
                        </Col>
                        <Col className="">
                            <div className="p-0">{numDifferentiation(subData.volume) }</div>
                            <div className="p-0">&nbsp;</div>
                        </Col>
                    </Row>

                    <span className="buy_sell_menu">
                          <BoxTooltip position={'tooltips_right'} buy={OpenByWindow} sell={OpenSellWindow} chart={OpenChartWindow}/>
                    </span>
                </div>
            </td>

        </React.Fragment>

    )

}

export default PEGroup;
