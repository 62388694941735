import { createSlice } from "@reduxjs/toolkit";

const optionChainSlice = createSlice({
    name: "optionChain",
    initialState: {
        model_open: false,
        new_model_open: false,
        open_future_chain:false,
        socket_open: false,
        future_key:null,
        option_key: null,
        sport_data: null,
        maxVolumePE: 0,
        maxVolumeCE: 0,

    },
    reducers: {
        OpenOptionModel: (state, action) => {
            state.model_open = action.payload.model_open;
            state.option_key = action.payload.option_key;
        },
        OpenNewOptionModel: (state, action) => {
            state.new_model_open = action.payload.new_model_open;
            state.option_key = action.payload.option_key;
            state.open_future_chain=false;
            state.future_key=null;
        },
        OpenFutureChain: (state,action)=>{
            state.open_future_chain=action.payload.open_future_chain;
            state.future_key=action.payload.future_key;
            state.model_open = false;
            state.new_model_open = false;
            state.option_key = null;
            state.sport_data = null;
            state.maxVolumePE = 0;
            state.maxVolumeCE = 0;
        },
        CloseFutureChain: (state)=>{
            state.open_future_chain=false;
            state.future_key=null;
        },
        CloseOptionModel: (state) => {
            state.model_open = false;
            state.new_model_open = false;
            state.option_key = null;
            state.sport_data = null;
            state.maxVolumePE = 0;
            state.maxVolumeCE = 0;
        },
        setSportData: (state,action) => {
            state.sport_data = action.payload;
        },
        onSocketChange: (state,action) => {
            state.socket_open = action.payload;
        },
        openOptionChain(state){
            window.GtmPusher({
                event: 'tv-option-chain-opened',
            })
        },
        openFutureChain(state){
            window.GtmPusher({
                event: 'tv-future-chain-opened',
            })
        },
        openHighLowChain(state){
            window.GtmPusher({
                event: 'tv-high-low-opened',
            })
        },
        openMarketDepthChain(state){
            window.GtmPusher({
                event: 'tv-market-depth-opened',
            })
        },
        
    },
});

export const MaxVolume = (state) => state.optionChain;
export const { OpenOptionModel,OpenNewOptionModel,OpenFutureChain,CloseFutureChain, CloseOptionModel ,setSportData,onSocketChange,openOptionChain,openFutureChain,openHighLowChain,openMarketDepthChain} = optionChainSlice.actions;
export default optionChainSlice.reducer;
