import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import Modal from 'react-modal';
import './addTobasket.css'
import BasketData from "./basketData";
import {useDispatch} from "react-redux";
import {setCurrentModification, setListDataMain} from "../../../slices/basketOrderSlice";

Modal.setAppElement('#model_basket');
const AddToBasket = forwardRef(({stockData,modification}, ref) => {

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const dispatch = useDispatch();

    const [darkTheme, setDarkTheme] = React.useState('');

    function openModal() {
        setIsOpen(true);
    }

    useEffect(() => {
      let d =  document.getElementById('themeManage').getAttribute('class')
        setDarkTheme(d)
    }, [])

    function closeModal() {
        dispatch(setCurrentModification(null,null))
        setIsOpen(false);
    }

    useImperativeHandle(ref, () => ({

        getAlert() {
            openModal()
        }

    }));

    return (
        <Modal
            isOpen={modalIsOpen}
            className={`custom-order-dialog_add_to_basket ${darkTheme} currentBg2`}
            overlayClassName="OverlayBasket"
            contentLabel="Basket BUY SELL Modal">
            <div className="my_box2 currentBg2">
                <BasketData close={closeModal} stockData={stockData} modification={modification}/>
            </div>
        </Modal>
    );
});

export default AddToBasket;
