import { getErrorMessage, logMessage, } from '../utils/helpers';
export class HistoryProvider {
    constructor(datafeedUrl, requester, headers) {
        this._datafeedUrl = datafeedUrl;
        this._requester = requester;
        this._headers = headers
    }

    //This function returns the historical data of candles 
    getBars(symbolInfo, resolution, periodParams) {
        const requestParams = {
            symbol: symbolInfo.symbol,
            token: symbolInfo.odin_token,
            market_segment_id: symbolInfo.odin_market_segment_id,
            resolution: resolution,
            from: periodParams.from,
            to: periodParams.to,
            first_data_request: periodParams.firstDataRequest,
            next_time: true,
        };
        if (periodParams.countBack) {
            requestParams.countback = periodParams.countBack;
        }
        if (symbolInfo.currency_code) {
            requestParams.currencyCode = symbolInfo.currency_code;
        }
        if (symbolInfo.unit_id) {
            requestParams.unitId = symbolInfo.unit_id;
        }
        if (requestParams.token == undefined || requestParams.token == 0 || requestParams.token == null || requestParams.token == "" || requestParams.token == "0") {
            return new Promise((resolve, reject) => {
                const meta = {
                    noData :true 
                }
                resolve({
                    bars: [],
                    meta: meta,
                });
            });
        }
        return new Promise((resolve, reject) => {
            this._requester.sendRequest(this._datafeedUrl, 'data/history', requestParams)
                .then((response) => {
                if (response.s !== 'ok' && response.s !== 'no_data') {
                    reject(response.errmsg);
                    return;
                }
                const bars = [];
                const meta = {
                    noData: false,
                };
                if (response.s === 'no_data') {
                    meta.noData = true;
                    meta.nextTime = response.next_time;
                }else if(response.s === 'error'){
                    
                    meta.noData = true;
                }
                else {
                    const volumePresent = response.v !== undefined;
                    const ohlPresent = response.o !== undefined;
                    var totalLastDayVolumeTillBar = 0
                    for (let i = 0; i < response.t.length; ++i) {
                        const barValue = {
                            time: response.t[i] * 1000,
                            close: parseFloat(response.c[i]),
                            open: parseFloat(response.c[i]),
                            high: parseFloat(response.c[i]),
                            low: parseFloat(response.c[i]),
                        };
                        if (ohlPresent) {
                            barValue.open = parseFloat(response.o[i]);
                            barValue.high = parseFloat(response.h[i]);
                            barValue.low = parseFloat(response.l[i]);
                        }
                        if (volumePresent) {
                            barValue.volume = parseFloat(response.v[i]);
                            if( barValue.time >= bod()){
                                totalLastDayVolumeTillBar = totalLastDayVolumeTillBar  + barValue.volume
                            }
                            
                        }
                        if( resolution != "1D" && resolution != "1W" && resolution != "1M" &&resolution != "1Y"){
                            barValue.totalLastDayVolumeTillBar = totalLastDayVolumeTillBar
                        }
                        bars.push(barValue);
                    }
                }
                resolve({
                    bars: bars,
                    meta: meta,
                });
            }).catch((reason) => {
                // tslint:disable-next-line:no-console
                logMessage(`HistoryProvider: getBars() failed, error=${getErrorMessage(reason)}`);
                reject(reason);
            });
        });
    }
}

function bod(){ //It returns the date and time of current day
    var now = new Date();
    var startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    var timestamp = startOfDay;
    return timestamp
}
