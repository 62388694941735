import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {DataFeedInstance} from "../../chart-datafeed/udf-compatible-datafeed";
import {CloseOptionModel} from "../../slices/optionChainSlice";
import {SpotService} from "../../sub";
import {NumberColor} from "../common/number_color";
import {ReactComponent as CloserIcon} from "../../assets/images/close-square.svg";




function TopSymbolSub({data}) {

    const [ subData, setSubData ] = useState(null);
    const dispatch = useDispatch();
    // const { sport_data } = useSelector((state) => state.optionChain);


    useEffect(() => {
        // if(subData) dispatch(setSportData(subData))
        if(subData) sendMessage();
    }, [subData])


    function sendMessage() {
        // send message to subscribers via observable subject
        SpotService.sendSpot(subData);
    }


    useEffect(() => {
        headerData()
        return () => {
            removeSub()
        }
    }, [data])

    let listenerGuid = undefined
    function headerData() {
        listenerGuid = `top-${data.token}-${data.marketSegmentId}`
        DataFeedInstance.subcscribeQuotesFromTokens([
            {market_segment_id: data.marketSegmentId , token: data.token }],(quotes)=>{
            // console.log("getQuotes data TopSymbolSub",quotes);
            setSubData(quotes[0].v)
        },listenerGuid)
    }

    function removeSub(){
        DataFeedInstance.unsubscribeQuotes(listenerGuid)
    }

    const onModelClose = () => {
        dispatch(CloseOptionModel())
    }

    if(subData == null){

        return(

            <div className="custom-order-dialog__header_option" id="custom-order-dialog__header-id" data-dragg-area="true">
                <div className="custom-order-dialog__stock-info" id="custom-order-dialog__quote-stock-info-id">
                    <div className="custom-order-dialog__stock-description" id="custom-order-dialog__stock-name-description">
                    </div>
                    <div className="custom-order-dialog__quote" id="custom-order-dialog__quote-id">

                    </div>
                </div>
                <div className="custom-order-dialog__actions">
                    <div className="custom-order-dialog__dialog-actions" id="custom-order-dialog__quote-dialog-actions-id">
                        <div onClick={onModelClose} className="c-pointer" id=""><CloserIcon/></div>
                        {/*<button onClick={onModelClose} className="button custom-order-dialog__close_button" id="custom-order-dialog__quote-close_button-id"/>*/}
                    </div>
                </div>
            </div>

        );

    }


    return(

        <div className="custom-order-dialog__header_option" id="custom-order-dialog__header-id" data-dragg-area="true">
            <div className="custom-order-dialog__stock-info" id="custom-order-dialog__quote-stock-info-id">
                <div className="custom-order-dialog__stock-description" id="custom-order-dialog__stock-name-description">
                    <div className="custom-order-dialog__stock-name" id="custom-order-dialog__stock-name-id">{subData.short_name} </div>
                    <div className="custom-order-dialog__exchange" id="custom-order-dialog__exchange-id">{subData.exchange}</div>
                </div>
                <div className="custom-order-dialog__quote" id="custom-order-dialog__quote-id">
                    <div className="custom-order-dialog__quote-ltp" id="custom-order-dialog__quote-ltp-id">{subData.lp} </div>
                    <div className="custom-order-dialog__quote-chng" id="custom-order-dialog__quote-chng-id"> <NumberColor number={subData.ch}  /> </div>
                    <div className="custom-order-dialog__quote-chngp" id="custom-order-dialog__quote-chngp-id">
                        <NumberColor number={subData.chp} numFix={true} percentageAdd={true} isFiniteCheck={true} />
                    </div>
                </div>
            </div>
            <div className="custom-order-dialog__actions">
                <div className="custom-order-dialog__dialog-actions" id="custom-order-dialog__quote-dialog-actions-id">
                    <div onClick={onModelClose} className="c-pointer" id=""><CloserIcon/></div>
                </div>
            </div>
        </div>

    );

}

export default TopSymbolSub;
