import { logMessage } from '../utils/helpers';
export class InHouseRequester {
    constructor(headers,logout_function) {
        if (headers) {
            this._headers = headers;
        }
        this.logout_function = logout_function
    }
    async sendRequest(url, urlPath, body,params, method="GET") {
        logMessage('New request: ' + urlPath);
        // Send user cookies if the URL is on the same origin as the calling script.
        const options = {};
        if (this._headers) {
            options.headers = this._headers;
        }
        if (body && method != "GET"){
            options.body = JSON.stringify(body)
        }
        url = new URL(`${url}${urlPath}`)
        url.search = new URLSearchParams(params).toString();
        options.method = method
        
        return fetch(url, options)
            .then((response)=>{
                if (response.status == 401){
                    this.logout_function()
                    return ""
                }
                return response.text()
            })
            .then((responseTest) => JSON.parse(responseTest))
            .catch((error)=> console.log(error))
            ;
    }

    async getQuotes(url,string){
        var requestOptions = {
            method: 'GET',
            headers: this._headers
          };
          return fetch(url+"analytics/quote?"+string, requestOptions)
          .then((response)=>{
            return response.text()
        })
        .then((responseTest) => JSON.parse(responseTest))
        .catch((error)=> console.log(error))
        ;
    }
}
