import React, {useEffect} from 'react';
import './optionStrategyModel.scss'
import Draggable from 'react-draggable';
import {useDispatch, useSelector} from "react-redux";
import OptionStrategyHeader from "./OptionStrategyHeader";
import {ReactComponent as StoreImage} from "../../assets/icons/StrategyStore.svg";
import {ReactComponent as ToolsFooter} from "../../assets/icons/toolsFooter.svg";
import {ReactComponent as BuilderImage} from "../../assets/icons/StrategyBuilder.svg";
import {setStrategyState,openOptionStrategyStore,openOptionStrategyBuilder} from "../../slices/optionStrategySlice"

function StrategyTools(props){

    const dispatch = useDispatch();

    const showstore =()=>{
        let state = {id:2,url:"store"}
        dispatch(setStrategyState(state))
        dispatch(openOptionStrategyStore())
    }
    const showbuilder =()=>{
        let state = {id:2,url:"builder"}
        dispatch(setStrategyState(state))
        dispatch(openOptionStrategyBuilder())
    }



    return(
        <React.Fragment>
            <OptionStrategyHeader>
                <div className='flex headerText'>Tools</div>
            </OptionStrategyHeader>
            <div className='container'>
            <div className='row'>
                    <div className='col-12' style={{"paddingLeft":"20px","paddingTop":"20px","paddingRight":"20px","paddingBottom":"20px"}}>
                        <div className='tools-back c-pointer' onClick={() => showbuilder()}>
                        <div className='relative'>
                            <div className='image'><BuilderImage/></div>
                            <div className='textHead headerText'>Strategy Builder</div>
                            <div className='description'>Predict the stock movement and get options strategies to trade</div>
                            <div className='arr'><i className="fa-solid fa-chevron-right"></i></div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12' style={{"paddingLeft":"20px","paddingRight":"20px","paddingBottom":"20px"}}>
                        <div className='tools-back c-pointer' onClick={() => showstore()}>
                        <div className='relative'>
                            <div className='image'><StoreImage/></div>
                            <div className='textHead headerText'>Strategy Store</div>
                            <div className='description'>Pre-defined strategies to help you make your decision easier</div>
                            <div className='arr'><i className="fa-solid fa-chevron-right"></i></div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                <div className='col-12' style={{"paddingLeft":"20px","marginTop":"55px","paddingRight":"20px","paddingBottom":"20px"}}>
                    <div className='foot'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-8' style={{"textAlign":"left","paddingTop":"20px"}}>
                                    <div className='headerText'>What is option strategy ?</div>
                                    <div className='foot-desc'>Option strategies are the simultaneous, and often mixed, buying or selling of one or more options that differ in one or more of the options' variables.</div>
                                    <div className='knowLink'><a href='https://rupeezy.in/blogs/what-are-options-trading-strategies/' target={"_blank"}>Know More</a></div>
                                </div>
                                <div className='col-4' style={{"paddingLeft":"10%"}}><ToolsFooter/></div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default StrategyTools;