import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {ResponceStatusService} from "../../sub";
import authService from "../../services/authService";
import {useNavigate} from "react-router-dom";

const ResponseHandle = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        dispatch(authService.logout())
        navigate("/",{ replace: true});
    }

    let subscription = undefined
    useEffect(() => {
        subscription = ResponceStatusService.getSub().subscribe(value => {
          if(value?.type === 'FLOW' && value?.status === 401) handleLogout()
        });
        return () => {
            subscription?.unsubscribe();
        };
    }, []);

    return (
        <div>

        </div>
    );
};

export default ResponseHandle;

