import React, { useEffect,useState } from "react";
import {DataFeedInstance} from "../../chart-datafeed/udf-compatible-datafeed";


const HighLow = ({data,odinToken,mktId})=> {

    const [subData,setSubData]=useState(null)

    const [ltpChangePercent,setLtpChangePercent] = useState(0)
    const [ltpChangeClass,setLtpChangeClass]=useState(null)
    const [ltpChange,setLtpChange] = useState(null)
    const [ltpClass,setLtpClass]=useState(null)
    const [fiveDayHigh,setFiveDayHigh]=useState(0)
    const [fiveDayLow,setFiveDayLow]=useState(0)
    const [oneMonthHigh,setOneMonthHigh]=useState(0)
    const [oneMonthLow,setOneMonthLow]=useState(0)
    const [threeMonthHigh,setThreeMonthHigh]=useState(0)
    const [threeMonthLow,setThreeMonthLow]=useState(0)
    const [sixMonthHigh,setSixMonthHigh]=useState(0)
    const [sixMonthLow,setSixMonthLow]=useState(0)
    const [oneYearHigh,setOneYearHigh]=useState(0)
    const [oneYearLow,setOneYearLow]=useState(0)



    useEffect(()=>{
        if(subData) checkLtpChangePercentage()
    },[subData])

    useEffect(()=>{
        if(subData) checkLtpChange()
    },[subData])

    useEffect(()=>{
        if(subData) setHighLow()
    },[subData])


    function setHighLow(){
        //Calculate Five day high and low
        if(subData.low_price<data.day_5.low && subData.low_price!=0){
            setFiveDayLow(subData.low_price.toFixed(2))
        }else{
            setFiveDayLow(data.day_5.low.toFixed(2))
        }
        if(subData.high_price>data.day_5.high){
            setFiveDayHigh(subData.high_price.toFixed(2))
        }else{
            setFiveDayHigh(data.day_5.high.toFixed(2))
        }

        //Calculate one month high and low
        if(subData.low_price<data.month_1.low && subData.low_price!=0){
            setOneMonthLow(subData.low_price.toFixed(2))
        }else{
            setOneMonthLow(data.month_1.low.toFixed(2))
        }
        if(subData.high_price>data.month_1.high){
            setOneMonthHigh(subData.high_price.toFixed(2))
        }else{
            setOneMonthHigh(data.month_1.high.toFixed(2))
        }

        //Calculate three month high and low
        if(subData.low_price<data.month_3.low && subData.low_price!=0){
            setThreeMonthLow(subData.low_price.toFixed(2))
        }else{
            setThreeMonthLow(data.month_3.low.toFixed(2))
        }
        if(subData.high_price>data.month_3.high){
            setThreeMonthHigh(subData.high_price.toFixed(2))
        }else{
            setThreeMonthHigh(data.month_3.high.toFixed(2))
        }

        //Calculate six month high and low
        if(subData.low_price<data.month_6.low && subData.low_price!=0){
            setSixMonthLow(subData.low_price.toFixed(2))
        }else{
            setSixMonthLow(data.month_6.low.toFixed(2))
        }
        if(subData.high_price>data.month_6.high){
            setSixMonthHigh(subData.high_price.toFixed(2))
        }else{
            setSixMonthHigh(data.month_6.high.toFixed(2))
        }
        //Calculate one year high and low
        if(subData.low_price<data.year_1.low && subData.low_price!=0){
            setOneYearLow(subData.low_price.toFixed(2))
        }else{
            setOneYearLow(data.year_1.low.toFixed(2))
        }
        if(subData.high_price>data.year_1.high){
            setOneYearHigh(subData.high_price.toFixed(2))
        }else{
            setOneYearHigh(data.year_1.high.toFixed(2))
        }
    }

    function checkLtpChangePercentage(){
        let sign = Math.sign(subData.chp)
        if(sign===1){
            setLtpChangePercent("(+"+subData.chp.toFixed(2)+"%)")
            setLtpChangeClass("greenText1")
        }else if(sign===-1){
            setLtpChangePercent("("+subData.chp.toFixed(2)+"%)")
            setLtpChangeClass("redText1")
        }else if(sign===0){
            setLtpChangePercent("(0%)")
            setLtpChangeClass("greenText1")
        }else if(sign===-0){
            setLtpChangePercent("(0%)")
            setLtpChangeClass("redText1")
        }else{
            setLtpChangePercent("(0%)")
            setLtpChangeClass("greenText1")
        }
    }
    function checkLtpChange(){
        let sign = Math.sign(subData.ch)
        if(sign===1){
            setLtpChange("+"+subData.ch.toFixed(2))
            setLtpClass("greenText1")
        }else if(sign===-1){
            setLtpChange(subData.ch.toFixed(2))
            setLtpClass("redText1")
        }else if(sign===0){
            setLtpChange("(0%)")
            setLtpClass("greenText1")
        }else if(sign===-0){
            setLtpChange("(0%)")
            setLtpClass("redText1")
        }else{
            setLtpChange("(0%)")
            setLtpClass("greenText1")
        }
    }

    useEffect(()=>{
        if(odinToken && mktId) Sub()

        return () => {
            if(listenerGuid) removeSub()
        }
    },[data])

    let listenerGuid = undefined

    function Sub() {
        listenerGuid = `top-${odinToken}-${mktId}`
        DataFeedInstance.subcscribeQuotesFromTokens([{market_segment_id: mktId , token: odinToken }],(quotes)=>{
            setSubData(quotes[0].v)
        },listenerGuid)
    }

    function removeSub(){
        DataFeedInstance.unsubscribeQuotes(listenerGuid)
    }

  return (
    <React.Fragment>
        <div className="container headerBackground pb-4 px-4 mb-4" style={{"marginLeft":"2.5%","marginRight":"2.5%"}}>
            <div className="row" style={{"position":"relative"}}>
                <div className="col-2 p-3 tablehlData" style={{"justifyContent":"left","paddingLeft":"5%"}}>1D</div>
                <div className="col-6 p-3 tablehlData" style={{"position":"relative"}}>
                    <div style={{"position":"absolute","left":"0px"}}>{subData?subData.low_price.toFixed(2):'0'}</div>
                    <div style={{"position":"absolute","right":"-49px"}}>{subData?subData.high_price.toFixed(2):'0'}</div>
                    <div className="sliderLine3">
                        <div className="slider">
                            <div className="roller" style={{"left":`${subData?((subData.lp-subData.low_price)/(subData.high_price-subData.low_price))*100:0}%`}}></div>
                        </div>
                    </div>
                </div>
                <div className="col-4 p-3 tablehlData" style={{"justifyContent":"right","paddingRight":"5%"}}><span className={`${ltpClass}`}>{subData?ltpChange:"0"}</span><span className={`${ltpChangeClass}`}>{subData?ltpChangePercent:"(0%)"}</span></div>
                <div className="sliderLine borderabc"></div>
            </div>
            <div className="row" style={{"position":"relative"}}>
                <div className="col-2 p-3 tablehlData" style={{"justifyContent":"left","paddingLeft":"5%"}}>5D</div>
                <div className="col-6 p-3 tablehlData" style={{"position":"relative"}}>
                    <div style={{"position":"absolute","left":"0px"}}>{fiveDayLow}</div>
                    <div style={{"position":"absolute","right":"-49px"}}>{fiveDayHigh}</div>
                    <div className="sliderLine3">
                        <div className="slider">
                            <div className="roller" style={{"left":`${subData?((subData.lp-fiveDayLow)/(fiveDayHigh-fiveDayLow))*100:0}%`}}></div>
                        </div>
                    </div>
                </div>
                <div className="col-4 p-3 tablehlData" style={{"justifyContent":"right","paddingRight":"5%"}}>
                    <span className={`${subData?subData.lp-data.day_5.open<0?'redText1':'greenText1':''} lightFont`}>{subData?(subData.lp-data.day_5.open).toFixed(2)>0?"+"+(subData.lp-data.day_5.open).toFixed(2):(subData.lp-data.day_5.open).toFixed(2):'0'}</span>
                    <span className={`${subData?subData.lp-data.day_5.open<0?'redText1':'greenText1':''} lightFont`}>{subData?(((subData.lp-data.day_5.open)/data.day_5.open)*100).toFixed(2)>0?"(+"+(((subData.lp-data.day_5.open)/data.day_5.open)*100).toFixed(2)+"%)":"("+(((subData.lp-data.day_5.open)/data.day_5.open)*100).toFixed(2)+"%)":'(0%)'}</span></div>
                <div className="sliderLine borderabc"></div>
            </div>
            <div className="row" style={{"position":"relative"}}>
                <div className="col-2 p-3 tablehlData" style={{"justifyContent":"left","paddingLeft":"5%"}}>1M</div>
                <div className="col-6 p-3 tablehlData" style={{"position":"relative"}}>
                    <div style={{"position":"absolute","left":"0px"}}>{oneMonthLow}</div>
                    <div style={{"position":"absolute","right":"-49px"}}>{oneMonthHigh}</div>
                    <div className="sliderLine3">
                        <div className="slider">
                            <div className="roller" style={{"left":`${subData?((subData.lp-oneMonthLow)/(oneMonthHigh-oneMonthLow))*100:0}%`}}></div>
                        </div>
                    </div>
                </div>
                <div className="col-4 p-3 tablehlData" style={{"justifyContent":"right","paddingRight":"5%"}}>
                    <span className={`${subData?subData.lp-data.month_1.open<0?'redText1':'greenText1':''}`}>{subData?(subData.lp-data.month_1.open).toFixed(2)>0?"+"+(subData.lp-data.month_1.open).toFixed(2):(subData.lp-data.month_1.open).toFixed(2):'0'}</span>
                    <span className={`${subData?subData.lp-data.month_1.open<0?'redText1':'greenText1':''}`}>{subData?(((subData.lp-data.month_1.open)/data.month_1.open)*100).toFixed(2)>0?"(+"+(((subData.lp-data.month_1.open)/data.month_1.open)*100).toFixed(2)+"%)":"("+(((subData.lp-data.month_1.open)/data.month_1.open)*100).toFixed(2)+"%)":'(0%)'}</span></div>
                <div className="sliderLine borderabc"></div>
            </div>
            <div className="row" style={{"position":"relative"}}>
                <div className="col-2 p-3 tablehlData" style={{"justifyContent":"left","paddingLeft":"5%"}}>3M</div>
                <div className="col-6 p-3 tablehlData" style={{"position":"relative"}}>
                    <div style={{"position":"absolute","left":"0px"}}>{threeMonthLow}</div>
                    <div style={{"position":"absolute","right":"-49px"}}>{threeMonthHigh}</div>
                    <div className="sliderLine3">
                        <div className="slider">
                            <div className="roller" style={{"left":`${subData?((subData.lp-threeMonthLow)/(threeMonthHigh-threeMonthLow))*100:0}%`}}></div>
                        </div>
                    </div>
                </div>
                <div className="col-4 p-3 tablehlData" style={{"justifyContent":"right","paddingRight":"5%"}}>
                    <span className={`${subData?subData.lp-data.month_3.open<0?'redText1':'greenText1':''}`}>{subData?(subData.lp-data.month_3.open).toFixed(2)>0?"+"+(subData.lp-data.month_3.open).toFixed(2):(subData.lp-data.month_3.open).toFixed(2):'0'}</span>
                    <span className={`${subData?subData.lp-data.month_3.open<0?'redText1':'greenText1':''}`}>{subData?(((subData.lp-data.month_3.open)/data.month_3.open)*100).toFixed(2)>0?"(+"+(((subData.lp-data.month_3.open)/data.month_3.open)*100).toFixed(2)+"%)":"("+(((subData.lp-data.month_3.open)/data.month_3.open)*100).toFixed(2)+"%)":'(0%)'}</span></div>
                <div className="sliderLine borderabc"></div>
            </div>
            <div className="row" style={{"position":"relative"}}>
                <div className="col-2 p-3 tablehlData" style={{"justifyContent":"left","paddingLeft":"5%"}}>6M</div>
                <div className="col-6 p-3 tablehlData" style={{"position":"relative"}}>
                    <div style={{"position":"absolute","left":"0px"}}>{sixMonthLow}</div>
                    <div style={{"position":"absolute","right":"-49px"}}>{sixMonthHigh}</div>
                    <div className="sliderLine3">
                        <div className="slider">
                            <div className="roller" style={{"left":`${subData?((subData.lp-sixMonthLow)/(sixMonthHigh-sixMonthLow))*100:0}%`}}></div>
                        </div>
                    </div>
                </div>
                <div className="col-4 p-3 tablehlData" style={{"justifyContent":"right","paddingRight":"5%"}}>
                    <span className={`${subData?subData.lp-data.month_6.open<0?'redText1':'greenText1':''}`}>{subData?(subData.lp-data.month_6.open).toFixed(2)>0?"+"+(subData.lp-data.month_6.open).toFixed(2):(subData.lp-data.month_6.open).toFixed(2):'0'}</span>
                <span className={`${subData?subData.lp-data.month_6.open<0?'redText1':'greenText1':''}`}>{subData?(((subData.lp-data.month_6.open)/data.month_6.open)*100).toFixed(2)>0?"(+"+(((subData.lp-data.month_6.open)/data.month_6.open)*100).toFixed(2)+"%)":"("+(((subData.lp-data.month_6.open)/data.month_6.open)*100).toFixed(2)+"%)":'(0%)'}</span></div>
                <div className="sliderLine borderabc"></div>
            </div>
            <div className="row" style={{"position":"relative"}}>
                <div className="col-2 p-3 tablehlData" style={{"justifyContent":"left","paddingLeft":"5%"}}>1Y</div>
                <div className="col-6 p-3 tablehlData" style={{"position":"relative"}}>
                    <div style={{"position":"absolute","left":"0px"}}>{oneYearLow}</div>
                    <div style={{"position":"absolute","right":"-49px"}}>{oneYearHigh}</div>
                    <div className="sliderLine3">
                        <div className="slider">
                            <div className="roller" style={{"left":`${subData?((subData.lp-oneYearLow)/(oneYearHigh-oneYearLow))*100:0}%`}}></div>
                        </div>
                    </div>
                </div>
                <div className="col-4 p-3 tablehlData" style={{"justifyContent":"right","paddingRight":"5%"}}>
                    <span className={`${subData?subData.lp-data.year_1.open<0?'redText1':'greenText1':''}`}>{subData?(subData.lp-data.year_1.open).toFixed(2)>0?"+"+(subData.lp-data.year_1.open).toFixed(2):(subData.lp-data.year_1.open).toFixed(2):'0'}</span>
                    <span className={`${subData?subData.lp-data.year_1.open<0?'redText1':'greenText1':''}`}>{subData?(((subData.lp-data.year_1.open)/data.year_1.open)*100).toFixed(2)>0?"(+"+(((subData.lp-data.year_1.open)/data.year_1.open)*100).toFixed(2)+"%)":"("+(((subData.lp-data.year_1.open)/data.year_1.open)*100).toFixed(2)+"%)":'(0%)'}</span></div>
                <div className="sliderLine borderabc"></div>
            </div>

        </div>
        <div className="container footerBackground">
            <div className="lastTradedPrice">Last Traded Price: <span className="bold">₹{subData?subData.lp:'0'}</span></div>
        </div>
    </React.Fragment>
  );
}

export default HighLow;