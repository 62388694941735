import React, { useEffect,useState } from 'react';
import {useDispatch,useSelector} from "react-redux";
import OptionStrategyHeader from "../OptionStrategyHeader"
import Form from "react-bootstrap/Form";
import {ReactComponent as SearchIcon} from "../../../assets/icons/search-normal.svg";
import {setStrategyState,setCurrentStrategy,setRecentSearches,setCurrentSymbolDetails,setExpiryDates} from "../../../slices/optionStrategySlice";
import {DataFeedInstance} from "../../../chart-datafeed/udf-compatible-datafeed";
import ScripSearchData from "./ScripSearchData"

const StrategyStore=({}) =>{

    const dispatch = useDispatch();
    const [ sData, setSData ] = useState([])
    const [formState, setFormState] = useState({userInput: '', exchange: 'All Exchanges', symbolType: '6'});
    const [typingTimer, setTypingTimer] = useState(null)
    const [isLoadingSearch, setIsLoadingSearch] = useState(false);
    const [strategySymbol,setStrategySymbol]=useState(null)
    // const [hideData,setHideData]=useState(false)


    const {recent_searches,current_strategy_state} = useSelector((state) => state.optionStrategy);



    useEffect(() => {
        searchDataFunc(formState.userInput) 
    }, [formState.symbolType,formState.exchange])

    const clearInput = () => {
        setFormState({ ...formState, userInput: '' });
    }

    const onBackClick =()=>{
        let state = {id:1,url:'tools'}
        dispatch(setStrategyState(state))
    }

    const searchDataFunc = (value) => {
        if(value.length === 0){
            return
        }
        let symbolType = Number(formState.symbolType)
        setIsLoadingSearch(true)
        DataFeedInstance.searchSymbols(value,formState.exchange,symbolType,(res) => {
            const newArray = res.filter(item => {
                item._source.sExchange = item._source.ExchangeSegment
                return item._source.nFOExists !== 0
            });
            setSData(newArray)
            setIsLoadingSearch(false)
        })
    }

    //This func will set the name of selected stock in input box and it will push the stock in localstorage
    const searchNameChange = (value)=>{
        setFormState({ ...formState, userInput: value.sSymbol });
        setStrategySymbol(value)
        const recentdata = JSON.parse(localStorage.getItem('recentSearches'));
        let data = {
            "symbol":value.sSymbol,
            "exchange":value.ExchangeSegment,
            "sSecurityDesc":value.sSecurityDesc,
            "token":value.nToken,
            "nMarketSegmentId":value.nMarketSegmentId
        }
        if(!recentdata.some(obj => obj.symbol === data.symbol)) {
            recentdata.push(data);
          }
          if(recentdata.length>3){
            localStorage.setItem('recentSearches', JSON.stringify(recentdata.slice(-3)));
          }else{
            localStorage.setItem('recentSearches', JSON.stringify(recentdata));
          }
        dispatch(setRecentSearches(recentdata))
        dispatch(setCurrentSymbolDetails(value))
        if(current_strategy_state.url=="store"){
            let state = {id:3,url:'allStrategies'}
            dispatch(setStrategyState(state))
        }else{
            let state = {id:3,url:'allBuilder'}
            dispatch(setStrategyState(state))
        }

    }

    const formStateChange = (key,value) => {
        setFormState({ ...formState, [key]: value });
    }

    function handleFormStateChange(event)  {
        const { name, value } = event.target;
        setFormState({ ...formState, [name]: value });

        clearTimeout(typingTimer);
        if (value.length > 0) {
            const timerOut = setTimeout(() =>{
                searchDataFunc(value)
            }, 500);
            setTypingTimer(timerOut)
        }
    }

    const topSymbols = [
        {"symbol":"NIFTY","instrument":"EQ","sSecurityDesc":"NIFTY Index","exchange":"NSE_EQ","token":26000,"nMarketSegmentId":1},
        {"symbol":"BANKNIFTY","instrument":"EQ","sSecurityDesc":"BANKNIFTY Index","exchange":"NSE_EQ","token":26009,"nMarketSegmentId":1},
        {"symbol":"FINNIFTY","instrument":"EQ","sSecurityDesc":"FINNIFTY Index","exchange":"NSE_EQ","token":26037,"nMarketSegmentId":1},
        {"symbol":"RELIANCE","instrument":"EQ","sSecurityDesc":"RELIANCE INDUSTRIES LTD","exchange":"NSE_EQ","token":2885,"nMarketSegmentId":1}
    ]

    const handleClick = (event)=>{
        var jsonObject = JSON.parse(event.currentTarget.id)
        setFormState({ ...formState, userInput: jsonObject.symbol });
        let data ={sSymbol:jsonObject.symbol,sExchange:jsonObject.exchange,nToken:jsonObject.token,sSecurityDesc:jsonObject.sSecurityDesc,nMarketSegmentId:jsonObject.nMarketSegmentId}
        setStrategySymbol(data)
        dispatch(setCurrentSymbolDetails(data))
        if(current_strategy_state.url=="store"){
            let state = {id:3,url:'allStrategies'}
            dispatch(setStrategyState(state))
        }else{
            let state = {id:3,url:'allBuilder'}
            dispatch(setStrategyState(state))
        }
        }

    function recentStocks(){
        return recent_searches.slice(-3).reverse().map(obj => {
            return(
                <React.Fragment>
                    <div onClick={handleClick} id={JSON.stringify(obj)} key={obj.token} className='col-4 recentSearches datarow' style={{"textAlign":"left","paddingLeft":"38px","paddingTop":"10px"}}>
                        {obj.symbol} &nbsp; <span className='exc'>{decoratedExchange(obj.exchange)}</span> <br></br>
                        <span className='desc'>{obj.sSecurityDesc!=""?obj.sSecurityDesc:obj.symbol+" Index"}</span>
                    </div>
                </React.Fragment>
            )
          });
          
    }

    function getRecentSearchesData(){
        if(recent_searches.length>0){
            return(
                <React.Fragment>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12' style={{"textAlign":"left"}}>
                                <div className='headerText' style={{"paddingLeft":"26px","paddingTop":"10px"}}>Recent Searches</div>
                            </div>
                        </div>
                        <div className='row'>
                            {recentStocks()}
                        </div>
                    </div>
                    <div className='boundary'></div>
                </React.Fragment>
            )
        }else{
            return <div></div>
        }
    }



    //this will return the formatted data of top symbols
    function returnSymbols() {
        return topSymbols.map(d => {
            return (
                <div key={d.token} className='col-6' style={{"paddingLeft":"30px","marginBottom":"10px"}}>
                    <div id={JSON.stringify(d)} className="d-flex flex-row my-2 datarow w-100" onClick={handleClick}>
                        <div className="tv-circle-logo">
                        <img 
                            className="tv-circle-logo" 
                            src={`https://static.rupeezy.in/stock_images/svgs/${d.symbol}.svg`} 
                            onError={(e) => { 
                                e.target.onerror = null; // Prevent infinite loop
                                if(d.exchange.includes("NSE")) {
                                    e.target.src = "https://static.rupeezy.in/stock_images/svgs/NSE.svg";
                                }else if(d.exchange.includes("BSE")) {
                                    e.target.src = "https://static.rupeezy.in/stock_images/svgs/BSE.svg";
                                } else {
                                    e.target.src = "https://static.rupeezy.in/stock_images/svgs/MCX.svg";
                                }
                            }} 
                            alt="" 
                        />
                        </div>
                        <div className="pl-3 mt-1 d-flex flex-column justify-content-start align-items-start">
                            <div className="d-flex flex-row justify-content-center">
                                <div className="recentSearches">{d.symbol}</div>
                                <div className="badge_bg" id="badge_exch">{decoratedExchange(d.exchange)}</div>
                            </div>
                            <div className="text_low desc">{d.sSecurityDesc}</div>
                        </div>
                    </div>
                </div>
            )
        })
    }
    function decoratedExchange(exchange){
        return exchange
    }

    function getTopSymbols(){
        return(
            <div className='container'>
                <div className='row my-4'>
                    <div className='col-12 headerText' style={{"textAlign":"left","paddingLeft":"35px"}}>Top Symbols</div>
                </div>
                <div className='row my-2'>
                    {returnSymbols()}
                </div>
            </div>
        )
    }

return(
    <React.Fragment>
        <OptionStrategyHeader>
            <div className='flex headerText'> <i onClick={onBackClick} className="fa fa-arrow-left c-pointer" aria-hidden="true"/>&nbsp;<span className=' px-2'>{current_strategy_state.url=="store"?'Strategy Store':"Strategy Builder"}</span></div>
        </OptionStrategyHeader>

        <div className='container'>
            <div className='row'>
                <div className='col-12'>
                <Form  className="w-100 pt-3 px-5 ">

                    <Form.Group className="position-relative" controlId="formBasicEmail">

                        {formState.userInput.length === 0 && (
                            <div className="mysearch c-pointer"><SearchIcon/></div>
                        )}
                        {formState.userInput.length > 0 && (
                            <React.Fragment>
                                <div className="myclear"> <button onClick={clearInput} className="btn clearbtn">Clear</button> </div>
                                {<ScripSearchData data={sData} isLoading={isLoadingSearch} formState={formState} formStateChange={formStateChange} searchNameChange={searchNameChange}/>}
                            </React.Fragment>
                            )}
                        <Form.Control type="text"
                                    name="userInput"
                                    required
                                    autoComplete="off"
                                    value={formState.userInput}
                                    onChange={handleFormStateChange}
                                    placeholder="Select Index, Stocks"
                                    className="custom-placeholder"/>
                        <Form.Control.Feedback type='invalid' className="d-flex flex-row">

                        </Form.Control.Feedback>
                    </Form.Group>

                </Form>
                </div>
            </div>
            </div>
            {getRecentSearchesData()}
            {getTopSymbols()}
    </React.Fragment>
)
}

export default StrategyStore;