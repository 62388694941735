import {useDispatch,useSelector} from "react-redux";
import {Navigate, Outlet, useLocation, useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import * as moment from 'moment';
import {SESSION_TIME} from "../utils/config";
import authService from "../services/authService";
export const RequireAuth = () => {

    const { isLoggedIn, sessionTime ,data} = useSelector((state) => state.authUser);
    let location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();


    function checkSession() {
        var now = moment();
        var momentInTime = moment(sessionTime)
        var diff =  now.diff(momentInTime, 'minutes')
        if(diff > 0){
            if(data != undefined &&  data.mfaType=='qr'){
                handleLogout()
            }else{
                navigate('/MPin',{state:{type:location.pathname},replace: true})
            }
        }
    }



    useEffect(() => {
        if(isLoggedIn){
            checkSession()
        }
    },[])

    const handleLogout = () => {
        dispatch(authService.logout())
        navigate("/qrLogin",{ replace: true});
    }




    if (!isLoggedIn) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} />;
    }


    return <Outlet />;




}
