import React from 'react';
import {onSocketChange} from "../../slices/optionChainSlice";
import store from "../../store";
import {BasketModelToggle} from "../../slices/basketOrderSlice";
import {OptionStrategyToggle} from "../../slices/optionStrategySlice";

export function HandleSocket(value) {
    store.dispatch(onSocketChange(value))
}

export function HandleBasketModel(value) {
    store.dispatch(BasketModelToggle(value))
}

export function optionStrategyModel(value){
    store.dispatch(OptionStrategyToggle(value))
}



