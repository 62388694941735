import React from 'react';
import './tooltips.css'

function BoxTooltip({buy,sell,chart,position}) {
    return (
        <div className={position}>
            <div className="tooltips_content d-flex flex-row justify-content-between align-items-center">
                <div onClick={() => buy()} className="borderDemo bg_green c-pointer">B</div>
                <div onClick={() => sell()}  className="borderDemo bg_red c-pointer">S</div>
                <div onClick={() => chart()}  className="borderDemo bg_yellow c-pointer">
                    <i className="fa fa-bar-chart"/>
                </div>
            </div>
        </div>
    );
}

export default BoxTooltip;


