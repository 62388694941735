import React, {useEffect, useState} from "react";
import {useDispatch,useSelector} from "react-redux";
import {ReactComponent as CloserIcon} from "../../../assets/images/close-square2.svg";


const BuilderFilter = ({strategiesList=[],strategyTypeList=[],filterByStrategyName,closeFilter,filterByStrategyType,sortProfit,resetFilters})=>{

    const dispatch = useDispatch()
    const {builder_sort_by,strategy_name_filters,strategy_type_list,builder_strategy_list,strategy_type_filters} = useSelector((state) => state.optionStrategy);
    const [totalStrategies,setTotalStrategies]=useState(builder_strategy_list?builder_strategy_list:strategiesList)
    const [totalTypeList,setTotalTypeList]=useState(strategy_type_list?strategy_type_list:strategyTypeList)


    useEffect(()=>{
        if(strategy_name_filters){
            strategy_name_filters.map(d=>{
                const element = document.getElementById(d)
                if (element) {
                    element.checked = true;
                  }
            })
        }
    })

    useEffect(()=>{
        if(strategy_type_filters){
            strategy_type_filters.map(d=>{
                const element = document.getElementById(d)
                if (element) {
                    element.checked = true;
                  }
            })
        }
    })

    useEffect(()=>{
        const buttons = document.querySelectorAll('.sortButton');

        buttons.forEach((button) => {
            button.classList.remove('active');
          });
        const element = document.getElementById(builder_sort_by);
        if(element){
            element.classList.add('active');
        }
    })


    const Filter=()=>{
        closeFilter()
    }

    const handleChange=(event)=>{
        filterByStrategyName(event.target.value,event.target.checked)
    }

    const handleTypeChange=(event)=>{
        filterByStrategyType(event.target.value,event.target.checked)
    }

    const sortByProfit=(event)=>{
        const buttons = document.querySelectorAll('.sortButton');

        buttons.forEach((button) => {
            button.classList.remove('active');
          });
        const element = document.getElementById(event.currentTarget.id);
        element.classList.add('active');
        sortProfit(event.target.id)
    }

    const getNameFilters=()=>{
        return totalStrategies.map(d=>{
            return(
                <React.Fragment>
                    <div className="my-4">
                        <label className="checkbox">
                            <input id={d} type="checkbox" value={d} className="strategyCheck" onChange={handleChange}/>&nbsp;<span style={{"position":"relative","top":"-4px"}} className="strategyFilterValue">{d}</span>
                        </label>
                    </div>
                </React.Fragment>
    
            )
        })
    }

    const getStrategyTypeFilters=()=>{
        return totalTypeList.map(d=>{
            return(
                <div className="col-6 checkbox my-2">
                    <input onChange={handleTypeChange} id={d} type="checkbox" value={d}/>&nbsp;<span style={{"position":"relative","top":"-4px"}} className="strategyFilterValue">{d}</span>
                </div>
            )
        })
        
    }

    const getSortByFilters=()=>{
        return (
        <div className="my-2" style={{"display":"flex","flexDirection":"row","justifyContent":"space-between"}}>
                    <div className="sortButton c-pointer" id="maxProfit" onClick={sortByProfit}>Max profit</div>
                    <div className="sortButton c-pointer" id="maxLoss" onClick={sortByProfit}>Max loss</div>
                </div>
                )
    }
    return(
        <React.Fragment>
            <div className={`builder_filter_header pt-5 `}>
                <div className="container">
                    <div className="row">
                        <div className={`col-6 filter_name `}>
                            Filters
                        </div>
                        <div className={`col-3 reset pt-1 `}>
                            <a onClick={()=>resetFilters()}>Reset</a>
                        </div>
                        <div className="col-3"> 
                            <span onClick={Filter} className="c-pointer" id="">{<CloserIcon/>}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-4">
                <div className="row" style={{"textAlign":"left"}}>
                    <div className="col-12">
                        <div className="strategyFilterHeader">Filter by strategies</div>
                        {totalStrategies && getNameFilters()}
                    </div>
                    <div className="col-12 mt-3">
                        <div className="strategyFilterHeader">Strategy Type</div>
                            <div className="container p-0">
                                <div className="row p-0">
                                    {totalTypeList && getStrategyTypeFilters()}
                                </div>
                            </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="strategyFilterHeader">Sort by</div>
                        {getSortByFilters()}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default BuilderFilter