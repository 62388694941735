import React, {useEffect, useRef, useState} from "react";
import {SpotService} from "../../sub";
import './optionModel.scss'
import {getCloseNumber} from "../../comman/status";
import $ from 'jquery';


function HiddenTable({SelectedData}) {

    const [ dynamic_sport, setDynamic_sport ] = useState(null)
     const [ stockArray, setStockArray ] = useState([])
     const [ stockLine, setStockLine ] = useState(0)

    const [ mainHight, setMainHight ] = useState(0)
    const [ lineAt, setlineAt ] = useState(0)
    // const [ CEwidth, setCEwidth ] = useState(0)
    // const [ PEwidth, setPEwidth ] = useState(0)





    let subscription = undefined;
    useEffect(() => {
        subscription = SpotService.getSpot().subscribe(value => {
            setDynamic_sport(value)
        });
        return () => {
            subscription?.unsubscribe();
        }
    }, [])


    // function set_width() {
    //     const CE = $('#MAIN_TABLE > tbody > tr:nth-child(1) > td:nth-child(1)').width();
    //     const PE = $('#MAIN_TABLE > tbody > tr:nth-child(1) > td:nth-child(3)').width();
    //     setCEwidth(CE)
    //     setPEwidth(PE)
    // }

    useEffect(() => {
        setLine()
        // set_width()
    }, [mainHight])

    useEffect(() => {

    }, [lineAt])

    useEffect(() => {
        setLine();
        // set_width()
    }, [stockLine])

    function setLine(){
        let selectror = `[id='${stockLine}']`;
        const line_at = $(selectror).position();
        if(line_at) setlineAt(line_at.top)
    }


    useEffect(() => {
        if(dynamic_sport) trigerSportChange()
    }, [dynamic_sport])



       useEffect(() => {
           extractStockArray()
       }, [SelectedData])

       useEffect(() => {
           // console.log("stockArray",stockArray)
           executeScroll();
       }, [stockArray])


       function trigerSportChange() {
          let goal =  dynamic_sport.lp
          let stock_price =  getCloseNumber(goal,stockArray)
           if(goal > stock_price){
               let index = stockArray.indexOf(stock_price);
               if(index >= 0 && index < stockArray.length - 1){
                    stock_price = stockArray[index + 1]
               }
           }
           setStockLine(stock_price)
          let height = $('#MAIN_TABLE').height();
           setMainHight(height)
           // console.log("HIGHT",height);
       }

       const executeScroll = () => {
           setTimeout(() => {
               let element = document.getElementById("live_sport");
               element?.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});

               let height = $('#MAIN_TABLE').height();
               setMainHight(height)
           }, 2000);
       }



       function extractStockArray() {
           if(SelectedData.length > 0){

               if(dynamic_sport.market_segment_id === '13' || dynamic_sport.market_segment_id === '14'){
                   setStockArray(SelectedData.map(d => (d.strikePrice / 10000000)))
               }else {
                   setStockArray(SelectedData.map(d => (d.strikePrice / 100)))
               }

           }
       }


    if(dynamic_sport == null){
        return
    }

    return (
        <React.Fragment>

            {/*<div className="my_stock_table_2 w-100" style={{height: `${mainHight}px`}}>*/}
            {/*    <div style={{ height: `${lineAt}px`,width: `${CEwidth + 10}px` }} className="shade_left">&nbsp;</div>*/}
            {/*    <div style={{ height: `${mainHight - lineAt}px`,width: `${PEwidth + 10}px` }} className="shade_right">&nbsp;</div>*/}
            {/*</div>*/}

            <div className="my_stock_table w-100" style={{height: `${mainHight}px`}}>
                <div style={{ paddingTop: `${lineAt}px` }} className="">

                    <div  id="live_sport" key="live_sport" className="no_border d-flex flex-row w-100">
                        <div className="separator px-2">
                            <div className="line"/>
                            <div className="strike_back">Spot Price {dynamic_sport.lp} ({  isFinite(dynamic_sport.chp) ?  dynamic_sport.chp.toFixed(2): '0'}%)</div>
                            <div className="line"/>
                        </div>
                    </div>

                </div>
            </div>

        </React.Fragment>
    );
}

export default HiddenTable;
