import * as React from 'react';
import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import LoginForm from "./components/LoginForm/LoginForm";
import Dashboard from "./components/DashBord/Dashbord";
import {RequireAuth} from "./components/requiredAuth";
import RebrandingPopUp from './components/RebrandingPopUp/rebrandingPopup';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import ForgetPassword from "./components/ForgetPassword/forgetPassword";
import CreateNewPassword from "./components/CreateNewPassword/createNewPassword";
import ForgetUserId from "./components/ForgetUserId/forgetUserId";
import UserIdRecovery from "./components/ForgetUserId/UserIdRecovery";
import OldPasswordReset from "./components/OldPasswordReset/oldPasswordReset";
import MpinInput from "./components/MPinInput/mpinInput";
import MpinSet from "./components/MPinInput/MpinSet";
import ForgotMpin from "./components/MPinInput/ForgetMpin/forgetMpin";
import TagManager from 'react-gtm-module'
import LoginOtp from "./components/LoginForm/loginOTPVerify";
import LoginWithQr from "./components/LoginForm/loginWithQr";
import SetTotp from "./components/LoginForm/TOTP/setTotp";
import EnableTotp from "./components/LoginForm/TOTP/EnableTotp";
import FlowForgetPassword from "./components/LoginForm/FlowForgetPassword/flowforgetPassword";
import FlowCreateNewPassword from "./components/LoginForm/FlowForgetPassword/FlowCreateNewPassword";
import PasswordNeedChange from "./components/LoginForm/FlowPasswordChange/PasswordNeedChange";
import ResponseHandle from "./components/ResponseHandle/ResponseHandle";
import AuthStore from "./components/AuthStore/authStore";

const tagManagerArgs = {
    gtmId: 'GTM-52QR6XWJ'
}

TagManager.initialize(tagManagerArgs)
window.GtmPusher = function (ob) {
	if (ob.customEventProperties === undefined){
		ob.customEventProperties = {}
		if(window.google_tag_manager!==undefined){
			window.google_tag_manager['GTM-52QR6XWJ'].dataLayer.set('customEventProperties',undefined)
		}
		
	}
	window.dataLayer.push(ob)
}

const App = () => {
	return (
		<div id='themeManage'>

			<ToastContainer
				position="top-right"
				autoClose={2000}
				hideProgressBar={true}
				newestOnTop={true}
				closeOnClick={true}
				rtl={false}
				pauseOnHover={true}
			/>
			<RebrandingPopUp/>
			<BrowserRouter>
				<Routes>
					<Route  path="/" element={<LoginForm />}/>
					<Route  path="/qrLogin" element={<LoginWithQr />}/>
					<Route path="/login" element={<LoginForm />}/>
					<Route path="/loginOTPVerify" element={<LoginOtp />}/>
					<Route path="/setTOtp" element={<SetTotp />}/>
					<Route path="/EnableTOtp" element={<EnableTotp />}/>
					<Route path="/FlowForgetPassword" element={<FlowForgetPassword />}/>
					<Route path="/FlowCreateNewPassword" element={<FlowCreateNewPassword />}/>
					<Route path="/PasswordNeedChange" element={<PasswordNeedChange />}/>
					{/*<Route path="/ForgetPassword" element={<ForgetPassword />}/>*/}
					{/*<Route path="/CreateNewPassword" element={<CreateNewPassword />}/>*/}
					<Route path="/ForgetUserID" element={<ForgetUserId />}/>
					<Route path="/UserIdRecovery" element={<UserIdRecovery />}/>
					<Route path="/OldPasswordReset" element={<OldPasswordReset />}/>
					<Route path="/authStore" element={<AuthStore/>}/>
					<Route element={<RequireAuth />}>
						<Route path="/tvTerminal" element={<Dashboard />} />
						<Route path="/MPin" element={<MpinInput />}/>
						<Route path="/ForgotMpin" element={<ForgotMpin />}/>
						<Route path="/MPinSet" element={<MpinSet />}/>
					</Route>
					<Route path="*" element={<h1>Error 404 Page not Found !!</h1>} />
					<Route element={<ResponseHandle/>} />
				</Routes>
			</BrowserRouter>
		</div>
	);
};




export default App;

