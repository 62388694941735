import React, { useEffect,useState } from 'react';
import {useDispatch,useSelector} from "react-redux";
import {ReactComponent as MenuIconRect} from "../../../assets/icons/menu.svg";
import moment from 'moment';
import {DataFeedInstance} from "../../../chart-datafeed/udf-compatible-datafeed";
import {ExchangeSegmentMap} from "../../common/number_color";



const EachOption = ({id,data,index})=>{
    
    const [ subData, setSubData ] = useState(null);
    const {current_multiplier,executable_options} = useSelector((state) => state.optionStrategy);


    useEffect(() => {
        headerData()
        return () => {
            removeSub()
        }
    }, [data])


    let listenerGuid = undefined
    function headerData() {
        listenerGuid = `cepe-${index}-${data.option.token}-${data.option.marketSegmentId}`
        listenerGuid = `cepe-${index}-${data.option.token}-${ExchangeSegmentMap[data.option.exchange]}`
        DataFeedInstance.subcscribeQuotesFromTokens([
            {market_segment_id: ExchangeSegmentMap[data.option.exchange] , token: data.option.token }],(quotes)=>{
            setSubData(quotes[0].v)
        },listenerGuid)
    }

    function removeSub(){
        DataFeedInstance.unsubscribeQuotes(listenerGuid)
    }

    function formatdate(date){
        let now = moment(date, "YYYYMMDD").format('DD MMM YYYY');
        return now
    }
    return(
        <tr className="each_box card_bg">
        <td><div className="mr-2" style={{"position":"relative","left":"8px"}}>
            <i className="fa-solid fa-list fa-lg" style={{"color":"#586572"}}></i>
        </div></td>
        <td className="menuBox position-relative">
            <div className="d-flex flex-column justify-content-start align-items-start">
                <div className="d-flex flex-row">
                    <div className="type_badge mr-2">Delivery</div>
                    <div className="type_badge mr-2">RL-MKT</div>
                    <div className="type_badge mr-2">{formatdate(data.option.expiry_date)}</div>
                </div>
                <div className="d-flex flex-row mt-1">
                {data.action === 'BUY' ? <div className="buysell_badeg color_buy mr-1">B</div> : <div className="buysell_badeg color_sell mr-1">S</div> }
                    <div className="text_head1">{data.option.symbol}&nbsp;{data.option.strike_price}&nbsp;{data.option.option_type}</div>
                </div>
            </div>
        </td>
        <td className="priceTag optionLtp">{subData?subData.lp.toFixed(2):0}</td>
        <td className="priceTag">MKT Price</td>
        <td className="priceTag">{data.quantity*current_multiplier}</td>
        {/* <td>{getOrderStatus()}</td> */}
    </tr>
    )
}

export default EachOption