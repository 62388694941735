import React, {useEffect, useState} from 'react';
import Background from "../BackGround/background";
import {Link, useNavigate} from "react-router-dom";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import MadeInLoveIndia from "../common/madeInLoveIndia";
import {useDispatch, useSelector} from "react-redux";
import authService from "../../services/authService";
import {toast} from "react-toastify";

var regularExpression = /^(?=.*[0-9])(?=.*[!@#<>?$":_`~;[]|=+()*&^%-])[a-zA-Z0-9!@#<>?":_`~;[]|=+()*&^%-]{6,12}$/;

function OldPasswordReset(props) {


    const [formState, setFormState] = useState({
        user_id: '',
        new_password: '',
        new_password_copy: '',
        old_password: ''
    });
    const [errors, setErrors] = useState({})
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let {data, user_id, mobile} = useSelector((state) => state.authUser);


    useEffect(() => {
        if (user_id === null) {
            navigate("/",{ replace: true});
        }
    }, [])


    const [isLoading, setIsLoading] = useState(false);

    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordShown2, setPasswordShown2] = useState(false);

    const togglePassword = () => setPasswordShown(!passwordShown)
    const togglePassword2 = () => setPasswordShown2(!passwordShown2)


    function handleFormStateChange(event) {
        const {name, value} = event.target;

        setFormState({...formState, [name]: value});

        if (!!errors[name]) setErrors({
            ...errors,
            [name]: null
        })
    }


    function changePassword(formState) {

        const dataPost = {
            user_id,
            new_password: formState.new_password,
            old_password: data.password,
        }

        setIsLoading(true);
        authService.NewPasswordSet(dataPost)
            .then(value => {
                if (value.data.status === "success") {
                    reloginwithNewPassword().then(r => setIsLoading(false))
                    toast.success(value.data.message)
                }
            })
            .catch(error => {
                toast.error(`${error.response.data.message}`)
                setIsLoading(false);
            })
            .finally(() => {

            })

    }

    async function reloginwithNewPassword() {
        let new_data = { ...data, password: formState.new_password};
        // toast.info("Please wait...")
        await dispatch(authService.fetchAuthUser(new_data, navigate));
        setIsLoading(false);
    }


    const findFormErrors = () => {
        const {new_password, new_password_copy, old_password} = formState
        const newErrors = {}
        // name errors


        // if ( !old_password || old_password === '' ) newErrors.old_password = 'Old password id is required!'
        // else if ( old_password.length < 4 ) newErrors.old_password = 'Old password is too short!'

        if (!regularExpression.test(new_password)) {
            newErrors.new_password = 'Strong Password Needed'
            newErrors.new_password_copy = 'Strong Password Needed'
        } else {
            if (new_password !== new_password_copy) {
                newErrors.new_password = 'Both Password Should match'
                newErrors.new_password_copy = 'Both Password Should match'
            }
        }

        return newErrors
    }

    function handleOnClick(event) {
        event.preventDefault();
        const newErrors = findFormErrors()
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors)
        } else {
            setErrors({})
            changePassword(formState);
        }

    }


    return (
        <Background>
            <div className="h-100 p-lg-5 p-5 d-flex flex-column justify-content-between ">

                <div className="">

                    {/*<div className="py-2">*/}
                    {/*    <Link to="/ForgetPassword"><i className="fas fa-arrow-left fas fa-lg"/></Link>*/}
                    {/*</div>*/}

                    <h3 className="heading1">Set New Password</h3>

                    <div className="colorGrey pt-0">You need to create your own new password</div>


                </div>


                <div className="align-self-start flex-fill w-100">

                    <div className="pt-2">

                        <Form>

                            {/* <Form.Group className="mb-2" controlId="formBasicOldpass">
                                <Form.Label className="text1 w-100">
                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <div className="">Old Password</div>
                                        <div className="colorBlue">&nbsp;</div>
                                    </div>
                                </Form.Label>
                                <InputGroup className="">
                                    <Form.Control type={passwordShown2 ? "text" : "password"}
                                                  name="old_password"
                                                  required
                                                  isInvalid={ !!errors.old_password }
                                                  value={formState.old_password}
                                                  onChange={handleFormStateChange}
                                                  placeholder="Old Password" />
                                    <InputGroup.Text>
                                        {
                                            passwordShown2 ?  <i onClick={togglePassword2} className="fa-solid fa-eye"/>  :
                                                <i onClick={togglePassword2} className="fas fa-eye-slash"/>
                                        }
                                    </InputGroup.Text>

                                    <Form.Control.Feedback type='invalid'>
                                        { errors.old_password }
                                    </Form.Control.Feedback>

                                </InputGroup>

                            </Form.Group>*/}


                            <Form.Group className="mb-2" controlId="formBasicpass">
                                <Form.Label className="text1 w-100">
                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <div className="">New Password</div>
                                        <div className="colorBlue">&nbsp;</div>
                                    </div>
                                </Form.Label>
                                <InputGroup className="">
                                    <Form.Control type={passwordShown ? "text" : "password"}
                                                  name="new_password"
                                                  required
                                                  isInvalid={!!errors.new_password}
                                                  value={formState.new_password}
                                                  onChange={handleFormStateChange}
                                                  placeholder="New Password"/>
                                    <InputGroup.Text>
                                        {
                                            passwordShown ? <i onClick={togglePassword} className="fa-solid fa-eye"/> :
                                                <i onClick={togglePassword} className="fas fa-eye-slash"/>
                                        }
                                    </InputGroup.Text>
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.new_password}
                                    </Form.Control.Feedback>
                                </InputGroup>

                            </Form.Group>


                            <Form.Group className="mb-2" controlId="formBasiccopy">
                                <Form.Label className="text1 w-100">
                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <div className="">Confirm password</div>
                                        <div className="colorBlue">&nbsp;</div>
                                    </div>
                                </Form.Label>
                                <InputGroup className="">
                                    <Form.Control type={passwordShown ? "text" : "password"}
                                                  name="new_password_copy"
                                                  required
                                                  isInvalid={!!errors.new_password_copy}
                                                  value={formState.new_password_copy}
                                                  onChange={handleFormStateChange}
                                                  placeholder="Confirm password"/>
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.new_password_copy}
                                    </Form.Control.Feedback>
                                </InputGroup>

                            </Form.Group>


                            <small className="form-text text-muted">Should contain at least 1 alphabet,1 number,1
                                special character and 6 to 12 character long</small>


                        </Form>

                    </div>

                </div>


                <div className="">
                    <Button disabled={isLoading} className="blue_btn w-100 my-3" onClick={handleOnClick}
                            variant="primary" type="submit">
                        {isLoading && (
                            <Spinner className="mr-2" as="span" animation="border" size="sm" role="status"
                                     aria-hidden="true"/>
                        )}
                        Set new password
                    </Button>

                    <MadeInLoveIndia/>
                </div>


            </div>
        </Background>
    );
}

export default OldPasswordReset;
