import React,{ useEffect, useState } from 'react';
import { ReactComponent as Indicatorslogo } from "../../assets/images/IndicatorsLogo.svg"
import { ReactComponent as BasketLogo } from "../../assets/images/BasketorderLogo.svg"
import { ReactComponent as DarwingToolsLogo } from "../../assets/images/DarwingToolsLogo.svg"
import { ReactComponent as ChartBasedLogo } from "../../assets/images/ChartbasedLogo.svg"
import { ReactComponent as ChartBasedTrading } from "../../assets/images/ChartBasedTrading.svg"
import { ReactComponent as IndicatorsBasedTrading } from "../../assets/images/IndicatorsBasedTrading.svg"
import { ReactComponent as DrawingToolstrading } from "../../assets/images/DrawingToolstrading.svg"
import { ReactComponent as BasketOrderTrading } from "../../assets/images/BasketOrderTrading.svg"


const DesktopViewSupport = ()=>{

    const [content,setContent]=useState('terminal')
    const [globalnumber,setGlobalNumber]=useState(1)

    function featureClicked(number) {
        switch(number) {
            case 1:
                setGlobalNumber(1)
                setContent("terminal")
                break;
            case 2:
                setGlobalNumber(2)
                setContent("Chart based")
                break;
            case 3:
                setGlobalNumber(3)
                setContent("terminal")
                break;
            case 4:
                setGlobalNumber(4)
                setContent("Chart based")
                break;
            default:
                setGlobalNumber(5)
                setContent("terminal")
                break
            
        }
    }



    function returnImage(){
        switch(globalnumber) {
                    case 1:
                        return(<ChartBasedTrading style={{"width":"100%","height":"150px"}}/>)
                    case 2:
                        return(<BasketOrderTrading style={{"width":"100%","height":"150px"}}/>)
                    case 3:
                        return(<IndicatorsBasedTrading style={{"width":"100%","height":"150px"}}/>)
                    case 4:
                        return(<DrawingToolstrading style={{"width":"100%","height":"150px"}}/>)
                    default:
                        return(<ChartBasedTrading style={{"width":"100%","height":"150px"}}/>)                    
                }
    }

    return(
        <div className='container' style={{"height":"100vh"}}>
            <div className='row'>
                <div className='col-12'>
                    <div className='downloadHeader'>
                        <div style={{"display":"flex","flexDirection":"row","gap":"10px"}}>
                            <div><i className="fa fa-exclamation-circle" aria-hidden="true" style={{"color":"#FE8400"}}></i></div>
                            <div>
                                <div className='downloadHead'>Oops! Supported in Desktop View</div>
                                <div className=''>`Rupeezy Web` is best viewed on desktop</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12' style={{"textAlign":"center","marginTop":"20px"}}>
                    <div className='middleHead'>TradingView powered Rupeezy Web</div>
                    <div className='middleDesc'>Switch to Power Trading via TradingView Charts</div>
                </div>
                <div className='col-12 mt-5'>
                    <div>{returnImage()}</div>
                    {/* <div>{content}</div> */}
                </div>
                <div className='col-12 mt-5'>
                    <div style={{"display":"flex","flexDirection":"row","justifyContent":"space-between"}}>
                        <div className={`p-3 clickFeatures ${globalnumber === 1 ? 'activeFeature' : ''}`} onClick={()=>featureClicked(1)}>
                            <div className='clickComp'>
                                <div className='mr-1'><ChartBasedLogo/></div>
                                <div className='logoText'>Chart based trading</div>
                            </div>
                        </div>
                        <div className={`p-3 clickFeatures ${globalnumber === 2 ? 'activeFeature' : ''}`} onClick={()=>featureClicked(2)}>
                            <div className='clickComp'>
                                <div className='mr-1'><BasketLogo/></div>
                                <div className='logoText'>Basket order</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 mt-3'>
                    <div style={{"display":"flex","flexDirection":"row","justifyContent":"space-between"}}>
                        <div className={`p-3 clickFeatures ${globalnumber === 3 ? 'activeFeature' : ''}`} onClick={()=>featureClicked(3)}>
                            <div className='clickComp'>
                                <div className='mr-1'><Indicatorslogo/></div>
                                <div className='logoText'>100+ technical indicators</div>
                            </div>
                        </div>
                        <div className={`p-3 clickFeatures ${globalnumber === 4 ? 'activeFeature' : ''}`} onClick={()=>featureClicked(4)}>
                            <div className='clickComp'>
                                <div className='mr-1'><DarwingToolsLogo/></div>
                                <div className='logoText'>50+ intelligent drawing tools</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 mobileApp'>
                    <div><img src='https://static.rupeezy.in/branding/blue-for-light-background.svg' alt='flowlogo' width={56} height={56}/></div>
                    <div>MOBILE APP - Rupeezy</div>
                </div>
                <div className='col-12 mt-4'>
                   <a href='https://asthatrade.onelink.me/O4xZ/k53yrsop'>
                    <span className='downloadButton'>
                        Open Rupeezy
                    </span>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default DesktopViewSupport