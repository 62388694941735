
export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

export function encodeBase64(data) {
    if (typeof btoa === "function") {
        return btoa(data);
    } else if (typeof Buffer === "function") {
        return Buffer.from(data, "utf-8").toString("base64");
    } else {
        throw new Error("Failed to determine the platform specific encoder");
    }
}

export function decodeBase64(data) {
    if (typeof atob === "function") {
        return atob(data);
    } else if (typeof Buffer === "function") {
        return Buffer.from(data, "base64").toString("utf-8");
    } else {
        throw new Error("Failed to determine the platform specific decoder");
    }
}

export function segmentToInstrumentType(segment) {
    segment = segment.toString().toLowerCase()
    const data = {nse_eq: 'nse',nse_fo: 'nfo',nse_cur: 'cds',mcx_fo: 'mcx', bse_eq: 'bse', bse_fo: 'bfo'}
    return  data[segment]
}
export function exchangeToMarketSegmentId(exchange) {
    exchange = exchange.toString().toLowerCase()
    const data = {nse_eq: 1,nse_fo: 2,nse_cur: 13,mcx_fo: 4,bse_eq: 3,bse_fo: 4}
    return  data[exchange]
}

export function numDifferentiation(value) {
    var val = Math.abs(value);
    var final = '';
    if (val >= 10000000) {
        value = (val / 10000000).toFixed(2);
        final = value + 'Cr';
    } else if (val >= 100000) {
        value = (val / 100000).toFixed(2);
        final = value + 'L';
    } else if (val >= 1000) {
        value = (val / 1000).toFixed(2);
        final = value + 'K';
    }else{
        final = '' + val;
    }
    return final;
}

export const  getCloseNumber = (goal,array)  => {
    return array.reduce(function(prev, curr) {
        return (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
    },0);
}

export function createElementFromHTML(htmlString) {
    var div = document.createElement('div');
    div.innerHTML = htmlString.trim();
    return div.firstChild;
}

// export var regularExpression = /^(?=.*[0-9])(?=.*[!@#<>?$":_`~;[]|=+()*&^%-])[a-zA-Z0-9!@#<>?":_`~;[]|=+()*&^%-]{8,16}$/;

export var regularExpression = /^(?=.*\d{1})(?=.*[a-z]{1})(?=.*[A-Z]{1})(?=.*[!@#$%^&*{|}?~_=+.-]{1})(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$/;

export function getBrowserDetails() {
    var nAgt = navigator.userAgent;
    var browserName  = navigator.appName;
    var fullVersion  = ''+parseFloat(navigator.appVersion);
    var nameOffset,verOffset,ix;

    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset=nAgt.indexOf("Opera"))!==-1) {
        browserName = "Opera";
        fullVersion = nAgt.substring(verOffset+6);
        if ((verOffset=nAgt.indexOf("Version"))!==-1)
            fullVersion = nAgt.substring(verOffset+8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset=nAgt.indexOf("MSIE"))!==-1) {
        browserName = "Microsoft Internet Explorer";
        fullVersion = nAgt.substring(verOffset+5);
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset=nAgt.indexOf("Chrome"))!==-1) {
        browserName = "Chrome";
        fullVersion = nAgt.substring(verOffset+7);
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset=nAgt.indexOf("Safari"))!==-1) {
        browserName = "Safari";
        fullVersion = nAgt.substring(verOffset+7);
        if ((verOffset=nAgt.indexOf("Version"))!==-1)
            fullVersion = nAgt.substring(verOffset+8);
    }
    // In Firefox, the true version is after "Firefox"
    else if ((verOffset=nAgt.indexOf("Firefox"))!==-1) {
        browserName = "Firefox";
        fullVersion = nAgt.substring(verOffset+8);
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) <
        (verOffset=nAgt.lastIndexOf('/')) )
    {
        browserName = nAgt.substring(nameOffset,verOffset);
        fullVersion = nAgt.substring(verOffset+1);
        if (browserName.toLowerCase()===browserName.toUpperCase()) {
            browserName = navigator.appName;
        }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix=fullVersion.indexOf(";"))!==-1)
        fullVersion=fullVersion.substring(0,ix);
    if ((ix=fullVersion.indexOf(" "))!==-1)
        fullVersion=fullVersion.substring(0,ix);

    var OSName="Unknown OS";
    var appvs = navigator.appVersion;
    if (appvs.indexOf("Win")!==-1) OSName="Windows";
    if (appvs.indexOf("Mac")!==-1) OSName="MacOS";
    if (appvs.indexOf("X11")!==-1) OSName="UNIX";
    if (appvs.indexOf("Linux")!==-1) OSName="Linux";

    return {browserName,fullVersion,OSName}

}


