import React, {useRef, useState} from 'react';
import Spinner from "react-bootstrap/Spinner";
import AddToBasket from "../AddToBasket/addToBasket";
import nextId from "react-id-generator";

function SearchData({data,formState,isLoading,formStateChange}) {

    const [currentData, setCurrentData] = useState(null);

    const childRef = useRef();

    function onSymbleChange(stypevalue){
        formStateChange('symbolType',stypevalue)
    }

    function onExchangeChange(event){
        const { value } = event.target;
        formStateChange('exchange',value)
    }

    function getSortName(name) {
        // console.log(name)
        const data = { EQUITIES: 'EQ',OPTSTK:'OPT',FUTSTK:'FUT', OPTIDX: 'OPT', OPTCUR: 'OPT', FUTCOM: 'FUT', FUTCUR: 'FUT', FUTIDX: 'FUT', OPTFUT: 'OPT'};
        return data[name]
    }

    function openAddBasket(data) {
        setCurrentData(data)
        childRef.current.getAlert();
    }

    function getsData() {

        if(isLoading){
            return(
                <div className="d-flex flex-row justify-content-center align-items-center">
                    <Spinner style={{ marginTop:'100px'}} className="mr-2 pri" as="span" animation="border" variant="primary" size="sm" role="status" aria-hidden="true"/>
                </div>
            )
        }

        return (
            <div  className="d-flex srollbox flex-column justify-content-start align-items-start pl-4">

                { isLoading && (
                    <div className="d-flex flex-row justify-content-center align-items-center">
                        <Spinner style={{ marginTop:'100px'}} className="mr-2 pri" as="span" animation="border" variant="primary" size="sm" role="status" aria-hidden="true"/>
                    </div>
                )
                }

                {
                    data.map(d => {
                        const id1 = nextId("B-");
                        return(
                            <div key={id1} onClick={() => openAddBasket(d)} className="d-flex flex-row my-2 datarow w-100">
                                <div className="NameLable">{ getSortName(d._source.sInstrumentName) }</div>
                                <div className="pl-3 mt-1 d-flex flex-column justify-content-start align-items-start">
                                    <div className="d-flex flex-row justify-content-center">
                                        <div className="currentText">{d._source.sSymbol}</div>
                                        <div className="badge_bg" id="badge_exch">{d.exchange}</div>
                                    </div>
                                    <div className="text_low currentText2">{d.description}</div>
                                </div>
                            </div>
                        )
                    })
                }

                <AddToBasket ref={childRef} stockData={currentData}/>

            </div>
        )

    }

    return(
        <div className="searchData card_bg">

            <div className="d-flex flex-row justify-content-start align-items-center p-4">
                <div onClick={() => onSymbleChange('5')} className={`my_badge_topic ${formState.symbolType === '5' ? 'active':''}`}>All</div>
                <div onClick={() => onSymbleChange('1')}  className={`my_badge_topic ${formState.symbolType === '1' ? 'active':''}`}>Stocks</div>
                <div onClick={() => onSymbleChange('2')}  className={`my_badge_topic ${formState.symbolType === '2' ? 'active':''}`}>Options</div>
                <div onClick={() => onSymbleChange('3')}  className={`my_badge_topic ${formState.symbolType === '3' ? 'active':''}`}>Futures</div>
                <div onClick={() => onSymbleChange('4')}  className={`my_badge_topic ${formState.symbolType === '4' ? 'active':''}`}>Indices</div>
            </div>

            {getsData()}
        </div>
    )
}


export default SearchData;
