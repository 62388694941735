import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {ReactComponent as CloserIcon} from "../../assets/images/close-square2.svg";


const AddFilter = ({preferences,filterFunction,AboveATMCount,BelowATMCount,updatePref,reset,vegaFunction,thetaFunction,gammaFunction,deltaFunction})=>{

    const [showVega,setShowVega]=useState(false)
    const [showTheta,setShowTheta]=useState(false)
    const [showGamma,setShowGamma]=useState(false)
    const [showDelta,setShowDelta]=useState(false)
    const [vegaClass,setVegaClass] = useState(null)
    const [thetaClass,setThetaClass] = useState(null)
    const [gammaClass,setGammaClass] = useState(null)
    const [deltaClass,setDeltaClass] = useState(null)
    const [darkClass,setDarkClass] = useState('light')


    useEffect(()=>{
        if(preferences) ApplyFilters()
    },[preferences])


    // const ApplyFilters = () => {
    //     // console.log("applying greeks filters")
    //     setTimeout(()=>{
    //         if(preferences.greeks.vega){
    //             const element = document.getElementsByName("vega")
    //             for(var i=0;i<element.length;i++)
    //             {
    //                 element[i].style.display="revert";
    //             }
    //             const greeks_button = document.getElementById("vega_button")
    //             greeks_button.style.background = "#064AD3"
    //             greeks_button.style.color="#FFFFFF"
    //             setShowVega(!showVega) 
    //         }
    //         if(preferences.greeks.theta){
    //             const element = document.getElementsByName("theta")
    //             for(var i=0;i<element.length;i++)
    //             {
    //                 element[i].style.display="revert";
    //             }
    //             const greeks_button = document.getElementById("theta_button")
    //             greeks_button.style.background = "#064AD3"
    //             greeks_button.style.color="#FFFFFF"
    //             setShowTheta(!showTheta) 
    //         }
    //         if(preferences.greeks.gamma){
    //             const element = document.getElementsByName("gamma")
    //             for(var i=0;i<element.length;i++)
    //             {
    //                 element[i].style.display="revert";
    //             }
    //             const greeks_button = document.getElementById("gamma_button")
    //             greeks_button.style.background = "#064AD3"
    //             greeks_button.style.color="#FFFFFF"
    //             setShowGamma(!showGamma) 
    //         }
    //         if(preferences.greeks.delta){
    //             const element = document.getElementsByName("delta")
    //             for(var i=0;i<element.length;i++)
    //             {
    //                 element[i].style.display="revert";
    //             }
    //             const greeks_button = document.getElementById("delta_button")
    //             greeks_button.style.background = "#064AD3"
    //             greeks_button.style.color="#FFFFFF"
    //             setShowDelta(!showDelta) 
    //         }
    //     },3500)
    // }

    const ApplyFilters = () =>{
        if(preferences.greeks.vega){
            setVegaClass('vega_active_button')
            setShowVega(true)
        }
        if(preferences.greeks.theta){
            setThetaClass('theta_active_button')
            setShowTheta(true)
        }
        if(preferences.greeks.gamma){
            setGammaClass('gamma_active_button')
            setShowGamma(true)
        }
        if(preferences.greeks.delta){
            setDeltaClass('delta_active_button')
            setShowDelta(true)
        }
    }

    // function showHide(elementId,reset=false){
    //     const element = document.getElementsByName(elementId)
    //     if(elementId==="vega"){
    //         if(showVega===true || reset===true){
    //             for(var i=0;i<element.length;i++)
    //             {
    //                 element[i].style.display="none";
    //             }
    //         const greeks_button = document.getElementById("vega_button")
    //         greeks_button.style.background = "#FFFFFF"
    //         greeks_button.style.color="#000000"
    //         }else{
    //             for(var i=0;i<element.length;i++)
    //             {
    //                 element[i].style.display="revert";
    //             } 
    //         const greeks_button = document.getElementById("vega_button")
    //         greeks_button.style.background = "#064AD3"
    //         greeks_button.style.color="#FFFFFF"
    //         }
    //         let elementId=document.getElementById("vega")
    //         elementId.scrollIntoView({behavior:"smooth",block: 'nearest', inline: 'center'});
    //         setTimeout(()=>{
    //             let targetRow = document.getElementById("spot_price_live")
    //             if(targetRow){
    //                 targetRow.scrollIntoView({behavior:"smooth",block: 'nearest', inline: 'center'});
    //             }
    //         },1000)
    //         setShowVega(!showVega)
    //     }
    //     if(elementId==="theta"){
    //         if(showTheta===true || reset===true){
    //             for(var i=0;i<element.length;i++)
    //             {
    //                 element[i].style.display="none";
    //             }
    //             const greeks_button = document.getElementById("theta_button")
    //             greeks_button.style.background = "#FFFFFF"
    //             greeks_button.style.color="#000000"
    //         }else{
    //             for(var i=0;i<element.length;i++)
    //             {
    //                 element[i].style.display="revert";
    //             }
    //             const greeks_button = document.getElementById("theta_button")
    //         greeks_button.style.background = "#064AD3"
    //         greeks_button.style.color="#FFFFFF"
    //         let elementId=document.getElementById("theta")
    //         elementId.scrollIntoView({behavior:"smooth",block: 'nearest', inline: 'center'});
    //         setTimeout(()=>{
    //             let targetRow = document.getElementById("spot_price_live")
    //             if(targetRow){
    //                 targetRow.scrollIntoView({behavior:"smooth",block: 'nearest', inline: 'center'});
    //             }
    //         },1000)
    //         }
    //         setShowTheta(!showTheta)
    //     }
    //     if(elementId==="gamma"){
    //         if(showGamma===true || reset===true){
    //             for(var i=0;i<element.length;i++)
    //             {
    //                 element[i].style.display="none";
    //             }
    //             const greeks_button = document.getElementById("gamma_button")
    //             greeks_button.style.background = "#FFFFFF"
    //             greeks_button.style.color="#000000"
    //         }else{
    //             for(var i=0;i<element.length;i++)
    //             {
    //                 element[i].style.display="revert";
    //             }
    //         const greeks_button = document.getElementById("gamma_button")
    //         greeks_button.style.background = "#064AD3"
    //         greeks_button.style.color="#FFFFFF"
    //         let elementId=document.getElementById("gamma")
    //         elementId.scrollIntoView({behavior:"smooth",block: 'nearest', inline: 'center'});
    //         setTimeout(()=>{
    //             let targetRow = document.getElementById("spot_price_live")
    //             if(targetRow){
    //                 targetRow.scrollIntoView({behavior:"smooth",block: 'nearest', inline: 'center'});
    //             }
    //         },1000)
    //         }
    //         setShowGamma(!showGamma)
    //     }
    //     if(elementId==="delta"){
    //         if(showDelta===true || reset===true){
    //             for(var i=0;i<element.length;i++)
    //             {
    //                 element[i].style.display="none";
    //             }
    //             const greeks_button = document.getElementById("delta_button")
    //             greeks_button.style.background = "#FFFFFF"
    //             greeks_button.style.color="#000000"
    //         }else{
    //             for(var i=0;i<element.length;i++)
    //             {
    //                 element[i].style.display="revert";
    //             }
    //             const greeks_button = document.getElementById("delta_button")
    //             greeks_button.style.background = "#064AD3"
    //             greeks_button.style.color="#FFFFFF"
    //             let elementId=document.getElementById("delta")
    //         elementId.scrollIntoView({behavior:"smooth",block: 'nearest', inline: 'center'});
    //             setTimeout(()=>{
    //                 let targetRow = document.getElementById("spot_price_live")
    //                 if(targetRow){
    //                     targetRow.scrollIntoView({behavior:"smooth",block: 'nearest', inline: 'center'});
    //                 }
    //             },1000)
    //         }
    //         setShowDelta(!showDelta)
    //     }
    // }

    function scrollIntoView(Id){
            let elementId=document.getElementById("MAINTABLE")
            elementId.scrollIntoView({behavior:"smooth",block: 'center', inline: 'start'});
            setTimeout(()=>{
                let targetRow = document.getElementById("spot_price_live")
                if(targetRow){
                    targetRow.scrollIntoView({behavior:"smooth",block: 'nearest', inline: 'center'});
                }
            },1000)
    }

    function showHideVega(){
        if(vegaClass!='vega_active_button'){
            setVegaClass('vega_active_button')
            vegaFunction(true)
            scrollIntoView('vega')
        }else{
            setVegaClass('')
            vegaFunction(false)
        }
        setShowVega(!showVega)
    }
    function showHideTheta(){
        if(thetaClass!='theta_active_button'){
            setThetaClass('theta_active_button')
            thetaFunction(true)
            scrollIntoView('theta')
        }else{
            setThetaClass('')
            thetaFunction(false)
        }
        setShowTheta(!showTheta)
    }
    function showHideGamma(){
        if(gammaClass!='gamma_active_button'){
            setGammaClass('gamma_active_button')
            gammaFunction(true)
            scrollIntoView('gamma')
        }else{
            setGammaClass('')
            gammaFunction(false)
        }
        setShowGamma(!showGamma)
    }
    function showHideDelta(){
        if(deltaClass!='delta_active_button'){
            setDeltaClass('delta_active_button')
            deltaFunction(true)
            scrollIntoView('delta')
        }else{
            setDeltaClass('')
            deltaFunction(false)
        }
        setShowDelta(!showDelta)
    }

    function resetFilters(){
        if(preferences.greeks.vega){
            setVegaClass('vega_active_button')
            setShowVega(true)
            vegaFunction(true)
        }else{
            setVegaClass('')
            setShowVega(false)
            vegaFunction(false)
        }
        if(preferences.greeks.theta){
            setThetaClass('theta_active_button')
            setShowTheta(true)
            thetaFunction(true)
        }else{
            setThetaClass('')
            setShowTheta(false) 
            thetaFunction(false)
        }
        if(preferences.greeks.gamma){
            setGammaClass('gamma_active_button')
            setShowGamma(true)
            gammaFunction(true)
        }else{
            setGammaClass('')
            setShowGamma(false)
            gammaFunction(false)
        }
        if(preferences.greeks.delta){
            setDeltaClass('delta_active_button')
            setShowDelta(true)
            deltaFunction(true)
        }else{
            setDeltaClass('')
            setShowDelta(false)
            deltaFunction(false)
        }
        reset()
    }
 
    return(
        <div>
            <div className={`filter_header pt-5 `}>
                <div className="container">
                    <div className="row">
                        <div className={`col-6 filter_name `}>
                            Filters
                        </div>
                        <div className={`col-3 reset pt-1 `}>
                            <a onClick={resetFilters}>Reset</a>
                        </div>
                        <div className="col-3">
                            <span onClick={()=> updatePref(showVega,showDelta,showTheta,showGamma)} className="c-pointer" id="">{<CloserIcon/>}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="filter_body">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <div className={`greeks_name mt-2 `}>
                                Greeks
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row mt-4">
                        <div className="col-6 pr-1">
                            <div className={`greeks_button ${darkClass} ${deltaClass}`} id="delta_button" onClick={showHideDelta}>Delta</div>
                        </div>
                        <div className="col-6 pl-1">
                            <div className={`greeks_button ${darkClass} ${gammaClass}`}  id="gamma_button" onClick={showHideGamma}>Gamma</div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-6 pr-1">
                            <div className={`greeks_button ${darkClass} ${thetaClass}`}  id="theta_button" onClick={showHideTheta}>Theta</div>
                        </div>
                        <div className="col-6 pl-1">
                            <div className={`greeks_button ${darkClass} ${vegaClass}`}  id="vega_button" onClick={showHideVega}>Vega</div>
                        </div>
                    </div>
                    <div className="row mt-6">
                        <div className="col-12">
                            <div className={`strikesAtm `}>Strikes Above ATM</div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-4 pr-1">
                            <div className="Atm_Values" id="A10" onClick={ () => filterFunction(10,BelowATMCount,"filter hit")}>10</div>
                        </div>
                        <div className="col-4 pr-1 pl-1">
                        <div className="Atm_Values" id="A20" onClick={ () => filterFunction(20,BelowATMCount,"filter hit")}>20</div>
                        </div>
                        <div className="col-4 pl-1">
                        <div className="Atm_Values" id="A30" onClick={ () => filterFunction(30,BelowATMCount,"filter hit")}>30</div>
                        </div>
                    </div>
                    <div className="row mt-4"> 
                        <div className="col-12">
                            <div className={`strikesAtm `}>Strikes Below ATM</div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-4 pr-1">
                            <div className="Atm_Values" id="B10" onClick={ () => filterFunction(AboveATMCount,10,"filter hit")}>10</div>
                        </div>
                        <div className="col-4 pr-1 pl-1">
                        <div className="Atm_Values" id="B20" onClick={ () => filterFunction(AboveATMCount,20,"filter hit")}>20</div>
                        </div>
                        <div className="col-4 pl-1">
                        <div className="Atm_Values" id="B30" onClick={ () => filterFunction(AboveATMCount,30,"filter hit")}>30</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddFilter

