import React, { useEffect,useState } from "react";
import {useDispatch,useSelector} from "react-redux";
import moment from 'moment';
import OptionStrategyHeader from "../OptionStrategyHeader";
import Form from "react-bootstrap/Form";
import {DataFeedInstance} from "../../../chart-datafeed/udf-compatible-datafeed";
import {ReactComponent as SearchIcon} from "../../../assets/icons/search-normal.svg";
import ShortStrangle  from "../../../assets/icons/ShortStrangle.svg"
import LongStrangle  from "../../../assets/icons/LongStrangle.svg"
import ShortStraddle  from "../../../assets/icons/ShortStraddle.svg"
import LongStraddle  from "../../../assets/icons/LongStraddle.svg"
import IronCondor  from "../../../assets/icons/IronCondor.svg"
import BullCallSpread from "../../../assets/icons/BullCallSpread.svg"
import BullPutSpread from "../../../assets/icons/BullPutSpread.svg"
import RatioCallSpread from "../../../assets/icons/RatioCallSpread.svg"
import RatioPutSpread from "../../../assets/icons/RatioPutSpread.svg"
import BearCallSpread from "../../../assets/icons/BearCallSpread.svg"
import BearPutSpread from "../../../assets/icons/BearPutSpread.svg"
import {ReactComponent as InfoIcon} from "../../../assets/icons/info-circle.svg"
import Buy from "../../../assets/icons/Buy.svg"
import {ReactComponent as UpArrow} from "../../../assets/icons/arrow-square-up.svg"
import Sell from "../../../assets/icons/Sell.svg"
import authService from "../../../services/authService";
import {currentUser} from "../../../slices/authSlice";
import flowBackEndServices2 from "../../../services/flowBackendServices";
import {setPredictRange,setStrategyState,setBuilderInput,setCurrentStrategy,setRecentSearches,setCurrentSymbolDetails,setExpiryDates,setCurrentExpiryDate,setStrategyNameDetails,setGlobalFunds} from "../../../slices/optionStrategySlice";
import ScripSearchData from "./ScripSearchData"
import HeaderSubscribedData from "./HeaderSubscribedData"
import {ReactComponent as Neutral} from "../../../assets/icons/Neutral.svg"
import CustomSpinner from "../CustomSpinner";



const RenderOptionStrategiesList=({})=>{
    const dispatch = useDispatch()
    const [ sData, setSData ] = useState([]);
    const [isLoadingSearch, setIsLoadingSearch] = useState(false);
    const user = useSelector(currentUser);   
    const [dates,setDates]=useState(null)
    const [typingTimer, setTypingTimer] = useState(null)
    const [formState, setFormState] = useState({userInput: '', exchange: 'All Exchanges', symbolType: '6'});
    const [symbolDetails,setSymbolDetails]=useState(null)
    const [showSearchedData,setShowSearchedData]=useState(true)
    const [allStrategies,setAllStrategies]=useState(null)
    const [needToUpdate,setNeedToUpdate]=useState(false)
    const [isLoading,setIsLoading] = useState(false)
    const [predictionType,setPredictionType]=useState("between")
    const [predictedInputValueFirst,setPredictedInputValueFirst]=useState(null)
    const [predictedInputValueSecond,setPredictedInputValueSecond]=useState(null)
    const [defaultInputFirst,setDefaultInputFirst]=useState(0)
    const [defaultInputSecond,setDefaultInputSecond]=useState(0)



    const {current_symbol_details,expiryDates,current_expiry_date,current_strategy,current_strategy_state} = useSelector((state) => state.optionStrategy);

    useEffect(()=>{
        dispatch(setGlobalFunds(null))
    })

    useEffect(()=>{
        const buttons = document.querySelectorAll('.predictType');

        buttons.forEach((button) => {
            button.classList.remove('active'); 
          });
        const element = document.getElementById("between");
        if(element){
            element.classList.add('active');
        }
    },[])

    useEffect(()=>{
        if(current_symbol_details){
            setFormState({ ...formState, userInput: current_symbol_details.sSymbol });
            setSymbolDetails(current_symbol_details)
        }
    },[current_symbol_details])

    useEffect(()=>{
        if(symbolDetails){
            getExpiryDates()
        }
    },[symbolDetails])

    useEffect(()=>{
            if(current_expiry_date && symbolDetails && current_strategy_state.url=="allStrategies") getStrategies(current_expiry_date)
    },[current_expiry_date,expiryDates,symbolDetails])

    

    useEffect(()=>{
        const buttons = document.querySelectorAll('.expDateButton');
        
        buttons.forEach((button) => {
            button.classList.remove('active');
          });
          if(current_expiry_date){
            const element = document.getElementById(current_expiry_date?current_expiry_date:dates[0]);
            if(element){
                element.classList.add('active'); 
            }
          }
    },[current_expiry_date,isLoading])



    useEffect(() => {
        searchDataFunc(formState.userInput)
    }, [formState.symbolType,formState.exchange])

    const clearInput = () => {
        setFormState({ ...formState, userInput: '' });
    }

    const searchDataFunc = (value) => {
        if(value.length === 0){
            return
        }
        let symbolType = Number(formState.symbolType)
        setIsLoadingSearch(true)
        DataFeedInstance.searchSymbols(value,formState.exchange,symbolType,(res) => {
            const newArray = res.filter(item => {
                item._source.sExchange = item._source.ExchangeSegment
                return item._source.nFOExists !== 0
            });
            setSData(newArray)
            setIsLoadingSearch(false)
        })
    }

    function handleFormStateChange(event)  {
        setShowSearchedData(false)
        const { name, value } = event.target;
        setFormState({ ...formState, [name]: value });

        clearTimeout(typingTimer);
        if (value.length > 0) {
            const timerOut = setTimeout(() =>{
                searchDataFunc(value)
            }, 500);
            setTypingTimer(timerOut)
        }
    }


    const formStateChange = (key,value) => {
        setFormState({ ...formState, [key]: value });
    }

    function formatdate(date){
        let now = moment(date, "YYYYMMDD").format('DD MMM YYYY');
        return now
    }

    const onBackClick =()=>{
        if(current_strategy_state.url=="allStrategies"){
            let state = {id:2,url:'store'}
            dispatch(setCurrentExpiryDate(null))
            dispatch(setExpiryDates(null))
            dispatch(setCurrentStrategy(null))
            dispatch(setStrategyState(state))
        }else{
            let state = {id:2,url:'builder'}
            dispatch(setCurrentExpiryDate(null))
            dispatch(setExpiryDates(null))
            dispatch(setCurrentStrategy(null))
            dispatch(setStrategyState(state))
        }

    }


    const StrategyArray = [
        {Name:"Bull Call Spread",Type:"Bullish",Description:"Used when trader expects limited increase in stock price. Trader buys one call option and sells one call option of same expiry but higher strike price. It caps the gains and limits the losses. ",Image:"BullCallSpread.svg",MoreInfo1:{BuySell:"Buy.svg",Text:"Buy 1 ATM",CePe:"CE"},MoreInfo2:{BuySell:"Sell.svg",Text:"Sell 1 OTM",CePe:"CE"}},
        {Name:"Bull Put Spread",Type:"Bullish",Description:"Trader shortsells a put option and buys another put option of same stock with same expiry but a lower strike price. Suitable for a moderately bearish view and earns an upfront credit. ",Image:"BullPutSpread.svg",MoreInfo1:{BuySell:"Buy.svg",Text:"Buy 1 OTM",CePe:"PE"},MoreInfo2:{BuySell:"Sell.svg",Text:"Sell 1 ITM",CePe:"PE"}},
        {Name:"Ratio Call Spread",Type:"Others",Description:"Employed when the view is bullish, trader buys calls and sells calls with the same expiry at different strike prices in ratio of 1:2, 1:3.or  2:3. Gains unlimited if market goes up and limited if not.",Image:"RatioCallSpread.svg",MoreInfo1:{BuySell:"Buy.svg",Text:"Buy 1 ATM",CePe:"CE"},MoreInfo2:{BuySell:"Sell.svg",Text:"Sell 2 OTM",CePe:"CE"}},
        {Name:"Ratio Put Spread",Type:"Others",Description:"Used when outlook is bearish and for a net credit. Combines long and short puts in ratios of 2:1, 3:2 oe 3:1. Trader profits from volatility in underlying stock.",Image:"RatioPutSpread.svg",MoreInfo1:{BuySell:"Buy.svg",Text:"Buy 1 ATM",CePe:"PE"},MoreInfo2:{BuySell:"Sell.svg",Text:"Sell 2 OTM",CePe:"PE"}},
        {Name:"Bear Call Spread",Type:"Bearish",Description:"Used for a moderately bearish view. Trader buys call options at a strike price and also sells the same number of  call options of the same stock and expiry but at a lower strike price. ",Image:"BearCallSpread.svg",MoreInfo1:{BuySell:"Buy.svg",Text:"Buy 1 OTM",CePe:"CE"},MoreInfo2:{BuySell:"Sell.svg",Text:"Sell 1 ATM",CePe:"CE"}},
        {Name:"Bear Put Spread",Type:"Bearish",Description:"Used for a moderately bearish outlook. Trader buys put options and while also selling same no of puts for same stock and same expiry at a lower strike price.",Image:"BearPutSpread.svg",MoreInfo1:{BuySell:"Buy.svg",Text:"Buy 1 ATM",CePe:"PE"},MoreInfo2:{BuySell:"Sell.svg",Text:"Sell 1 OTM",CePe:"PE"}},
        {Name:"Short Strangle",Type:"Neutral",Description:"Used when a stock trades in a narrow range between the break even points. The trader sells an OTM put and OTM call at the same time.",Image:"ShortStrangle.svg",MoreInfo1:{BuySell:"Sell.svg",Text:"Sell 1 OTM",CePe:"PE"},MoreInfo2:{BuySell:"Sell.svg",Text:"Sell 1 OTM",CePe:"CE"}},
        {Name:"Long Strangle",Type:"Others",Description:"Trader buys an OTM call and OTM put option simultaneously. Profit potential of call option unlimited on the upside and put option profits on the downside. Risk limited to premium paid.",Image:"LongStrangle.svg",MoreInfo1:{BuySell:"Buy.svg",Text:"Buy 1 OTM",CePe:"PE"},MoreInfo2:{BuySell:"Buy.svg",Text:"Buy 1 OTM",CePe:"CE"}},
        {Name:"Short Straddle",Type:"Neutral",Description:"An advance level strategy, employed when underlying stock lacks volatility. Trader sells ATM call option and ATM put option of the same expiry.",Image:"ShortStraddle.svg",MoreInfo1:{BuySell:"Sell.svg",Text:"Sell 1 ATM",CePe:"PE"},MoreInfo2:{BuySell:"Sell.svg",Text:"Sell 1 ATM",CePe:"CE"}},
        {Name:"Long Straddle",Type:"Others",Description:"Trader buys ATM call option and ATM put option of the same expiry. Used to profit from a strong swing due to unexpected event. ",Image:"LongStraddle.svg",MoreInfo1:{BuySell:"Buy.svg",Text:"Buy 1 ATM",CePe:"PE"},MoreInfo2:{BuySell:"Buy.svg",Text:"Buy 1 ATM",CePe:"CE"}},
    ]

    const StrategyArrayMoreCombination = [
        {Name:"Iron Condor",Type:"Neutral",Description:"Used when stock has less volatility. Involves two puts, one long and one short and two calls, one long and one short, four strike prices and same expiry. ",Image:"IronCondor.svg",MoreInfo1:{BuySell:"Buy.svg",Text:"Buy 1 OTM",CePe:"CE"},MoreInfo2:{BuySell:"Sell.svg",Text:"Sell 1 OTM",CePe:"CE"},MoreInfo3:{BuySell:"Buy.svg",Text:"Buy 1 OTM",CePe:"PE"},MoreInfo4:{BuySell:"Sell.svg",Text:"Sell 1 OTM",CePe:"PE"}},
        {Name:"Iron Butterfly",Type:"Neutral",Description:"Used to profit from price movement in narrow range. Trade creates four options - two call and two put options all with same expiry. Designed to benefit from reduction in implied volatility.",Image:"IronButterfly.svg",MoreInfo1:{BuySell:"Buy.svg",Text:"Buy 1 OTM",CePe:"CE"},MoreInfo2:{BuySell:"Buy.svg",Text:"Buy 1 OTM",CePe:"PE"},MoreInfo3:{BuySell:"Sell.svg",Text:"Sell 1 ATM",CePe:"CE"},MoreInfo4:{BuySell:"Sell.svg",Text:"Sell 1 ATM",CePe:"PE"}},

    ]

        //This func will set the name of selected stock in input box and it will push the stock in localstorage
    const searchNameChange = (value)=>{
            setFormState({ ...formState, userInput: value.sSymbol });
            dispatch(setCurrentExpiryDate(null))
            setSymbolDetails(value)
            setShowSearchedData(true)
            setNeedToUpdate(true)
            const recentdata = JSON.parse(localStorage.getItem('recentSearches'));
            let data = {
                "symbol":value.sSymbol,
                "exchange":value.sExchange,
                "sSecurityDesc":value.sSecurityDesc,
                "token":value.nToken,
                "nMarketSegmentId":value.nMarketSegmentId
            }
            if(!recentdata.some(obj => obj.symbol === data.symbol)) {
                recentdata.push(data);
              }
              if(recentdata.length>3){
                localStorage.setItem('recentSearches', JSON.stringify(recentdata.slice(-3)));
              }else{
                localStorage.setItem('recentSearches', JSON.stringify(recentdata));
              }
            dispatch(setRecentSearches(recentdata))
            dispatch(setCurrentSymbolDetails(value))
    
        }


    //Get the expirydates from option chain api
    function getExpiryDates(){
        setIsLoading(true)
        const data = {symbol: symbolDetails.sSymbol,exchange: symbolDetails.sExchange, token: symbolDetails.nToken}
        authService.newOptionChaindata(data,user.access_token).then(value => {
            return value.data
        }).then((data)=>{
            if(data.status==="success"){
                setDates(data.response.expiry_dates)
                if(needToUpdate){
                    dispatch(setCurrentExpiryDate(data.response.expiry_dates[0]))
                    dispatch(setExpiryDates(data.response.expiry_dates))
                }
                if(current_expiry_date==null){
                    dispatch(setCurrentExpiryDate(data.response.expiry_dates[0]))
                    dispatch(setExpiryDates(data.response.expiry_dates))
                }
            }else if(data.status==="error"){
                onBackClick()
            }
        }).catch(reason => {
            console.log("Error in option data",reason)
            onBackClick()
        })

    }

    function getStrategies(expiryDate){
        setIsLoading(true)
        const data = {symbol: symbolDetails.sSymbol,token: symbolDetails.nToken,expiry_date:expiryDate.toString(),exchange: symbolDetails.sExchange}
            flowBackEndServices2.getStrategies(data,user.access_token).then(value => {
                return value.data
            }).then((data)=>{
                setAllStrategies(data.data.strategies)
                setIsLoading(false)
                dispatch(setCurrentStrategy(data.data.strategies))
            }).catch(reason => {
                console.log("Error in strategies data",reason);
                setIsLoading(false)
                dispatch(setCurrentStrategy(null))
                onBackClick()
            })
    }


    const handleButtonClick=(event)=>{
        const buttons = document.querySelectorAll('.expDateButton');

        buttons.forEach((button) => {
            button.classList.remove('active');
          });
        const element = document.getElementById(event.currentTarget.id);
        element.classList.add('active');
        dispatch(setCurrentExpiryDate(event.currentTarget.id))
        dispatch(setCurrentStrategy(null))
    }

    function returnExpiryButtons(){
       if(expiryDates){
        return expiryDates.map(d=>{
            return(
                <div onClick={handleButtonClick} className="text-nowrap expDateButton" key={d} id={d}>{formatdate(d)}</div>
                )
        })
       }else{
        return(
            <div></div>
        )
       }
    }

    const openStrategyDetails=(event)=>{
        dispatch(setStrategyNameDetails(event.currentTarget.id))
        let state = {id:4,url:'strategyDetails'}
        dispatch(setStrategyState(state))
    }


    function return2CombinationStrategiesBlocks(){

        return StrategyArray.map(d=>{
            return(
                <div key={d.Name} className="col-6 mb-4 " style={{"marginLeft":"-12px","position":"relative"}}>
                    <div id={d.Name} className="flex strategy datarow" onClick={openStrategyDetails}>
                        <div className="flex-container">
                            <span className="pt-3"><img src={require(`../../../assets/icons/${d.Image}`)} alt="strategy"/></span>
                            <div className="pt-3 pl-2">
                                <p className="headerText" style={{"display":"inline","width":"150px"}}>{d.Name}</p>
                                <div style={{"display":"inline"}} className='tooltip2'><span className='c-pointer' style={{"display":"inline","position":"relative","top":"-3px","right":"-4px"}}>{<InfoIcon />}</span>
                                    <div className="tooltiptext2 tipvalue">{d.Description}</div>
                                </div>
                                <div className="flex-container">
                                    <img src={require(`../../../assets/icons/${d.MoreInfo1.BuySell}`)} alt=""></img>
                                    <span className="px-1 py-1 ATMOTM">{d.MoreInfo1.Text}</span>
                                    <span className="cepe my-1">{d.MoreInfo1.CePe}</span>
                                </div>
                                <div className="flex-container">
                                    <img src={require(`../../../assets/icons/${d.MoreInfo2.BuySell}`)} alt=""></img>
                                    <span className="px-1 ATMOTM">{d.MoreInfo2.Text}</span>
                                    <span className="cepe">{d.MoreInfo2.CePe}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {d.Type=="Bullish" && (<div className="strategyType" style={{"color":"#26a69a","background": "#E3FEEF"}}><span ><i className="fa-solid fa-caret-up"></i>&nbsp;{d.Type}</span></div>)}
                    {d.Type=="Bearish" && (<div className="strategyType" style={{"color":"#FE4141","background": "#FFEDED"}}><span ><i className="fa-solid fa-caret-down"></i>&nbsp;{d.Type}</span></div>)}
                    {d.Type=="Neutral" && (<div className="strategyType" style={{"color":"#064AD3","background": "#DDE8FF"}}><span ><Neutral/>&nbsp;{d.Type}</span></div>)}
                    {d.Type=="Others" && (<div className="strategyType" style={{"color":"#ff8800","background": "#FFF4EB"}}><span >{d.Type}</span></div>)}
                </div>
                )
        })
    }

    function return4CombinationStrategiesBlocks(){

        return StrategyArrayMoreCombination.map(d=>{
            return(
                <div key={d.Name} className="col-6 mb-4 " style={{"marginLeft":"-12px","position":"relative"}}>
                        <div id={d.Name} className="flex strategy datarow" onClick={openStrategyDetails}>
                            <div className="flex-container">
                                    <span className="pt-3"><img src={require(`../../../assets/icons/${d.Image}`)} alt="strategy"/></span>
                                    <div className="pt-3 pl-2">
                                    <p className="headerText" style={{"display":"inline","width":"150px"}}>{d.Name}</p>
                                    <div style={{"display":"inline"}} className='tooltip2'><span className='c-pointer' style={{"display":"inline","position":"relative","top":"-3px","right":"-4px"}}>{<InfoIcon />}</span>
                                        <div className="tooltiptext2 tipvalue">{d.Description}</div>
                                    </div>
                                    <div className="flex-container border-right">
                                        <img src={require(`../../../assets/icons/${d.MoreInfo1.BuySell}`)} alt=""></img>
                                        <span className="px-1 py-1 ATMOTM">{d.MoreInfo1.Text}</span>
                                        <span className="cepe my-1">{d.MoreInfo1.CePe}</span>
                                     </div>
                                     <div className="flex-container border-right">
                                        <img src={require(`../../../assets/icons/${d.MoreInfo2.BuySell}`)} alt=""></img>
                                        <span className="px-1 ATMOTM">{d.MoreInfo2.Text}</span>
                                        <span className="cepe">{d.MoreInfo2.CePe}</span>
                                     </div>
                                     <div className="flex-container" style={{"position":"relative","bottom":"45px","left":"130px"}}>
                                        <img src={require(`../../../assets/icons/${d.MoreInfo3.BuySell}`)} alt=""></img>
                                        <span className="px-1 py-1 ATMOTM">{d.MoreInfo3.Text}</span>
                                        <span className="cepe my-1">{d.MoreInfo3.CePe}</span>
                                     </div>
                                     <div className="flex-container" style={{"position":"relative","bottom":"45px","left":"130px"}}>
                                        <img src={require(`../../../assets/icons/${d.MoreInfo4.BuySell}`)} alt=""></img>
                                        <span className="px-1 ATMOTM">{d.MoreInfo4.Text}</span>
                                        <span className="cepe">{d.MoreInfo4.CePe}</span>
                                     </div>
                                </div>
                            </div>
                        </div>
                        {d.Type=="Bullish" && (<div className="strategyType" style={{"color":"#26a69a","background": "#E3FEEF"}}><span ><i className="fa-solid fa-caret-up"></i>&nbsp;{d.Type}</span></div>)}
                        {d.Type=="Bearish" && (<div className="strategyType" style={{"color":"#FE4141","background": "#FFEDED"}}><span ><i className="fa-solid fa-caret-down"></i>&nbsp;{d.Type}</span></div>)}
                        {d.Type=="Neutral" && (<div className="strategyType" style={{"color":"#064AD3","background": "#DDE8FF"}}><span ><Neutral/>&nbsp;{d.Type}</span></div>)}
                        {d.Type=="Others" && (<div className="strategyType" style={{"color":"#ff8800","background": "#FFF4EB"}}><span >{d.Type}</span></div>)}
                </div>
            )
        })
    }

    const changePredictiontype=(event)=>{
        setPredictedInputValueFirst(defaultInputFirst>0?defaultInputFirst:null)
        setPredictedInputValueSecond(defaultInputSecond>0?defaultInputSecond:null)
        const buttons = document.querySelectorAll('.predictType');

        buttons.forEach((button) => {
            button.classList.remove('active');
          });
        const element = document.getElementById(event.currentTarget.id);
        element.classList.add('active');
        setPredictionType(event.currentTarget.id)
    }

    const getFormattedValue = (value) =>{
        const formattedValue = value.replace(/[^0-9.]/g, '');
        const decimalIndex = formattedValue.indexOf('.');
        
        if (decimalIndex !== -1) {
          const decimalPlaces = formattedValue.slice(decimalIndex + 1);
          if (decimalPlaces.length > 2) {
            // Limit to two decimal places
            return formattedValue.slice(0, decimalIndex + 3);
          }
        }

        return formattedValue
    }

    const handleInputChangeAB=(event)=>{
        const val = getFormattedValue(event.target.value)
        setDefaultInputFirst(val)
        setPredictedInputValueFirst(val)
    }
    const handleInputChangeBetweenFirst=(event)=>{
        const val = getFormattedValue(event.target.value)
        setDefaultInputFirst(val)
        setPredictedInputValueFirst(val)
    }
    const handleInputChangeBetweenSecond=(event)=>{
        const val = getFormattedValue(event.target.value)
        setDefaultInputSecond(val)
        setPredictedInputValueSecond(val)
    }

    const setPredictedValueAB = ()=>{
        return(
            <div style={{"marginTop":"9px"}}>
                <input className="predictedValue" style={{"width":"96%"}} onChange={handleInputChangeAB} type="number" value={defaultInputFirst}/>
            </div>
        )
    }

    const setPredictedValueBetween=()=>{
        return(
            <div style={{"marginTop":"9px"}}>
            <input placeholder="lower value" className="predictedValue mr-3" style={{"width":"46%"}} onChange={handleInputChangeBetweenFirst} type="number" value={defaultInputFirst}/>
            <input placeholder="higher value" className="predictedValue" style={{"width":"46%"}} onChange={handleInputChangeBetweenSecond} type="number" value={defaultInputSecond}/>
        </div>
        )
    }

    const openBuilderPage=()=>{
        let arr = []

        if(predictionType=="between"){
            arr.push(Number(predictedInputValueFirst))
            arr.push(Number(predictedInputValueSecond))
        }else{
            arr.push(Number(predictedInputValueFirst))
        }

        let data={
            "token": current_symbol_details.nToken,
            "symbol": current_symbol_details.sSymbol,
            "expiry_date": current_expiry_date.toString(),
            "prediction":predictionType.toUpperCase(),
            "stockType":"NFO",
            "price_range":arr
        }
        let state = {id:7,url:"builderStrategyDetails"}  
        dispatch(setPredictRange(arr))
        dispatch(setBuilderInput(data))
        dispatch(setStrategyState(state))

    }

    const getFooter = () =>{
        return(
            <div style={{"position":"relative"}}>
                <div  className="builderContinue c-pointer" onClick={openBuilderPage}>Continue</div>
            </div>
        )
    }

    const getLtp=(value)=>{
        setPredictedInputValueFirst(value)
        setPredictedInputValueSecond(value)
        setDefaultInputFirst(value)
        setDefaultInputSecond(value)

    }


    return (
        <React.Fragment>
        <OptionStrategyHeader>
            <div className='flex headerText'> <i onClick={onBackClick} className="fa fa-arrow-left c-pointer" aria-hidden="true"/>&nbsp;<span className=' px-2'>{current_strategy_state.url=="allStrategies"?'Strategy Store':"Strategy Builder"}</span></div>
        </OptionStrategyHeader>
        {(<div className='container'>
            <div className='row'>
                <div className='col-12'>
                    <Form  className="w-100 pt-3 px-5 ">

                    <Form.Group className="position-relative" controlId="formBasicEmail">

                    {formState.userInput?.length === 0 && (
                        <div className="mysearch c-pointer"><SearchIcon/></div>
                    )}
                    {formState.userInput?.length > 0 && (
                        <React.Fragment>
                            <div className="myclear"> <button onClick={clearInput} className="btn clearbtn">Clear</button> </div>
                            {showSearchedData?null:<ScripSearchData data={sData} isLoading={isLoadingSearch} formState={formState} formStateChange={formStateChange} searchNameChange={searchNameChange}/>}
                        </React.Fragment>
                        )}
                    <Form.Control type="text"
                                name="userInput"
                                required
                                autoComplete="off"
                                value={formState.userInput}
                                onChange={handleFormStateChange}
                                placeholder="Select Index, Stocks" 
                                className="custom-placeholder"/>
                    <Form.Control.Feedback type='invalid' className="d-flex flex-row">

                    </Form.Control.Feedback>
                </Form.Group>

            </Form>
            </div>
        </div>
        </div>)}

        {(<div className="container">
            <div className="row">
                <div className='col-12' style={{"textAlign":"left"}}>
                    <div className='headerText' style={{"paddingLeft":"26px","paddingTop":"10px"}}>Select Expiry</div>
                </div>
            </div>
        </div>)}
        {(<div className="button-container">
            {returnExpiryButtons()}
        </div>)}
        {current_strategy_state.url=="allStrategies" && (
        <div className="container" style={{"height":"270px","overflowY":"scroll"}}>
            <div className="row" style={{"paddingLeft":"37px"}}>
                    {return2CombinationStrategiesBlocks()}
                    {return4CombinationStrategiesBlocks()}
            </div>
        </div>
        )}
                { current_strategy_state.url=="allBuilder" && (
                            <div className="container" style={{"height":"270px","overflowY":"scroll"}}>
                            <div className="row" style={{"paddingLeft":"37px"}}>
                                    <div className="strategyBuilder p-0 mt-4">
                                        <div className="builderHead">
                                            <div style={{"paddingTop":"19px","paddingLeft":"14px"}}><span className="builderSymbol">{current_symbol_details.sSymbol}</span><span className='exc mx-2' style={{"top":"38px"}}>{current_symbol_details.sExchange}</span></div>
                                            <div>
                                                <HeaderSubscribedData token={current_symbol_details.nToken} segmentId={current_symbol_details.nMarketSegmentId} symbol={current_symbol_details.sSymbol} description={current_symbol_details.sSecurityDesc!=""?current_symbol_details.sSecurityDesc:current_symbol_details.sSymbol+" Index"} exchange={current_symbol_details.sExchange} location={"strategy"} getLtp={getLtp} callGetLtp={true}/>
                                            </div>
                                        </div>
                                        <div className="container p-0 predictionStart">
                                            <div className="row">
                                                <div className="col-6" style={{"textAlign":"left","paddingLeft":"24px","paddingTop":"7px"}}>
                                                    <div className="predictHead">Select prediction</div>
                                                    <div style={{"display":"flex","flexDirection":"row","justifyContent":"space-between","marginTop":"9px"}}>
                                                        <div className="predictType c-pointer" id="above" onClick={changePredictiontype}>Above</div>
                                                        <div className="predictType c-pointer" id="between" onClick={changePredictiontype}>Between</div>
                                                        <div className="predictType c-pointer" id="below" onClick={changePredictiontype}>Below</div>
                                                    </div>
                                                </div>
                                                <div className="col-6" style={{"textAlign":"left","paddingLeft":"24px","paddingTop":"7px"}}>
                                                    <div className="predictHead">Enter predicted value</div>
                                                    {predictionType!="between" && setPredictedValueAB()}
                                                    {predictionType=="between" && setPredictedValueBetween()}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {getFooter()}
                            </div>
                        </div>
        )}
        </React.Fragment>
    )
    }   


export default RenderOptionStrategiesList