import React, {useEffect, useState} from 'react';
import Background from "../BackGround/background";
import './mpin.css'
import OtpInput from "react-otp-input";
import Flowlogo from '../../assets/images/Icon.svg'
import MadeInLoveIndia from "../common/madeInLoveIndia";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import {useLocation} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import authService from "../../services/authService";
import {toast} from "react-toastify";
import {Link, useNavigate} from "react-router-dom";
import {loginEvent, switchUserEvent} from '../../slices/authSlice';
import {getBrowserDetails} from "../../comman/status";
import flowBackEndServices2 from "../../services/flowBackendServices";
import refresh from "../../assets/images/refresh-circle.svg";
import Timer from "../common/timer";

function MpinInput(props) {
    const { state } = useLocation();

    const navigate = useNavigate();
    const { user,data } = useSelector((state) => state.authUser);


    const [Otp, setOtp] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingResed, setIsLoadingResed] = useState(false);
    const [showResetBtn, setShowResetBtn] = useState(false);

    const dispatch = useDispatch();


    const [otpType, setOtpType] = useState('otp');

    useEffect(() => {
        forceLogout()
        console.log("mpininput ", data)
        if(data) setOtpType(data.mfaType)
        if(data && data.mfaType === 'otp') loginWithOTP(false)
    },[data])

    function forceLogout() {
        if(data === null || data.refreshToken === null || data.refreshToken === undefined){
            handleLogout()
        }
    }

    const showResend = () => {
        setShowResetBtn(!showResetBtn)
    }

    // NOT USING
    function callMpinSetApi(Otp) {
        const dataPost = {
            user_id: user.user_id,
            login_type: "MPIN",
            password: Otp,
            second_auth: "",
            UDID:"",
        }

        setIsLoading(true);

        function setSessionAndNavigate(userData) {
            const mpin = userData.mpin_enabled;
            authService.saveUserSession(userData,mpin,dispatch)
            let path = state?.type ?? '/MPin'
            if (path === '/MPin') path = '/tvTerminal'
            navigate(path);
        }

        authService.MPinLogin(dataPost)
            .then(value => {
                if(value.data.status === "success"){
                    toast.success(value.data.message)
                    setSessionAndNavigate(value.data.data)
                    dispatch(loginEvent())
                }
            })
            .catch(error => {
                toast.error(`${error.response.data.message}`)
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            })

    }

    function loginWithOTP(reset) {
        setIsLoadingResed(true)
        const {deviceId,refreshToken} = data;
        let mfaType = otpType;
        let sendData = {deviceId,mfaType}
        flowBackEndServices2.refreshLoginOtpSend(sendData,refreshToken).then(value => {
            if(value?.status === 200){
                setOtp('')
                setOtpType('otp')
                if(reset === true) showResend()
                toast.success(`OTP sent`)
            }else {
                if(value.data.status === 'error' && value.data.message === 'session has expired'){
                    handleLogout()
                }
            }
        }).catch(reason => {
            toast.error(`Failed`)
            setIsLoadingResed(false)
        }).finally(() =>  setIsLoadingResed(false))
    }

    function resendPassword() {
        loginWithOTP(true)
    }

    function refreshLogin(otp_param) {
        const {deviceId, refreshToken} = data;
        let mfaType = otpType;

        let browswe = getBrowserDetails()
        let deviceInfo = `${browswe?.browserName}`;
        let appVersion = `${browswe?.fullVersion}`;
        let os = `${browswe?.OSName}`;


        let dataSend = {otp: `${Otp}`, deviceId: deviceId,mfaType,deviceInfo,appVersion,os}
        setIsLoading(true)
        flowBackEndServices2.refreshLogin(dataSend, refreshToken).then(value => {
            if(value?.status === 200) {
                if(value.data.status === 'success'){
                    if(data.mfaType === 'totp') {
                        mfaType = 'totp'
                    }
                    flowBackEndServices2.setUpSessionData(value,mfaType,dispatch,navigate,data)
                }else {
                    toast.error(`${value.data.message}`)
                    if(value.data.status === 'error' && value.data.message === 'session has expired'){
                        handleLogout()
                    }
                }
            }
        }).catch(reason => {
            console.log(reason)
            toast.error(`invalid OTP`)
            setIsLoading(false)
        }).finally(() =>  setIsLoading(false))
    }

    function handleOnClick(event) {
        event.preventDefault();
        if(Otp.length === 6) refreshLogin(Otp)
    }

    useEffect(() => {
        if(Otp?.length === 6) refreshLogin(Otp)
    }, [Otp])

    useEffect(() => {
        listner()
    }, [])



    const handleLogout = () => {
        dispatch(switchUserEvent())
        dispatch(authService.logout())
        navigate("/",{ replace: true});
    }

    function listner() {

        var input = document.getElementById("myInput");

        if(input !== null) {
            input.addEventListener("keypress", function (event) {
                if (event.key === "Enter") {
                    event.preventDefault();
                    if (Otp.length === 6){
                        document.getElementById("myBtn").click();
                    }
                }
            });
        }
    }


    function getSortName(user_name) {
        let name = ''
        if(user_name){
            name = user_name.split(' ')[0]
        }
        return name;
    }

    function switchToTotp() {
        setOtp('')
        setOtpType('totp')
    }

    return (
        <Background>
            <div className="h-100 d-flex card_padding flex-column justify-content-between align-items-center">

                <div className="w-100 d-flex flex-row justify-content-between align-items-center">
                    <div className="flow"><img width={40} height={40} src={'https://static.rupeezy.in/branding/blue-for-light-background.svg'} alt={''}/>Rupeezy</div>
                    <div onClick={handleLogout} className="colorBlue c-pointer">Switch user</div>
                </div>

              <div className="flex-fill w-100">


                <div className="d-flex w-100 flex-column justify-content-between align-items-center">
                <div className="circle mt-2">{user.user_name.charAt(0)}</div>
                <div className="heading1">{getSortName(user.user_name)}</div>
                <div className="colorGrey text1">{user.user_id}</div>


                </div>


                <div className="w-100 d-flex flex-row justify-content-between align-items-center">
                    <div className="colorBlack">Enter {otpType?.toUpperCase()}</div>
                    {(otpType === 'otp' && data?.mfaType === 'totp') &&  (
                        <div onClick={switchToTotp} className="colorBlue c-pointer">Switch to TOTP</div>
                    )}
                </div>

                  <div id="myInput" className="w-100 mt-2 d-flex flex-row justify-content-center align-items-center">
                      <OtpInput
                          inputStyle="opt_box"
                          value={Otp}
                          onChange={setOtp}
                          numInputs={6}
                          isInputNum={true}
                          isInputSecure={false}
                          separator={<span/>}
                      />
                  </div>




     </div>

                <div className="w-100">

                    {otpType === 'otp' && (
                        <div className="colorBlue align-self-start py-1">

                            {showResetBtn ? <div className="d-flex flex-row">
                                    {isLoadingResed ? (
                                        <Spinner className="mr-2" as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                    ) : <span className='4' onClick={resendPassword}><img src={refresh} alt={''}/>Resend OTP</span> }
                                </div>
                                : <div className="d-flex flex-row justify-content-start align-items-center">
                                    <div className="colorGrey">OTP can be resent in:</div>
                                    <Timer initialSeconds={59} initialMinute={0} showResend={showResend} />
                                </div> }

                        </div>

                    )}

                    { otpType === 'totp' && (
                         <div>
                             {isLoadingResed ? (
                                 <Spinner className="mr-2" as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                             ):(
                                 <div onClick={loginWithOTP} className="heading2 c-pointer py-1 colorBlue">Login with OTP <span className="colorGrey">instead?</span></div>
                             )}
                         </div>
                    )}

                <Button id="myBtn" disabled={isLoading || Otp.length !== 4} className="blue_btn w-100 my-3" onClick={handleOnClick} variant="primary" type="submit">
                    {isLoading && (
                        <Spinner className="mr-2" as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                    )}
                    Continue
                </Button>

                <MadeInLoveIndia/>
                </div>


            </div>



        </Background>
    );
}

export default MpinInput;
