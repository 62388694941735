import React, {useEffect, useState} from 'react';
import Background from "../BackGround/background";
import mobileImage from "../../assets/images/mobile.svg";
import sendImage from "../../assets/images/send-2.svg";
import pancardImage from "../../assets/images/personalcard.svg";
import MadeInLoveIndia from "../common/madeInLoveIndia";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import {useLocation} from "react-router";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import Form from "react-bootstrap/Form";
import authService from "../../services/authService";
import {toast} from "react-toastify";
import flowBackEndServices2 from "../../services/flowBackendServices";

const Emailregex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
const PanRegex = new RegExp('[A-Z]{5}[0-9]{4}[A-Z]{1}');

function UserIdRecovery(props) {
    const { state } = useLocation();

    const [formState, setFormState] = useState({dynamicValue: ''});
    const [ errors, setErrors ] = useState({})
    const [isLoading, setIsLoading] = useState(false);
    const [ selectedOption, setSelectedOption ] = useState({})


    const dispatch = useDispatch();
    const navigate = useNavigate();

    const ObjectType = [
        {
            'key': 'phone',
            'heading': 'Recover Using Phone Number',
            'second_heading': 'Input your mobile no. registered with us. Your User ID will be sent to your registered email id.',
             'form_value': 'Phone Number'
        },
        {
            'key': 'email',
            'heading': 'Recover Using Email ID',
            'second_heading': 'Input your Email Id registered with us. Your User ID will be sent to your registered email id.',
            'form_value': 'Email'
        },
        {
            'key': 'pan',
            'heading': 'Recover Using Pan Number',
            'second_heading': 'Input your PAN registered with us. Your User ID will be sent to your registered email id.',
            'form_value': 'Pan Number'
        }

    ]
    function setOption() {
      let data = ObjectType.find(value => value.key === state.type)
      setSelectedOption(data)
    }

    const STATUSES = Object.freeze({
        EMAIL: 'email',
        PHONE: 'phone',
        PAN: 'pan',
    });


    useEffect(() => {
        if(state === null) navigate("/ForgetUserID")
        setOption()
    },[])


    const findFormErrors = () => {
        const { dynamicValue } = formState
        const newErrors = {}

        if(selectedOption.key === STATUSES.PHONE){
            if ( dynamicValue.length !== 10 ) newErrors.dynamicValue = 'Enter valid 10 digit mobile number!'
        }

        if(selectedOption.key === STATUSES.EMAIL){
            if ( !dynamicValue || dynamicValue === '' ) newErrors.dynamicValue = 'Email id is required!'
            else if ( !Emailregex.test(dynamicValue) ) newErrors.dynamicValue = 'Enter valid email address!'
        }

        if(selectedOption.key === STATUSES.PAN){
            if ( !dynamicValue || dynamicValue === '' ) newErrors.dynamicValue = 'Pan id is required!'
            else if ( !PanRegex.test(dynamicValue) ) newErrors.dynamicValue = 'Enter valid Pan number!'
        }


        return newErrors
    }



    function handleFormStateChange(event) {
        const { name, value } = event.target;

        if(selectedOption.key === STATUSES.PAN){
            setFormState({ ...formState, [name]: value.toUpperCase() });
        }else {
            setFormState({ ...formState, [name]: value });
        }

        if ( !!errors[name] ) setErrors({
            ...errors,
            [name]: null
        })
    }

    const onlyNumber = (event) => {
        if(selectedOption.key === 'phone'){
            if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
            }
        }
    }


    function calluserIDSend(inputValue) {
        var mydata = {};
        if(selectedOption.key === STATUSES.PHONE) mydata['phoneNumber'] = inputValue
        if(selectedOption.key === STATUSES.EMAIL) mydata['email'] = inputValue
        if(selectedOption.key === STATUSES.PAN) mydata['panNumber'] = inputValue

        setIsLoading(true)
        flowBackEndServices2.SendUserId(mydata)
            .then(value => {
                if(value.data.status === "success"){
                    toast.success(value.data.message)
                    navigate("/",{ replace: true});
                }
            })
            .catch(error => {
                toast.error(`${error.response.data.message}`)
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    function handleOnClick(event) {
        event.preventDefault();

        // get our new errors
        const newErrors = findFormErrors()
        // Conditional logic:
        if ( Object.keys(newErrors).length > 0 ) {
            setErrors(newErrors)
        } else {
           calluserIDSend(formState.dynamicValue)
        }

    }
    return (
        <Background>

            <div className="h-100 p-lg-5 p-5 d-flex flex-column justify-content-between " >

                <div className="">

                    <div className="py-2">
                        <Link to="/ForgetUserID"><i className="fas fa-arrow-left fas fa-lg"/></Link>
                    </div>

                    <h3 className="heading1 py-1">{selectedOption.heading}</h3>

                    <div className="colorGrey pt-0">{selectedOption.second_heading}</div>


                </div>


                <div className="align-self-center flex-fill w-100">

                    <Form className="pt-3 w-100">
                        <Form.Group className="mb-2" controlId="formBasicEmail">
                            <Form.Label className="text1 w-100">
                                <div className="d-flex flex-row justify-content-between align-items-center">
                                    <div className="">{selectedOption.form_value}</div>
                                    <div className="">&nbsp;</div>
                                </div>
                            </Form.Label>
                            <Form.Control type="text"
                                          name="dynamicValue"
                                          required
                                          onKeyPress={onlyNumber}
                                          isInvalid={ !!errors.dynamicValue }
                                          value={formState.dynamicValue}
                                          onChange={handleFormStateChange}
                                          placeholder={`Enter Your ${selectedOption.form_value}`} />
                            <Form.Control.Feedback type='invalid'>
                                { errors.dynamicValue }
                            </Form.Control.Feedback>
                        </Form.Group>

                    </Form>


                </div>


                <div className="">

                    <Button disabled={isLoading} className="blue_btn w-100 my-3" onClick={handleOnClick} variant="primary" type="submit">
                        {isLoading && (
                            <Spinner className="mr-2" as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                        )}
                        Recover user ID
                    </Button>


                    <MadeInLoveIndia/>
                </div>



            </div>

        </Background>
    );
}

export default UserIdRecovery;
