import React, {forwardRef, useImperativeHandle, useRef} from "react";
import frameIcon from "../../../assets/icons/Frame.svg";
import ReactDragListView from "react-drag-listview";
import nextId from "react-id-generator";
import EachStock from "../eachStock/eachStock";
import { useSelector} from "react-redux";
import {collectionArray} from "./basketDetails";

const AllBasketOrderRef = forwardRef(({basketOrders,data,masterOrder,isExecuted,updateBasketOrderCallBack}, ref) => {

    let dragProps = {
        onDragEnd(fromIndex, toIndex) {
            const data = [...basketOrders];
            const item = data.splice(fromIndex, 1)[0];
            data.splice(toIndex, 0, item);
            updateBasketOrderCallBack(data)
        },
        handleSelector: ".grab",
        nodeSelector:'.each_box',
        enableScroll:true,
    };
    const  listDataMain  = useSelector((state) => state.basketOrder.listDataMain);


    const childRefEachStock = useRef();

    function setSingleExcData() {
        let orders =  listDataMain.find(d => d.id === data.id)
        collectionArray.setBasketWhenExce = orders.basketOrders
    }



    useImperativeHandle(ref, () => ({

        callChildMethod() {
            closeLodding()
        }

    }));


    function closeLodding() {
        childRefEachStock.current.closeLodding();
    }

    if(basketOrders.length === 0){
        return (<div className="d-flex flex-column justify-content-start align-items-center">
            <div className=""><img src={frameIcon} alt={''}/></div>
            <div className="heading2 mt-5">No Stocks Found</div>
            <div className="text1 colorGrey currentColor">Search and Add upto 10 stocks in this basket</div>
        </div>);
    }

    return (

        <div className="d-flex flex-column justify-content-start align-items-start m-4">
            <div className="d-flex scroll_box2 my_scroll_track flex-column align-items-stretch w-100 justify-content-between">
                <ReactDragListView   {...dragProps}>
                    <table className="baskTable">
                        <thead className="card_bg">
                        <tr className="currentText">
                            <th>&nbsp;</th>
                            <th>Name</th>
                            <th>LTP</th>
                            <th>Price</th>
                            <th>Qty.</th>
                            <th>Status</th>
                        </tr>
                        </thead>

                        <tbody>
                        {
                            basketOrders.map((item,index) => {
                                const id1 = nextId("BE-");
                                return(
                                    <EachStock ref={childRefEachStock} key={id1} data={item} index={index} masterOrder={masterOrder} isExecuted={isExecuted} setSingleExcData={setSingleExcData}/>
                                )
                            })
                        }
                        </tbody>

                    </table>
                </ReactDragListView>
            </div>
        </div>
    )
});



const AllBasketOrder = React.memo(AllBasketOrderRef);


export default AllBasketOrder;
