import { configureStore } from '@reduxjs/toolkit';
import authReducer, {initialCheckData} from "./slices/authSlice";
import optionChainReducer from "./slices/optionChainSlice";
import basketOrderReducer from "./slices/basketOrderSlice";
import optionStrategyReducer from "./slices/optionStrategySlice";

const store = configureStore({
  reducer: {
    authUser: authReducer,
    optionChain: optionChainReducer,
    basketOrder: basketOrderReducer,
    optionStrategy: optionStrategyReducer,
  },
});

store.dispatch(initialCheckData())

export default store;
