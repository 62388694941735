import React, {useEffect, useState} from "react";
import {SpotService} from "../../sub";


const SpotPrice = ({stockarray,optionChainData,darkLightClass})=>{
    const [ dynamic_spot, setDynamic_spot ] = useState(null)
    const [livePosition,setLivePosition] = useState(null)
    const [strikeDifference,setStrikeDifference] = useState(null)
    const [targetIndex,setTargetIndex]=useState(null)
    const [Safari,setSafari] = useState(false)

    let subscription = undefined;
    useEffect(() => { 
        subscription = SpotService.getSpot().subscribe(value => {
            setDynamic_spot(value)
        });
        return () => {
            subscription?.unsubscribe(); 
        }
    }, [])

    useEffect(()=>{
        appendDynamicSpotPrice()
        // if(dynamic_spot) executeToScroll()
    },[dynamic_spot])

    useEffect(()=>{
        isSafari()
    },[])

    useEffect(()=>{
            placeInitially()
    },[dynamic_spot])

    useEffect(()=>{
        if(livePosition) scrollToSpotPrice()
    },[livePosition])

    function isSafari() {
        const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
        setSafari(isSafari)
    }

    function appendDynamicSpotPrice(){
            if(dynamic_spot){
                let strikePrice = (Math.floor(dynamic_spot.lp/strikeDifference)+1)*strikeDifference
                setLivePosition(strikePrice)
                let dynamic_spot_element = document.getElementById("spot_price_live")
                let targetRow = document.getElementById(strikePrice)
                let table = document.getElementById("mainbody")
        
                if (dynamic_spot_element && targetRow){
                    table.insertBefore(dynamic_spot_element,targetRow);
                }
            }
    }
    function placeInitially(){
        if(targetIndex===null){
            for (let i = 0; i < stockarray.length; i++){
                if(stockarray[i]-dynamic_spot?.lp>0){
                setTargetIndex(i)
                break;
            }  
          }
        }
        let strikePrice = stockarray[targetIndex]
        let strike_diff = parseFloat(stockarray[parseFloat(targetIndex)+1])-parseFloat(stockarray[targetIndex])

        setStrikeDifference(strike_diff)
        setLivePosition(strikePrice)
    }

    function executeToScroll(){
        if(initialIndex && initialIndex!=0 && dynamic_spot.lp){
            let start = initialIndex
            while(true){
                if(dynamic_spot.lp<stockarray[start] && dynamic_spot.lp>stockarray[start-1]){
                    setLivePosition(stockarray[start])
                    break;
                }else if(dynamic_spot.lp>stockarray[start]){
                    start = parseInt(initialIndex)+1
                }else if(dynamic_spot.lp<stockarray[start-1]){
                    start = initialIndex-1
                }
            }
        }
    }

    const scrollToSpotPrice = ()=>{ 
        setTimeout(() => {
            let targetRow = document.getElementById("spot_price_live")
            if(targetRow){
                targetRow.scrollIntoView({behavior:"smooth",block: 'nearest', inline: 'center'});
            }
        },1000)
       } 

    if(dynamic_spot==null){
        return(
                <tr id="spot_price_live">
                    <td className='p-0' ><div className={`${Safari?'safari_line':'line'}`}/></td>
                    <td className='p-0' colSpan={2}><div className={`${Safari?'safari_strike_price_live':'strike_price_live'}`}>Spot Price</div></td>
                    <td className='p-0' ><div className={`${Safari?'safari_line':'line'}`}/></td>
                </tr>    
        )
    }else{
        return (
                <tr id="spot_price_live" className={`${darkLightClass}`}>
                    <td className='p-0' ><div className={`${Safari?'safari_line':'line'}`}/></td>
                    <td className='p-0' colSpan={2}><div className={`${Safari?'safari_strike_price_live':'strike_price_live'} ${darkLightClass}`}>Spot Price {dynamic_spot?dynamic_spot.lp:''}  ({dynamic_spot?dynamic_spot.chp=='Infinity'?0:dynamic_spot.chp.toFixed(2):0}%) </div></td>
                    <td className='p-0' ><div className={`${Safari?'safari_line':'line'}`}/></td>
                </tr>
        )
    }
}

export default SpotPrice 