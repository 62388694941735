import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AuthService from "../services/authService";
import {decodeBase64, STATUSES} from "../comman/status";
import {MOMENT_KEY, STORAGE_KEY, STORAGE_KEY_REFRESH} from "../utils/config";
import moment from "moment";
const user = JSON.parse(localStorage.getItem(STORAGE_KEY));
const sessionTime = JSON.parse(localStorage.getItem(MOMENT_KEY)) || 0;
const refreshData = JSON.parse(localStorage.getItem(STORAGE_KEY_REFRESH));
import { datadogRum } from '@datadog/browser-rum';

const initialState = {
    user: user,
    isLoggedIn: !!user,
    status: STATUSES.IDLE,
    error_message: null,
    email: null,
    mobile: null,
    data: refreshData,
    user_id: null,
    themeData:null,
    tokenExp: {ssoToken: false},
    sessionTime: Number(sessionTime)
};



function checkTokenValid(state){
    const sso_token = state.user?.ssoToken
    if(sso_token){
        let decodeValue = JSON.parse(decodeBase64(sso_token.split('.')[1]));
        state.data = {...state.data,applicationId: decodeValue.applicationId}
        let d = new Date(0);
        let momentInTime = moment(d.setUTCSeconds(decodeValue.exp))
        let now = moment();
        let val = now.isAfter(momentInTime)
        let diff =  now.diff(momentInTime, 'minutes')
        if(diff > 0){
            state.tokenExp = {...state.tokenExp,ssoToken: true}
        }else {
            state.tokenExp = {...state.tokenExp,ssoToken: false,checked: true}
        }
    }
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers:{
        setUser(state, action) {
            datadogRum.setUser({
                id: action.payload.user_id,
                name: action.payload.user_name,
                email: action.payload.email,
            })
            state.user = action.payload;
            state.isLoggedIn = true;
        },
        setStatus(state, action) {
            state.status = action.payload;
        },
        setMessage(state, action) {
            state.error_message = action.payload;
        },
        clearMessage(state, action) {
            state.error_message = null;
        },
        logout(state) {
            state.isLoggedIn = false;
            state.user = null;
        },
        setMpinEnable(state) {
            state.user.mpin_enabled = true;
        },
        setEmailAndMobile(state, action){
            state.email = action.payload.email
            state.mobile = action.payload.mobile
        },
        setQrData(state,action){
            state.data = action.payload.data
        },
        setOtpData(state, action){
            state.data = action.payload.data
            state.user_id = action.payload.user_id
        },
        setSessionTime(state, action){
            state.sessionTime = action.payload
        },
        setThemeData(state, action) {
            state.themeData = action.payload;
        },
        initialCheckData(state){
            checkTokenValid(state)
        },
        loginEvent(state,action){
            
            window.GtmPusher({
                event: 'tv-login',
                email: state.email,
                mobile_number: state.mobile
            })
        },
        switchUserEvent(state){
            window.GtmPusher({
                event: 'tv-switch_user',
            })
        },
        TotpDoLaterEvent(state){
            window.GtmPusher({
                event: 'tv-totp_do_later',
            })
        },

    },
});


export const currentUser = (state) => state.authUser.user;
export const { setUser, setStatus, setMessage,clearMessage,setThemeData,
    setEmailAndMobile,setOtpData,setSessionTime,logout,loginEvent,setMpinEnable,initialCheckData,
    switchUserEvent,TotpDoLaterEvent,setQrData} = authSlice.actions;
export default authSlice.reducer;
