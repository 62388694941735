import React, {useState} from 'react';
import './all_stayle.css'
import {numDifferentiation} from "../../comman/status";

export function NumberColor({number,textChange,numFix,percentageAdd,isFiniteCheck,returnSign=false,returnBracket}) {

    const num = Math.sign(number)
    // const [ num, setnum ] = useState(num)



    if(textChange === true){
        number =  numDifferentiation(number)
    }

    if(numFix === true && !isNaN(num)){
        number =  number.toFixed(2)
    }

    if(!isFinite(number)){
        number =  "0.0"
    }

    if(percentageAdd === true){
        number = `${number}%`
    }

    if(returnSign===true){
        if(num === 1) {
            return(<i className="fa-solid fa-caret-up greenText1"></i>);
        }else if(num === -1) {
            return(<i className="fa-solid fa-caret-down red_icon"></i>);
        }else if(num === 0) {
            return(<i className="fa-solid fa-caret-up greenText1"></i>);
        }else if(num === -0) {
            return(<i className="fa-solid fa-caret-down red_icon"></i>);
        }else if(isNaN(num)) {
            return(<i className="fa-solid fa-caret-up greenText1"></i>);
        }

    }else if(returnBracket==true){
        if(num === 1) {
            return(<div className="greenText1">(+{number})</div>);
        }else if(num === -1) {
            return(<div className="redText1">({number})</div>);
        }else if(num === 0) {
            return(<div className="greenText1">(+{number})</div>);
        }else if(num === -0) {
            return(<div className="redText1">({number})</div>);
        }else if(isNaN(num)) {
            return(<div className="greenText1">(0)</div>);
        }
    }
    else{
        if(num === 1) {
            return(<div className="greenText1">+{number}</div>);
        }else if(num === -1) {
            return(<div className="redText1">{number}</div>);
        }else if(num === 0) {
            return(<div className="greenText1">+{number}</div>);
        }else if(num === -0) {
            return(<div className="redText1">{number}</div>);
        }else if(isNaN(num)) {
            return(<div className="greenText1">0</div>);
        }
    }

}

export const ExchangeSegmentMap = {
    "NSE_EQ": 1,
    "NSE_FO": 2,
    "MCX_FO": 5,
    "NSE_CUR": 13,
    "BSE_FO": 4,
    "BSE_EQ": 3,
    "NSE_CUR_SPOT": 14,
    "MCX_FO_SPOT": 6,
}

export const SegmentExchangeMap = {
    1: "NSE_EQ",
    2: "NSE_FO",
    5: "MCX_FO",
    13: "NSE_CUR",
    14: "NSE_CUR_SPOT",
    6: "MCX_FO_SPOT",
    4: "BSE_FO",
    3: "BSE_EQ"
}

// export const ExchangeSegmentMap;