import React, { useEffect, useState } from "react";
import {DataFeedInstance} from "../../chart-datafeed/udf-compatible-datafeed";


const FutureChainTableData = ({data,symbol,odinToken,mktId})=> {

    const [subData,setSubData]=useState(null)
    const [ltpChangePercent,setLtpChangePercent] = useState(0)
    const [ltpChangeClass,setLtpChangeClass]=useState(null)
    const [ltpChange,setLtpChange] = useState(null)
    const [ltpClass,setLtpClass]=useState(null)

    useEffect(()=>{
        if(subData) checkLtpChangePercentage()
    },[subData])

    useEffect(()=>{
        if(subData) checkLtpChange()
    },[subData])

    function checkLtpChangePercentage(){
        let sign = Math.sign(subData.chp)
        if(sign===1){
            setLtpChangePercent("(+"+subData.chp.toFixed(2)+"%)")
            setLtpChangeClass("greenText1")
        }else if(sign===-1){
            setLtpChangePercent("("+subData.chp.toFixed(2)+"%)")
            setLtpChangeClass("redText1")
        }else if(sign===0){
            setLtpChangePercent("(0%)")
            setLtpChangeClass("greenText1")
        }else if(sign===-0){
            setLtpChangePercent("(0%)")
            setLtpChangeClass("redText1")
        }else{
            setLtpChangePercent("(0%)")
            setLtpChangeClass("greenText1")
        }
    }
    function checkLtpChange(){
        let sign = Math.sign(subData.ch)
        if(sign===1){
            setLtpChange("+"+subData.ch.toFixed(2))
            setLtpClass("greenText1")
        }else if(sign===-1){
            setLtpChange(subData.ch.toFixed(2))
            setLtpClass("redText1")
        }else if(sign===0){
            setLtpChange("(0%)")
            setLtpClass("greenText1")
        }else if(sign===-0){
            setLtpChange("(0%)")
            setLtpClass("redText1")
        }else{
            setLtpChange("(0%)")
            setLtpClass("greenText1")
        }
    }

    useEffect(()=>{
        if(odinToken && mktId) Sub()

        return () => {
            if(listenerGuid) removeSub()
        }
    },[data])

    let listenerGuid = undefined

    function Sub() {
        listenerGuid = `top-${odinToken}-${mktId}`
        DataFeedInstance.subcscribeQuotesFromTokens([{market_segment_id: mktId , token: odinToken }],(quotes)=>{
            setSubData(quotes[0].v)
        },listenerGuid)
    }

    function removeSub(){
        DataFeedInstance.unsubscribeQuotes(listenerGuid)
    }



return(
    <div className="row" >
        <div className="col-5 p-3 tabledata" style={{"justifyContent":"left","paddingLeft":"5%"}}>{symbol}</div>
        <div className="col-2 p-3 tabledata" style={{"justifyContent":"left"}}></div>
        <div className="col-2 p-3 tabledata" style={{"justifyContent":"left"}}>{subData?"₹"+subData.lp.toFixed(2):'₹0'}</div>
        <div className="col-3 p-3 tabledata" style={{"justifyContent":"right","paddingRight":"5%"}}><span className={`${ltpClass}`}>{subData?ltpChange:"0"}</span><span className={`${ltpChangeClass}`}>{subData?ltpChangePercent:"(0%)"}</span></div>
    </div>
)

}
export default FutureChainTableData

