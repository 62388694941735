import React, {useEffect} from 'react';

function AuthStore(props) {


    useEffect(() => {
        console.log('Start')
        window.addEventListener("message", handleMessage, false);
        return () => {
            window.removeEventListener('message',handleMessage);
        };
    },[])

    function handleMessage(e) {
        let {key, value, method} = e.data;
        if (method === 'store') {
            console.log(e.data)
            window.localStorage.setItem(key, value); // Store data in iframe domain local storage
        } else if (method === 'retrieve') {
            let response = window.localStorage.getItem(key);
            e.source.postMessage({
                key,
                response,
                method: 'response'
            }, '*'); // Retrieve local storage data
        }
    }

    return (
        <div></div>
    );
}

export default AuthStore;