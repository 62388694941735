import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './LoginForm.css';
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import authService from "../../services/authService";
import {decodeBase64,STATUSES} from "../../comman/status";
import Background from "../BackGround/background";

import logo from "../../assets/images/Frame.svg"
import Flowlogo from "../../assets/images/appFlow.svg"
import Form from 'react-bootstrap/Form'
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import MadeInLoveIndia from "../common/madeInLoveIndia";
import InputGroup from "react-bootstrap/InputGroup";
import flowBackEndServices2 from "../../services/flowBackendServices";
import {DEVICE_ID_KEY, QUERY_PARAM, TV_APPLICATION_ID} from "../../utils/config";
import {toast} from "react-toastify";
import {setSharedBasketId} from "../../slices/basketOrderSlice"
import {setOtpData, setStatus} from "../../slices/authSlice";
import SupportDesktopView from './DesktopViewSupport';

const LoginForm = (key, value) => {

  const [searchParams, setSearchParams] = useSearchParams();
  const { isLoggedIn,status,tokenExp,user_id, themeData,data,user,theamDataBG } = useSelector((state) => state.authUser);
  const [formState, setFormState] = useState({user_id: '', password: '', second_auth: '',ApplicationId: TV_APPLICATION_ID,basket_id:""});
  const [ errors, setErrors ] = useState({})
  const [isLoading, setIsLoading] = useState(false);
  const [refId,setRefId]=useState(null)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [passwordShown, setPasswordShown] = useState(false);

    // useEffect(() =>{
    //     if(themeData) getReturn()
    // },[themeData])

    const togglePassword = () => {
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setPasswordShown(!passwordShown);
    };


    function setApplicationID() {
        let applicationId = formState.ApplicationId
        if(searchParams.get('applicationId')){
            applicationId = searchParams.get('applicationId')
            localStorage.setItem(QUERY_PARAM,JSON.stringify(searchParams.toString()))
        }else if(themeData?.applicationId){
            applicationId = themeData.applicationId
        }
        let dataSet = {data: { ...data,applicationId},user_id: user_id}
        dispatch(setOtpData(dataSet))
    }

    function callApiAndRedirect() {
        let datasend = {ssoToken:user.ssoToken,applicationId: searchParams.get('applicationId') }
        dispatch(setStatus(STATUSES.LOADING));
        flowBackEndServices2.getRedirecUrl(datasend,user.access_token)
                    .then( (value) => {
                        if(value?.status === 200) {
                            if(value.data?.redirectUrl){
                                window.open(value.data.redirectUrl,'_self')
                            }else {
                                toast.error(`${value.data.message}`)
                            }
                        }
                    }).catch(reason => {
                        toast.error(`invalid Data`)
                        setIsLoading(false)
                        dispatch(setStatus(STATUSES.IDLE));
                    }).finally(() =>  dispatch(setStatus(STATUSES.IDLE)))
    }

    function checkTokenValid(){
        var applicationId = searchParams.get('applicationId')
        if (applicationId != ""){
            // at_cTKcWxTMaL is vortex application id. we dont want it to directly use sso auth
            if (applicationId.startsWith("at_") && applicationId != "at_cTKcWxTMaL" ) {
                if(tokenExp.ssoToken){
                    handleLogout()
                }else {
                    callApiAndRedirect()
                }
            }else{
                navigate("/?applicationId="+applicationId,{ replace: true});
            }
        }else{
            if(tokenExp.ssoToken){
                handleLogout()
            }else {
                callApiAndRedirect()
            }
        }
    }

    const handleLogout = () => {
        dispatch(authService.logout())
        navigate("/",{ replace: true});
    }

    useEffect(()=>{
        let ack = sessionStorage.getItem("acknowledge_disclosure")
        if(isLoggedIn){
            if(searchParams.get('applicationId')) return checkTokenValid();
            if(ack){
                if(ack!="true"){
                    navigate("/tvTerminal",{ replace: true});
                }else{
                    handleLogout()
                }
            }else{
                navigate("/tvTerminal",{ replace: true})
            }
            return
        }

        if(user_id !== null){
            setFormState({ ...formState,user_id: user_id})
        }
    },[theamDataBG])

    useEffect(() => {
          // setFormState({user_id: '600S1871', password: 'ApiTesting15', second_auth: '9741578665'})

        setApplicationID()
    },[])

    useEffect(()=>{
        setBasketId()
    },[])

    function setBasketId(){
        if(searchParams.get('basket_id')){
            var basketId = searchParams.get('basket_id')
            sessionStorage.setItem("public_basket_id",basketId)
            dispatch(setSharedBasketId(basketId))
        }
        if(searchParams.get('referral_id')){
            var referralId = searchParams.get('referral_id')
            sessionStorage.setItem("referral_id",referralId)
            setRefId(referralId)
        }
    }

    function handleFormStateChange(event) {
        const { name, value } = event.target;


        if(event.target.name === 'password'){
            setFormState({ ...formState, [name]: value });
        }else {
            setFormState({ ...formState, [name]: value.toUpperCase() });
        }


        if ( !!errors[name] ) setErrors({
            ...errors,
            [name]: null
        })
    }



    const findFormErrors = () => {
        const { user_id, password, second_auth } = formState
        const newErrors = {}
        // name errors
        if ( !user_id || user_id === '' ) newErrors.user_id = 'User id is required!'
        else if ( user_id.length < 4 ) newErrors.user_id = 'User id is too short!'

        if ( !password || password === '' ) newErrors.password = 'password id is required!'
        else if ( password.length < 4 ) newErrors.password = 'password id is too short!'

        // if ( !second_auth || second_auth === '' ) newErrors.second_auth = 'pan/mobile/dob is required!'
        // else if ( second_auth.length < 4 ) newErrors.second_auth = 'pan/mobile/dob is too short!'


        return newErrors
    }


    function SendOpenAccound() {
        if(refId){
            window.open('https://accounts.rupeezy.in/authentication/login?c='+refId,'_blank')
        }else{
            window.open('https://accounts.rupeezy.in/authentication/login','_blank')
        }
    }


    function getIconAndHeader() {
        if(themeData.themeNeed){
            return(
                <div className="pt-5">
                    <div className=""><img width={'60'} height={'60'} src={'https://static.rupeezy.in/branding/blue-for-light-background.svg'} alt='Rupeezylogo'/></div>
                    <div className="heading1 pt-2">Rupeezy</div>
                    <div className="heading3 colorGrey py-2">Login to {themeData.display_name} with Rupeezy</div>
                </div>
            )
        }else {
            return(
                <div className="pt-5">
                    <div className=""><img src={logo} alt='logo'/></div>
                    <h3 className="heading1 py-2">Trade directly from charts</h3>
                </div>
            )
        }
    }

    if(status === STATUSES.LOADING){
        return (
            <div className="center_loading">
                    <Spinner className="mr-2 align-self-center" as="span" animation="border"  role="status" aria-hidden="true"/>
            </div>
        )
    }

    function getResponsiveLoginForm(){
        return(
            <div className="h-100 p-5 p-xl-5 d-flex flex-column justify-content-end">

            {themeData && getIconAndHeader()}

            <div className="align-self-start flex-fill w-100 d-flex flex-column-reverse">

                <Form className="w-100" onSubmit={handleOnClick} on>
                    <Form.Group className="mb-2" controlId="formBasicEmail">
                        <Form.Label className="text1 w-100">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <div className="">Mobile Number / Client Code</div>
                                <div className=""/>
                                {/* <div className="colorBlue"><Link to='/ForgetUserID'>Forgot user ID ?</Link></div> */}
                            </div>
                        </Form.Label>
                        <Form.Control type="text"
                                      name="user_id"
                                      required
                                      isInvalid={ !!errors.user_id }
                                      value={formState.user_id}
                                      onChange={handleFormStateChange}
                                      placeholder="Mobile Number / Client Code" />
                    </Form.Group>

                    <Form.Group className="mb-2" controlId="formBasicPassword">
                        <Form.Label className="text1 w-100">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <div className="">Password</div>
                                <div className="colorBlue"><Link to='/FlowForgetPassword'>Forgot password ?</Link></div>
                            </div>
                        </Form.Label>
                        <InputGroup className="">
                        <Form.Control type={passwordShown ? "text" : "password"}
                                      name="password"
                                      required
                                      isInvalid={ !!errors.password }
                                      value={formState.password}
                                      onChange={handleFormStateChange}
                                      placeholder="Password" />

                        <InputGroup.Text>
                            {
                                passwordShown ?  <i onClick={togglePassword} className="fa-solid fa-eye"/>  :
                                    <i onClick={togglePassword} className="fas fa-eye-slash"/>
                            }
                        </InputGroup.Text>
                        </InputGroup>
                    </Form.Group>

                   {/* <Form.Group className="mb-2" controlId="formBasicmobile">
                        <Form.Label className="text1 w-100">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <div className="">PAN/ Mobile No./ DOB(DDMMYYY)*</div>
                                <div className="">&nbsp;</div>
                            </div>
                        </Form.Label>
                        <Form.Control
                            id="mobile"
                            name="second_auth"
                            type="text"
                            value={formState.second_auth}
                            isInvalid={ !!errors.second_auth }
                            onChange={handleFormStateChange}
                            placeholder="PAN / Mobile No / DOB" />
                    </Form.Group>*/}
                   <Button disabled={isLoading} className="blue_btn w-100 my-3" onClick={handleOnClick} variant="primary" type="submit">
                    {isLoading && (
                        <Spinner className="mr-2" as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                    )}
                    Login
                </Button>

                </Form>


            </div>


            <div className="">

               {/* <Button disabled={status === STATUSES.LOADING} className="blue_btn w-100 my-3" onClick={handleOnClick} variant="primary" type="submit">
                    {status === STATUSES.LOADING && (
                        <Spinner className="mr-2" as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                    )}
                    Sign in
                </Button>*/}
               {themeData && themeData.permission_needed ? ("") : (<div className='qr_log_switch_login py-2' onClick={handleOnClickQr}>login using QR Code</div>)}
                

                <div className="py-2">Don’t have an account ? <span onClick={SendOpenAccound} className="colorBlue c-pointer"> Open an account</span> </div>

                <MadeInLoveIndia/>

            </div>

        </div>
        )
    }

    return(
        <Background>
            {getReturn()}
        </Background>
    )

    function getReturn(){
        if(!isMobile){
            return (
                <React.Fragment>
                    {getResponsiveLoginForm()}
                </React.Fragment>
           );
        }else{
            if(themeData && !themeData.themeNeed){
                return (
                    <SupportDesktopView />
           );
            }else{
                return (
                    <React.Fragment>
                        {getResponsiveLoginForm()}
                    </React.Fragment>
               );
            }
        }
        // if(themeData && themeData.themeNeed){
        //     return (
        //         <React.Fragment>
        //            {getResponsiveLoginForm()}
        //         </React.Fragment>
        //    );
        // }else if(themeData && !themeData.themeNeed && !isMobile){
        //     return (
        //         <React.Fragment>
        //            {getResponsiveLoginForm()}
        //         </React.Fragment>
        //    );
        // }else{
        //     return (
        //             <SupportDesktopView />
        //    );
        // }
    }

    async function handleOnClickQr(event){
        event.preventDefault();
        navigate("/qrLogin")
    }


    async function handleOnClick(event) {
        event.preventDefault();

        // get our new errors
        const newErrors = findFormErrors()
        // Conditional logic:
        if ( Object.keys(newErrors).length > 0 ) {
            setErrors(newErrors)
        } else {
             // await dispatch(authService.fetchAuthUser(formState,navigate));
             await flowLoginApi()
        }

    }
    
   async function flowLoginApi() {
        let dataSend = {
            clientCode: formState.user_id,
            password: formState.password,
            deviceId: getDeviceID(),
            applicationId: themeData.applicationId
        }
        setIsLoading(true)
        flowBackEndServices2.flowLogin2fo(dataSend)
            .then( async (value) => {
                if(value.status === 200){
                     let mainData = value.data
                    if(mainData === '') return toast.error(`No Responce`)
                    if(mainData.status !== 'success') toast.error(`${mainData.message}`)


                    let data = {data: {...dataSend,...mainData},user_id: dataSend.clientCode}
                    await dispatch(setOtpData(data))
                    if(mainData.mfaType === '' && mainData.tempToken === '' && mainData.pwdNeedsChange){
                        navigate("/FlowForgetPassword",{ state: data,hash:'RESET'})
                        return
                    }else if(mainData.tempToken !== '' && mainData.pwdNeedsChange && mainData.status === 'success'){
                        navigate("/PasswordNeedChange",{ state: data})
                        return
                    } {
                        if(mainData.status === 'success')  navigate("/loginOTPVerify",{ state: data})
                         // navigate("/PasswordNeedChange",{ state: data})
                    }

                }
            })
            .catch(reason => {
                toast.error(`invalid Data`)
                setIsLoading(false)
            })
            .finally(() =>  setIsLoading(false))
    }

    function getDeviceID() {
       let deviceID = localStorage.getItem(DEVICE_ID_KEY)
        if(deviceID === null){
            deviceID = Date.now().toString()
            localStorage.setItem(DEVICE_ID_KEY,deviceID)
        }
       return  deviceID
    }

}

export default LoginForm;
