export const ordersPageColumns = [
    {
        id:"symbol",
        label: 'Symbol',
        formatter: 'symbol',
        dataFields: ['symbol', 'symbol', 'message'],
    },
    {
        label: "", 
        id:"order_id",
        formatter: "orderSettings", 
        dataFields: ['order_id']
    },
    {
        label: 'Buy/Sell',
        id:"buy/sell",
        dataFields: ['side'],
        formatter: 'side',
    },
    {
        label: 'Type',
        id:"type",
        dataFields: ['order_type'],
        formatter: 'custom_uppercase',
    },
    {
        label: 'Product',
        id:"product",
        dataFields: ['product'],
        formatter: 'custom_uppercase',
    },
    {
        label: 'Qty(Lots)',
        id:"lots",
        alignment: 'right',
        dataFields: ['qty'],
        help: 'Size in lots',
        formatter: 'custom_uppercase',
    },
    {
        label: 'Lot Size',
        id:"lotSize",
        alignment: 'right',
        dataFields: ['lot_size'],
        formatter: 'formatQuantity',
        help: 'Quantity per lot',
    },
    {   id:"duration",
        label: "Duration",
        dataFields: ["duration"],
        formatter: 'custom_uppercase',
    }
    ,
    {
        label: 'Rem Qty',
        id:"remQty",
        alignment: 'left',
        dataFields: ['remQty'],
        help: 'Size in lots',
        formatter: 'custom_uppercase',
    },
    {
        id:"limitPrice",
        label: 'Limit Price',
        alignment: 'left',
        dataFields: ['limitPrice'],
        formatter: 'formatPrice',
    },
    {   
        id:"stopPrice",
        label: 'Stop Price',
        alignment: 'left',
        dataFields: ['stopPrice'],
        formatter: 'formatPrice',
    },
    // {
    //     label: 'Traded Price',
    //     alignment: 'left',
    //     dataFields: 'tradedPrice',
    //     formatter: 'formatPrice',
    // },
    {
        id:"status",
        label: 'Status',
        dataFields: ['status'],
        formatter: 'status',
        supportedStatusFilters: [0 /* All */],
    },
    {
        id:"amo",
        label: 'After Market',
        dataFields: ['is_amo_order'],
        formatter: 'custom_upercase',
    },
    {
        id:"order",
        label: 'Order id',
        dataFields: ['id'],
        formatter: 'custom_uppercase'
    },
    {
        id:"message",
        label: 'Message', 
        dataFields: ['displayMessage'], 
        formatter: 'custom_uppercase'

    }
];
export const positionsPageColumns = [
    {
        id:"tableId",
        label: 'Table Id',
        formatter: 'symbol',
        dataFields: ['id'],
        // hide: true,
        hideByDefault: true, 
        showOnMobile: false,
        notHideable: false
    },
    {
        id:"symbol",
        label: 'Symbol',
        formatter: 'symbol',
        dataFields: ['symbol', 'symbol', 'message'],
    },
    // {
    //     label: "", 
    //     formatter: 'posSettings', 
    //     dataFields: 'settings'
    // },
    {
        id:"product",
        label: 'Product', 
        formatter: 'custom_uppercase', 
        dataFields: ['product']
    },

    {
        id:"long/short",
        label: 'Long/Short',
        dataFields: ['side'],
        formatter: 'positionSide',
    },
    {
        id:"today'sP&L",
        label: "Today's P&L",
        alignment: 'right',
        dataFields: ['totalPl'],
        formatter: 'profit',
    },
    {
        id:"overAllP&L",
        label: "Overall P&L",
        alignment: 'right',
        dataFields: ['overallPl'],
        formatter: 'profit',
    },
    {
        id:"qty",
        label: 'Qty(Lots)',
        alignment: 'right',
        dataFields: ['qty'],
        formatter: 'formatQuantity',
        help: 'Size in lots',
    },
    {
        id:"lot_size",
        label: 'Lot Size',
        alignment: 'right',
        dataFields: ['lot_size'],
        formatter: 'formatQuantity',
        help: 'Size in lots',
    },
    {
        id:"avgPrice",
        label: 'Avg Price',
        alignment: 'right',
        dataFields: ['avgPrice'],
        formatter: 'formatPrice',
    },
    {
        id:"ltp",
        label: 'LTP',
        alignment: 'right',
        dataFields: ['last'],
        formatter: 'formatPrice',
        highlightDiff: true,
    },
    // {
    //     label: 'Realized P&L',
    //     alignment: 'right',
    //     dataFields: 'realizedPl',
    //     formatter: 'profit',
    // },
    // {
    //     label: 'Unrealized P&L',
    //     alignment: 'right',
    //     dataFields: 'unrealizedPl',
    //     formatter: 'profit',
    // },
    {
        id:"buyQty",
        label: "Buy Qty",
        alignment: 'right',
        dataFields: ['buyQty'],
        formatter: 'formatQuantity',
    },
    // {
    //     id:"BuyAvg",
    //     label: "Buy Avg",
    //     alignment: 'right',
    //     dataFields: ['buyAvg'],
    //     formatter: 'formatPrice',
    // },
    {
        id:"sellQty",
        label: "Sell Qty",
        alignment: 'right',
        dataFields: ['sellQty'],
        formatter: 'formatQuantity',
    },
    // {
    //     id:"sellAvg",
    //     label: "Sell Avg",
    //     alignment: 'right',
    //     dataFields: ['sellAvg'],
    //     formatter: 'formatPrice',
    // }
    
];

export const closedPositionsPageColumns = [
    {
        id:"id",
        label: 'Table Id',
        formatter: 'symbol',
        dataFields: ['id'],
        // hide: true,
        hideByDefault: true, 
        showOnMobile: false,
        notHideable: false
    },
    {
        id:"symbol",
        label: 'Symbol',
        formatter: 'symbol',
        dataFields: ['symbol'],
    },
    // {
    //     label: "", 
    //     formatter: 'posSettings', 
    //     dataFields: 'settings'
    // },
    {
        id:"product",
        label: 'Product', 
        formatter: 'custom_uppercase', 
        dataFields: ['product']
    },

    {
        id:"long/short",
        label: 'Long/Short',
        dataFields: ['side'],
        formatter: 'positionSide',
    },
    {
        id:"qty",
        label: 'Qty(Lots)',
        alignment: 'right',
        dataFields: ['qty'],
        formatter: 'formatQuantity',
        help: 'Size in lots',
    },
    {
        id:"lot_size",
        label: 'Lot Size',
        alignment: 'right',
        dataFields: ['lot_size'],
        formatter: 'formatQuantity',
        help: 'Size in lots',
    },
    {
        id:"avg_price",
        label: 'Avg Price',
        alignment: 'right',
        dataFields: ['avgPrice'],
        formatter: 'formatPrice',
    },
    {
        id:"ltp",
        label: 'LTP',
        alignment: 'right',
        dataFields: ['last'],
        formatter: 'formatPrice',
        highlightDiff: true,
    },
    {
        id:"realized",
        label: 'Realized P&L',
        alignment: 'right',
        dataFields: ['realizedPl'],
        formatter: 'profit',
    },
    {
        id:"unRealized",
        label: 'Unrealized P&L',
        alignment: 'right',
        dataFields: ['unrealizedPl'],
        formatter: 'profit',
    },
    {
        id:"total",
        label: 'Total P&L',
        alignment: 'right',
        dataFields: ['totalPl'],
        formatter: 'profit',
    },
    {
        id:"buy_qty",
        label: "Buy Qty",
        alignment: 'right',
        dataFields: ['buyQty'],
        formatter: 'formatQuantity',
    },
    {
        id:"buy_avg",
        label: "Buy Avg",
        alignment: 'right',
        dataFields: ['buyAvg'],
        formatter: 'formatPrice',
    },
    {
        id:"sell_qty",
        label: "Sell Qty",
        alignment: 'right',
        dataFields: ['sellQty'],
        formatter: 'formatQuantity',
    },
    {
        id:"sell_avg",
        label: "Sell Avg",
        alignment: 'right',
        dataFields: ['sellAvg'],
        formatter: 'formatPrice',
    }
    
];

export const accountSummaryColumns = [
    {
        id:"title",
        label: '',
        notSortable: true,
        dataFields: ['title'],
        formatter: 'custom_uppercase',
    },
    {
        id:"balance",
        label: 'Balance',
        alignment: 'right',
        dataFields:['balance'],
        formatter: 'fixed',
        fixedWidth: true,
    },
    {
        id:"open PL",
        label: 'Open PL',
        alignment: 'right',
        dataFields: ['pl'],
        formatter: 'profit',
        notSortable: true,
        fixedWidth: true,
    },
    {
        id:"equity",
        label: 'Equity',
        alignment: 'right',
        dataFields: ['equity'],
        formatter: 'fixed',
        notSortable: true,
        fixedWidth: true,
    },
];


export const fundSummaryColumns = [
    {
        id:"detail",
        label: 'Detail',
        notSortable: true,
        dataFields: ['id'],
        formatter: 'custom_uppercase',
        sortProp: true
    },
    {
        id:"value",
        label: 'Value',
        alignment: 'right',
        dataFields: ['value'],
        formatter: 'fixed',
        notSortable: true,
        fixedWidth: true,
    },
]

export const holdingColumns = [
    // {
    //     label: 'id',
    //     notSortable: false,
    //     dataFields: 'id',
    //     formatter: 'custom_uppercase',
    // },
    {
        id:"symbol",
        label: 'Symbol',
        notSortable: false,
        dataFields: ['symbol','symbol','message'],
        formatter: 'symbol',
    },
    {
        id:"quantity",
        label: 'Quantity',
        notSortable: false,
        dataFields: ['quantity'],
        formatter: 'custom_uppercase',
    },
    // {
    //     label: 'Remaining Quantity',
    //     notSortable: false,
    //     dataFields: 'symbol',
    //     formatter: 'custom_uppercase',
    // },
    {
        id:"avg_buy_price",
        label: 'Avg. Buy Price',
        notSortable: false,
        dataFields: ['average_price'],
        formatter: 'custom_uppercase',
    },
    {
        id:"ltp",
        label: 'LTP',
        notSortable: false,
        dataFields: ['last_price'],
        formatter: 'formatPrice',
        highlightDiff: true,
    },
    {
        id:"change%",
        label: "Day's Change(%)",
        notSortable: false,
        dataFields: ['days_change'],
        formatter: 'formatPrice',
        highlightDiff: true,
    },
    {
        id:"mkt_val",
        label: 'Market Value',
        notSortable: false,
        dataFields: ['market_value'],
        formatter: 'formatPrice',
    },
    {
        id:"unrealized",
        label: 'Unrealized P&L',
        notSortable: false,
        dataFields: ['unrealized_pnl'],
        formatter: 'profit',
    },
    {
        id:"unrealized%",
        label: 'Unrealized P&L(%)',
        notSortable: false,
        dataFields: ['unrealized_pnl_per'],
        formatter: 'profit',
    },
    {
        id:"holding_type",
        label: 'Holding Type',
        notSortable: false,
        dataFields: ['holding_type'],
        formatter: 'custom_uppercase',
    },
]

export const tradesColumns = [
    {
        id:"symbol",
        label: "Symbol", 
        dataFields: ['symbol','symbol','message'], 
        formatter: 'symbol', 

    },
    {
        id:"exchange",
        label: "Exchange & Segment", 
        dataFields: ["exchange"], 
        formatter: "custom_uppercase"
    },
    {
        id:"buy/sell",
        label: "Buy/Sell", 
        dataFields: ['side'],
        formatter: 'side',
    },
    {
        id:"order_type",
        label: "Order Type", 
        dataFields: ['type'],
        formatter: 'custom_uppercase',
    },
    {
        id:"product_type",
        label: "Product Type", 
        dataFields: ['product'],
        formatter: 'custom_uppercase',
    },
    {
        id:"traded_qty",
        label: "Traded Quantity", 
        dataFields: ['tradedQty'],
        formatter: 'custom_uppercase',
    },
    {
        id:"traded_price",
        label: "Traded Price", 
        dataFields: ['tradedPrice'],
        formatter: 'formatPrice',
    },
    {
        id:"time",
        label: "Time", 
        dataFields: ['tradeTime'],
        formatter: 'custom_uppercase',
        fixedWidth: true,
    },
    {
        id:"order_number",
        label: "Order Number", 
        dataFields: ['orderNumber'],
        formatter: 'custom_uppercase',
    },
    {
        id:"trade_number",
        label: "Trade Number", 
        dataFields: ['id']
    }
]