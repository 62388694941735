import { UDFCompatibleDatafeedBase } from './udf-compatible-datafeed-base';
import { Requester } from './requester';
import { OdinRequester } from './odin_requester';
import {InHouseRequester} from './inhouse_requester'; 
import { remoteConfigGetter } from "../utils/remoteConfig";

export let DataFeedInstance = null 
export class UDFCompatibleDatafeed extends UDFCompatibleDatafeedBase {
    constructor(odin_url,auth_token,inhouse_url, logout_function) {
        const requester = new Requester({
            'Authorization': "Bearer "+auth_token, 
            "X-Application-Id": process.env.REACT_APP_TV_APPLICATION_ID,
            "X-Stage": process.env.REACT_APP_ENV, 
            'x-api-key': process.env.REACT_APP_VORTEX_KEY
        },logout_function);
        const inHouseRequester = new InHouseRequester({
            'Authorization': "Bearer "+auth_token, 
            "X-Application-Id": process.env.REACT_APP_TV_APPLICATION_ID,
            "X-Stage": process.env.REACT_APP_ENV, 
            'x-api-key': process.env.REACT_APP_VORTEX_KEY
        },logout_function)
        const odinRequester = new OdinRequester({
            'x-api-key': remoteConfigGetter("x_api_key").asString(), 
            'Authorization': 'Bearer '+auth_token, 
            'Content-Type': 'application/json',
        },logout_function);
        super(odin_url,inhouse_url, requester,odinRequester,inHouseRequester);
        DataFeedInstance = this
    }
}
