import { useEffect, useState } from "react"
import {DataFeedInstance} from "../../chart-datafeed/udf-compatible-datafeed";


 export const productTypeModel = {'1': 'Intraday','2':'PayLater','3':'Delivery'};
 export const OrderTypeModel = {'1':'RL','2':'RL-MKT','3':'SL','4':'SL-MKT'};

const PublicEachStock = ({data})=>{

    const [ subData, setSubData ] = useState(null);

    useEffect(() => {
        headerData()
        return () => {
            removeSub()
        }
    }, [data])

    let listenerGuid = undefined
    function headerData() {
        listenerGuid = `bs-${data.token}-${data.stockData.marketSegmentId}`
        DataFeedInstance.subcscribeQuotesFromTokens([
            {market_segment_id: data.stockData.marketSegmentId , token: data.token }],(quotes)=>{
            setSubData(quotes[0].v)
        },listenerGuid)
    }

    function removeSub(){
        console.log("unsubscribe")
        DataFeedInstance.unsubscribeQuotes(listenerGuid)
    }



    return(
        <tr className="each_box card_bg">
        <td className="menuBox position-relative">
            <div className="d-flex flex-column justify-content-start align-items-start" style={{"padding":"7px 0px 3px 10px"}}>
                <div className="d-flex flex-row">
                    <div className="type_badge mr-2">{productTypeModel[data.productType.toString()]}</div>
                    <div className="type_badge mr-2">{OrderTypeModel[data.orderType.toString()]}</div>
                </div>
                <div className="d-flex flex-row mt-1">
                    {data.buySell === 1 ? <div className="buysell_badeg color_buy mr-1">B</div> : <div className="buysell_badeg color_sell mr-1">S</div> }
                    <div className="text_head1">{subData?subData.short_name:''}</div>
                </div>
            </div>
        </td>
        <td className="priceTag" style={{"paddingLeft": "10px","paddingTop": "15px"}}>{subData?subData.lp.toFixed(2):0} </td>
        <td className="priceTag" style={{"paddingLeft": "10px","paddingTop": "15px"}}>{data.orderType==2?'MKT PRICE':data.price}</td>
        <td className="priceTag" style={{"paddingLeft": "10px","paddingTop": "15px"}}>{data.quantity}</td>
    </tr>
    )
}

export default PublicEachStock