import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import './eachstock.css'
import MenuIcon from "../../../assets/icons/menu.svg";
import {ReactComponent as MenuIconRect} from "../../../assets/icons/menu.svg";
import executeIcon from "../../../assets/icons/send-2.svg";
import ModifyIcon from "../../../assets/icons/setting-3.svg";
import {ReactComponent as ModifyIconRect} from "../../../assets/icons/setting-3.svg";
import DeleteIcon from "../../../assets/icons/trash.svg";
import {ReactComponent as DeleteIconRect} from "../../../assets/icons/trash.svg";
import AdditemIcon from "../../../assets/icons/additem.svg";
import {ReactComponent as AdditemIconRect} from "../../../assets/icons/additem.svg";

import {DataFeedInstance} from "../../../chart-datafeed/udf-compatible-datafeed";
import {useDispatch, useSelector} from "react-redux";
import {setBasketDetails, setCurrentModification} from "../../../slices/basketOrderSlice";
import flowBackEndService from "../../../services/flowBackendApi";
import {toast} from "react-toastify";
import {basketSubServices} from "../basketSub";
import {currentUser} from "../../../slices/authSlice";
import {BrokerApiInstance} from "../../../chart-brokerapi/broker";
import Spinner from "react-bootstrap/Spinner";
import {OrderOMSFeedSub} from "../../../sub";
import {logMessage} from "../../../utils/helpers";

export const productTypeModel = {'1': 'Intraday','2':'PayLater','3':'Delivery'};
export const OrderTypeModel = {'1': 'RL','2':'RL-MKT','3':'SL','4':'SL-MKT'};
export const ValidtyTypeModel = {'1': 'DAY','2': 'IOC','3': 'GTT'}

const EachStock = forwardRef(({data,index,masterOrder,isExecuted,setSingleExcData}, ref) => {


    const dispatch = useDispatch();

    const [ subData, setSubData ] = useState(null);

    const { basketDetails } = useSelector((state) => state.basketOrder);
    const [isLoading, setIsLoading] = useState(false);
    const user = useSelector(currentUser);


    const [currentData, setCurrentData] = useState(null);

    function closeLodding2(){
        setIsLoading(false)
    }

    useImperativeHandle(ref, () => ({

        closeLodding() {
            closeLodding2()
        }

    }));


    const OrderStatus = {'1': 'Canceled', '2': 'Filled', '3': 'Inactive', '4': 'Placing', '5': 'Rejected', '6': 'Working'}


    useEffect(() => {
        listenSingleOrdereExcecution()
        return () => {
            subscription?.unsubscribe();
        };
    }, []);

    let subscription;
    function listenSingleOrdereExcecution(){
        subscription = OrderOMSFeedSub.OrderListener().subscribe(values => {
            if(values.response && values.response.error){
                setIsLoading(false)
            }
        })
    }

    useEffect(() => {
        // console.log('subData',index,subData)
    }, [subData])

    useEffect(() => {
         // console.log('masterOrder',masterOrder)
    }, [masterOrder])


    useEffect(() => {
      if(currentData) dispatch(setCurrentModification(currentData))
    }, [currentData])


    useEffect(() => {
         headerData()
        return () => {
            removeSub()
            // console.log('UnMount EachStock')
        }
    }, [data])

    Array.prototype.remove =  function(from, to) {
        var rest = this.slice((to || from) + 1 || this.length);
        this.length = from < 0 ? this.length + from : from;
        return this.push.apply(this, rest);
    };

    const deleteOrder = () => {
      let BasketOrders = [...basketDetails.basketOrders]
          BasketOrders.remove(index)
        let newData = { ...basketDetails,basketOrders: BasketOrders}
         dispatch(setBasketDetails(newData))
        setIsLoading(true)
        const datas = {basketName: newData.basketName,orders:newData.basketOrders}
        flowBackEndService.UpdateBasket(datas,user.access_token,newData.id)
            .then(value => {
                if(value.data.status ===  'success'){
                    // toast.success(`Basket order delete ${value.data.message}`);
                    basketSubServices.setNewBasket(newData)
                }else{
                    toast.error(`Error update ${value?.data?.message}`);
                }
            })
            .catch(error => {
                toast.error(`${error.response.data.message}`)
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            })

    }

    function getJsonOrder(){
        return {
            token: data.token,
            marketSegmentId: data.marketSegmentId,
            buySell: data.buySell,
            productType: data.productType,
            orderType: data.orderType,
            quantity: data.quantity,
            price:  data.price,
            disclosedQuantity: data.disclosedQuantity,
            triggerPrice: data.triggerPrice,
            validity: data.validity,
            isAmo: data.isAmo,
        };
    }

    const duplicateOrder = () => {
        let BasketOrders = [...basketDetails.basketOrders,getJsonOrder()]
        let newData = { ...basketDetails,basketOrders: BasketOrders}
        setIsLoading(true)
        const datas = {basketName: newData.basketName,orders:newData.basketOrders}
        flowBackEndService.UpdateBasket(datas,user.access_token,newData.id)
            .then(value => {
                if(value.data.status ===  'success'){
                    // toast.success(`Basket order Duplicate ${value.data.message}`);
                    basketSubServices.setNewBasket(newData)
                    dispatch(setBasketDetails(newData))
                }else{
                    toast.error(`Error update ${value?.data?.message}`);
                }
            })
            .catch(error => {
                toast.error(`${error.response.data.message}`)
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    let listenerGuid = undefined
    function headerData() {
        listenerGuid = `top-${data.token}-${data.marketSegmentId}-${index}`
        DataFeedInstance.subcscribeQuotesFromTokens([
            {market_segment_id: data.marketSegmentId , token: data.token }],(quotes)=>{
              // console.log("getQuotes data EachStock",quotes);
              // console.log(listenerGuid);
              // console.log('subData',index,quotes[0].v)
              setSubData(quotes[0].v)
        },listenerGuid)
    }

    function removeSub(){
        DataFeedInstance.unsubscribeQuotes(listenerGuid)
    }




   function setupStockData(values){
        let datanew = { symbol: values.symbol,type: data?.stockData?.series,
            _source: {nToken: subData.token,MktSegId: subData?.market_segment_id},
            currentData: data
            }
       setCurrentData(datanew)
    }

    function modifyOrder() {
        DataFeedInstance.tokenInfo({market_segment_id: data.marketSegmentId , token: data.token }).then(values => {
            setupStockData(values)
        })
    }

    function executeOrderSingle() {
        setIsLoading(true)
        DataFeedInstance.tokenInfo({market_segment_id: data.marketSegmentId , token: data.token }).then(values => {
            var listenerId =  data.id;
            setSingleExcData()
            placeOrederSingle(values,listenerId)
        })
    }

   function placeOrederSingle(values,listenerId){
       BrokerApiInstance.placeOrder({
           symbol: values.symbol,
           side: data.buySell,
           margin: data.productType,
           qty: data.quantity,
           limitPrice: data.price,
           triggerPrice: data.triggerPrice,
           order_type: data.orderType,
           validity: data.validity,
           is_amo: data.isAmo,
           origin:'single_order_basket'
       },listenerId).then(()=>{
           // console.log("ORDDDDDD",listenerId)
        //    logMessage(`ORDDDDDD ${listenerId}`);
           // setIsLoading(false)
           window.GtmPusher({
               event: 'tv-order-placed-basket'
           })
       })
   }


    function getOrderStatus() {
        if(data.orderId === '' && isExecuted){
            return  (
                <div  className="d-flex flex-row justify-content-start align-items-center">
                  {/*<div className="mr-1"><img src={executeIcon} alt={''}/></div>*/}
                    {isLoading ? (
                        <Spinner className="mr-2" as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                    ) : (<div onClick={executeOrderSingle} className="excecute_btn c-pointer">Execute &gt;</div>)}

                </div>
            )
        }

        if(data.orderId.length >  2){
         let orderSingle =  masterOrder.find(d => d.order_id === data.orderId)
            if(orderSingle){
               // console.log("orderSingle",orderSingle)
               let name = OrderStatus[orderSingle?.status?.toString()];
                return (
                    <div className={`back_side ${name}`}>{name}</div>
                )
            }else {
                // return (
                //     <div>{data.orderId}</div>
                // )
            }
        }

    }

    return (

            <tr className="each_box card_bg">
                <td><div className="mr-2 grab">
                    <MenuIconRect/>
                    {/*<img alt={''} className="grab" src={MenuIcon}/> */}
                </div></td>
                <td className="menuBox position-relative">
                    <div className="d-flex flex-column justify-content-start align-items-start">
                        <div className="d-flex flex-row">
                            <div className="type_badge mr-2">{productTypeModel[data.productType.toString()]}</div>
                            <div className="type_badge mr-2">{OrderTypeModel[data.orderType.toString()]}</div>
                            { data.isAmo && (
                                <div className="type_badge">AMO</div>
                            )}
                        </div>
                        <div className="d-flex flex-row mt-1">
                            {data.buySell === 1 ? <div className="buysell_badeg color_buy mr-1">B</div> : <div className="buysell_badeg color_sell mr-3">S</div> }
                            <div className="text_head1">{subData?.short_name}</div>
                        </div>
                    </div>
                    <div className="drover_box">
                       <div className="d-flex h-100 flex-row justify-content-start align-items-center">
                           <div className="w-25">
                               <div onClick={deleteOrder} className="tooltipMenu">
                                   {/*<img src={DeleteIcon} alt={''}/>*/}
                                   <DeleteIconRect/>
                                   <div className="top">
                                       <div>Delete</div>
                                       <i></i>
                                   </div>
                               </div>
                           </div>
                           <div className="w-25">
                               <div onClick={modifyOrder} className="tooltipMenu">
                                   {/*<img src={ModifyIcon} alt={''}/>*/}
                                   <ModifyIconRect/>
                                   <div className="top">
                                       <div>Modify</div>
                                       <i></i>
                                   </div>
                               </div>
                           </div>
                           <div className="w-25">
                               <div onClick={duplicateOrder} className="tooltipMenu">
                                   {/*<img src={AdditemIcon} alt={''}/>*/}
                                   <AdditemIconRect/>
                                   <div className="top">
                                       <div>Duplicate</div>
                                       <i></i>
                                   </div>
                               </div>
                           </div>

                       </div>
                    </div>
                </td>
                <td className="priceTag">{subData?.lp}</td>
                <td className="priceTag">{data.price} </td>
                <td className="priceTag">{data.quantity}</td>
                <td>{getOrderStatus()}</td>
            </tr>

    );
});

export default EachStock;


function oldEchStock() {
    return (
        <div key={id1} onClick={toggleMenu} className="each_box w-100 p-3 mb-2">

            <div className="d-flex flex-row justify-content-between">

                <div className="d-flex flex-row align-items-center">
                    <div className="mr-2"><img alt={''} className="grab" src={MenuIcon}/></div>
                    <div className="d-flex flex-column justify-content-start align-items-start">
                        <div className="d-flex flex-row">
                            <div className="type_badge mr-3">{productTypeModel[data.productType.toString()]}</div>
                            <div className="type_badge">{OrderTypeModel[data.orderType.toString()]}</div>
                        </div>
                        <div className="d-flex flex-row my-2">
                            {data.buySell === 1 ? <div className="buysell_badeg color_buy mr-3">B</div> : <div className="buysell_badeg color_sell mr-3">S</div> }
                            <div className="text_head1">{subData?.short_name}</div>
                        </div>
                        <div className="d-flex flex-row">
                            <div className="text_thin1">LTP : {subData?.lp}</div>
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-column justify-content-end align-items-end">
                    <div className="">
                        <div> {data.orderId}</div>
                        {/*{data?.authoriseStatus === true ? <div className="authorise_box c-pointer ">Authorize <img alt={''} className="ml-1" src={RefreshIcon}/> </div>: <div>&nbsp;</div>}*/}
                    </div>
                    <div className="my-2 text_head1">{data.price * data.quantity}</div>
                    <div className="text_thin1">({data.price} X {data.quantity})</div>
                </div>

            </div>


            <div className="drover_box">
                <div className="d-flex align-items-center justify-content-center flex-row">
                    <div onClick={executeOrderSingle} className="d-flex c-pointer flex-row">
                        <div className="mr-1"><img src={executeIcon} alt={''}/></div>
                        <div className="">Execute</div>
                    </div>

                    <div onClick={modifyOrder} className="ml-2 d-flex c-pointer flex-row">
                        <div className="mr-1"><img src={ModifyIcon} alt={''}/></div>
                        <div className="">Modify</div>
                    </div>

                    <div onClick={duplicateOrder} className="ml-2 d-flex c-pointer flex-row">
                        <div className="mr-1"><img src={AdditemIcon} alt={''}/></div>
                        <div className="">Duplicate</div>
                    </div>

                    <div onClick={deleteOrder} className="ml-2 d-flex c-pointer flex-row">
                        <div className="mr-1"><img src={DeleteIcon} alt={''}/></div>
                        <div className="">Delete</div>
                    </div>
                </div>
            </div>






        </div>
    );
}
