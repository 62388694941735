import React, { useEffect,useState } from 'react';
import {useDispatch,useSelector} from "react-redux";
import Buy from "../../../assets/icons/Buy.svg"
import Sell from "../../../assets/icons/Sell.svg"
import moment from 'moment';
import {DataFeedInstance} from "../../../chart-datafeed/udf-compatible-datafeed";
import {SpotService} from "../../../sub";
import {NumberColor,ExchangeSegmentMap} from "../../common/number_color";

const OptionsCombinations = ({options,currentindex,currentMultiplier,strategyName,bool}) =>{

    const [ subData, setSubData ] = useState(null);
    

    useEffect(() => {
        headerData()
        return () => {
            removeSub()
        }
    }, [options])

    let listenerGuid = undefined
    function headerData() {
        listenerGuid = `cepe-${currentindex}-${options.option.token}-${ExchangeSegmentMap[options.option.exchange]}`
        DataFeedInstance.subcscribeQuotesFromTokens([
            {market_segment_id: ExchangeSegmentMap[options.option.exchange] , token: options.option.token }],(quotes)=>{
            setSubData(quotes[0].v)
        },listenerGuid)
    }

    function removeSub(){
        DataFeedInstance.unsubscribeQuotes(listenerGuid)
    }

    
    function formatdate(date){
        let now = moment(date, "YYYYMMDD").format('DD MMM');
        return now
    }


    if(subData){
        return(
            <div style={{"display":"flex","flexDirection":"row","justifyContent":"space-between"}}>
                <div className='multvalue' style={{"fontSize":"18px"}}>{(options.quantity/options.option.lot_size)*(bool?currentMultiplier:1)}</div>
                <div className="cross" style={{"alignSelf": "center","height":"100%"}}><i className="fa-solid fa-xmark"></i></div>
                <div style={{"position":"relative","top":"2px"}} className=""><img height={"17px"} width={"17px"} src={options.action=="BUY"?Buy:Sell}/></div>
                <div style={{"alignSelf": "center","height":"100%","width":"60px"}} className=" sPrice">{(options.option.strike_price).toFixed(2)}</div>
                <div style={{"alignSelf": "center","height":"100%"}} className="cepe">{options.option.option_type}</div>
                <div style={{"alignSelf": "center","height":"100%","textAlign":"right","width":"50px"}} className="date">{formatdate(options.option.expiry_date)}</div>
                <div id={strategyName+(currentindex+1)} style={{"alignSelf": "center","height":"100%","width":"100px","textAlign":"center"}} className={`ltp_change ${strategyName+(currentindex+1)}`}>{subData?subData.lp.toFixed(2):0}</div>
                <div style={{"alignSelf": "center","height":"100%","color":"#171D27","width":"100px","textAlign":"right"}} className="ltp_change"><NumberColor number={subData?subData.chp:0} returnSign={true}/>&nbsp;<NumberColor number={subData?subData.chp.toFixed(2):0} returnBracket={true} percentageAdd={true}/></div>
            </div>
        )
    }else{
        return(
            <div style={{"display":"flex","flexDirection":"row","justifyContent":"space-between"}}>
                <div className='multvalue' style={{"fontSize":"18px"}}>0</div>
                <div className="cross" style={{"alignSelf": "center","height":"100%"}}><i className="fa-solid fa-xmark"></i></div>
                <div style={{"position":"relative","top":"2px"}} className=""><img height={"17px"} width={"17px"} src={Buy}/></div>
                <div style={{"alignSelf": "center","height":"100%","width":"60px"}} className=" sPrice">0</div>
                <div style={{"alignSelf": "center","height":"100%"}} className="cepe">CE</div>
                <div style={{"alignSelf": "center","height":"100%","textAlign":"right","width":"50px"}} className="date">---</div>
                <div id={strategyName+(currentindex+1)} style={{"alignSelf": "center","height":"100%","width":"100px","textAlign":"center"}} className={`ltp_change ${strategyName+(currentindex+1)}`}>0</div>
                <div style={{"alignSelf": "center","height":"100%","color":"#171D27","width":"100px","textAlign":"right"}} className="ltp_change">0</div>
            </div>
        )
    }
}

export default OptionsCombinations;