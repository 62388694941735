import {BrokerApiInstance} from '../chart-brokerapi/broker'
import {DataFeedInstance} from '../chart-datafeed/udf-compatible-datafeed'
export class ChartWatchlists{
    constructor( chartTvApi){
        this._watchlists = {}
        this._chartTvApi = chartTvApi
        this._broker = BrokerApiInstance
        this._feed = DataFeedInstance
    }

    updateWatchlist(listId){
        if(this._watchlists[listId].readonly){
            window.alert("Changes made to default watchlist will not be stored to server")
            return
        }
        var list = this._chartTvApi.getAllLists()[listId]
        console.error(list)
        this._watchlists[listId].symbols = list 
        this._feed.getTokensFromSymbol(list.symbols).then((result)=>{
            return result.map((symbol)=>{
                return {
                    scripToken: parseInt(symbol.token), 
                    marketSegmentId: parseInt(symbol.market_segment_id), 
                }
            })

        }).then((payload_symbols)=>{
            if(this._watchlists[listId].id == undefined){
                this._broker.createWatchlist({watchlistName: list.title,stocksOrder: payload_symbols})
            }else{
                this._broker.updateWatchlist(this._watchlists[listId].id, {watchlistName: list.title,stocksOrder: payload_symbols})
            }
        })
        
    }
    // type Stock struct {
    //     ScripToken      int `json:"scripToken" binding:"required"`
    //     MarketSegmentId int `json:"marketSegmentId" binding:"required"`
    // }
    
    // type WatchlistCreate struct {
    //     WatchlistName string  `json:"watchlistName"  binding:"required"`
    //     StocksOrder   []Stock `json:"stocksOrder"  binding:"required"`
    // }

    addWatchlist(watchlist){
        var list = this._chartTvApi.createList(watchlist.name,watchlist.symbols)
        this._watchlists[list.id] = watchlist
        if(watchlist.wlPosition==0){
            this._chartTvApi.setActiveList(list.id)
        }
    }

    renameWatchlist(listId,oldName, newName){
        var list = this._chartTvApi.getList(listId)
        this._watchlists[listId].symbols = list 
        if(this._watchlists[listId].readonly){
            window.alert("Changes made to default watchlist will not be stored to server")
            return
        }
        var payload_symbols  = list.map((symbol)=>{
            var aa = this._feed._symbolToTokenMap[symbol]
            return {
                scripToken: parseInt(aa[1]), 
                marketSegmentId: parseInt(aa[0]), 
            }
        })
        this._watchlists[listId].name = newName
        this._broker.updateWatchlist(this._watchlists[listId].id, {watchlistName: newName,stocksOrder: payload_symbols})
    }

    deleteWatchlist(listId){
        if(this._watchlists[listId].readonly){
            window.alert("Changes made to default watchlist will not be stored to server")
            return
        }
        this._broker.deleteWatchlist(this._watchlists[listId].id)
        delete this._watchlists[listId]
    }

    createWatchlist(listId,symbols,name){
        var list = this._chartTvApi.getAllLists()[listId]
        this._watchlists[listId] ={}
        var chartTvApi = this._chartTvApi
        var watchlists = this._watchlists
        var feed = this._feed
        var broker = this._broker
        var interval = setInterval(function(){
            if(chartTvApi.getAllLists()[listId] != undefined){
                if( watchlists[listId] == undefined || Object.keys(watchlists[listId]).length == 0 ){
                    var list = chartTvApi.getAllLists()[listId]
                    watchlists[listId] ={symbols: []}
                    var payload_symbols  = list.symbols.map((symbol)=>{
                        var aa = feed._symbolToTokenMap[symbol]
                        return {
                            scripToken: parseInt(aa[1]), 
                            marketSegmentId: parseInt(aa[0]), 
                        }
                    })
                    broker.createWatchlist({watchlistName: list.title,stocksOrder: payload_symbols}).then((response)=>{
                        watchlists[listId].id = response.response.ID
                    })
                    
                }
                clearInterval(interval)
            }
        },200)        
    }

}
