import React, {useEffect, useState} from "react";
import {DataFeedInstance} from "../../chart-datafeed/udf-compatible-datafeed";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {numDifferentiation} from "../../comman/status";
import nextId from "react-id-generator";
import {NumberColor,ExchangeSegmentMap} from "../common/number_color";
import BuySellButton from "./BuySellButton"
import {SpotService, ceService, BuySellServices, layoutSubServices,ceIoService} from "../../sub";
import VisibilitySensor from 'react-visibility-sensor'


const CallData = ({data,sp,showZeroOi,vega,theta,gamma,delta})=>{
    const [ yellowShade, setYellowShade ] = useState(false)
    const [ subData, setSubData ] = useState(null)
    const [ barData, setBarData ] = useState(0);
    const [ barIoData, setBarIoData ] = useState(0);
    const [ max, setMax ] = useState(0);
    const [ maxIo, setMaxIo ] = useState(0);
    const [ buySellSymbol, setBuySellSymbol ] = useState(null);
    const [changeOi,setChangeOi] = useState(null)
    const [ltpChangePercent,setLtpChangePercent] = useState(0)
    const [ltpChangeClass,setLtpChangeClass]=useState(null)
    const [oiChangePercent,setOiChangePercent] = useState(0)
    const [oiChangeClass,setOiChangeClass]=useState(null);
    const [guide,setGuide] =useState(null)


    function calPercentage(minvolume, maxVolume) {
        let width=0
        if(maxVolume>0 && maxVolume!=="NAN"){
             width =  (minvolume/maxVolume) * 100
        }       setBarData((width/100)*80)
    }
    function calIoPercentage(minimumIo, maximumIo) {
        let width=0
        if(maximumIo>0 && maximumIo!=="NAN"){
             width =  (minimumIo/maximumIo) * 100
        }        setBarIoData((width/100)*80)
    }
    useEffect(() => {
        if (subData) {
            ceService.sendMax(Number(subData.volume))
            // setVolumeCE(Number(subData.oi))
            // calPercentage(Number(subData.oi),maxVolumeCE)
        }
    }, [subData?.volume,max])
    useEffect(() => {
        if (subData) {
            ceIoService.sendMaxIo(Number(subData.oi))
            // setVolumeCE(Number(subData.oi))
            // calPercentage(Number(subData.oi),maxVolumeCE)
        }
    }, [subData?.oi,maxIo])

    useEffect(()=>{
        if(subData) changeOiData()
    })
    useEffect(()=>{
        if(subData) checkLtpChangePercentage()
    })
    useEffect(()=>{
        if(subData) checkOiChangePercentage()
    })

    useEffect(() => {
        if(subData) calPercentage(Number(subData.volume),max)
    })

    useEffect(() => {
        if(subData) calIoPercentage(Number(subData.oi),maxIo)
    })


   



    useEffect(()=>{
            if(data) filterDataZeroOi(data.openInterest)
    },[showZeroOi])



    useEffect(() => {
        ceService.resetVolumeCE();
        ceIoService.resetIoCE()
        return () => {
            removeSub()
        }
    }, [data])


    let listenerGuid = undefined

    function callSub() {
        listenerGuid = `ce-${data.option.token}-${ExchangeSegmentMap[data.exchange]}`
        setGuide(listenerGuid)
        DataFeedInstance.subcscribeQuotesFromTokens([{market_segment_id: ExchangeSegmentMap[data.exchange] , token: data.option.token }],(quotes)=>{
            setBuySellSymbol(quotes[0].n)
            setSubData(quotes[0].v)
        },listenerGuid)
    }

    let subscription = undefined;
    let subscriptionCE = undefined;
    let subscriptionIoCE = undefined;

    useEffect(() => {
        subscription = SpotService.getSpot().subscribe(value => {
            const val = value.lp
            setYellowShade(val)
        });

        subscriptionCE = ceService.getSub().subscribe(value => {
            setMax(value)
        });
        subscriptionIoCE = ceIoService.getSubIo().subscribe(value => {
            setMaxIo(value)
        });

        return () => {
            subscription?.unsubscribe();
            subscriptionCE?.unsubscribe();
            subscriptionIoCE?.unsubscribe();
        }
    }, [])

    function changeOiData(){
        let data = parseFloat(subData.oi_ch)
        if(data>=0){
            setChangeOi(subData.oi-(subData.oi/(100+data))*100)
        }else{ 
            setChangeOi((subData.oi/(100+data))*100-subData.oi)
        }
    }

    function filterDataZeroOi(OIdata){
        if(OIdata==0){
            const element = document.getElementById(sp)
            if(showZeroOi===false){
                element.style.display = "none"
            }else{
                element.style.display = "revert"
            }
        }
    }

    function calculateGreeks(value,marketId){
        var returnValue
        if(marketId=="13"){
            returnValue= value/10000000
        }else{
            returnValue= value/100
        }
        if(parseFloat(returnValue*1000)>0){
            return returnValue.toFixed(3)
        }
        else{
            return "0"
        }
    }

    function checkLtpChangePercentage(){
        let sign = Math.sign(subData.chp)
        if(sign===1){
            setLtpChangePercent("(+"+subData.chp.toFixed(2)+"%)")
            setLtpChangeClass("greenText1")
        }else if(sign===-1){
            setLtpChangePercent("("+subData.chp.toFixed(2)+"%)")
            setLtpChangeClass("redText1")
        }else if(sign===0){
            setLtpChangePercent("(0%)")
            setLtpChangeClass("greenText1")
        }else if(sign===-0){
            setLtpChangePercent("(0%)")
            setLtpChangeClass("redText1")
        }else{
            setLtpChangePercent("(0%)")
            setLtpChangeClass("greenText1")
        }
    }

    function checkOiChangePercentage(){
        let sign = Math.sign(parseFloat(subData.oi_ch))
        if(sign===1){
            setOiChangePercent("(+"+parseFloat(subData.oi_ch).toFixed(2)+"%)")
            setOiChangeClass("greenText1")
        }else if(sign===-1){
            setOiChangePercent("("+parseFloat(subData.oi_ch).toFixed(2)+"%)")
            setOiChangeClass("redText1")
        }else if(sign===0){
            setOiChangePercent("(0%)")
            setOiChangeClass("greenText1")
        }else if(sign===-0){
            setOiChangePercent("(0%)")
            setOiChangeClass("redText1")
        }else{
            setOiChangePercent("(0%)")
            setOiChangeClass("greenText1")
        }
    }

    function onChange (isVisible) {
        if(isVisible){
            callSub()
        }else{
            if(guide) removeSub()
        }
      }
    const OpenBuyWindow = () => {
           let data = { ...subData ,buySellSymbol,"type":"B","option_type:":"CE"};
           BuySellServices.setBuySell(data)
       }
    
        const OpenSellWindow = () => {
            let data = { ...subData ,buySellSymbol,"type":"S","option_type:":"CE"};
            BuySellServices.setBuySell(data)
        }
    
        const OpenChartWindow = () => {
            let data = { ...subData ,buySellSymbol,"type":"","option_type:":""};
            layoutSubServices.setLayOut(data)
        }
        // useEffect(()=>{
        //     return ()=>{
        //         removeSub()
        //     }
        // },[])

    function removeSub(){
        DataFeedInstance.unsubscribeQuotes(guide)
        setSubData(null)
    }

    // if(subData==null){
    //     return(
    //         <td id="head_border_bsc">
    //         <Row className='flex-nowrap p-0'>
    //             <Col className={`${vega} chainValue optionChainHeader p-0`} name="vega" id="vega">
    //                 <div className="callChainValue">{data.greeks.vega?data.marketSegmentId==13?((data.greeks.vega)/10000000).toFixed(2):((data.greeks.vega)/100).toFixed(2):0}</div>
    //             </Col>
    //             <Col className={`${theta}  chainValue optionChainHeader p-0`} name="theta" id="theta">
    //                 <div className="callChainValue">{data.greeks.theta?data.marketSegmentId==13?((data.greeks.theta)/10000000).toFixed(2):((data.greeks.theta)/100).toFixed(2):0}</div>        
    //             </Col>
    //             <Col className={`${gamma}  chainValue optionChainHeader p-0`} name="gamma" id="gamma">
    //                 <div className="callChainValue">{data.greeks.gamma?data.marketSegmentId==13?((data.greeks.gamma)*10000000).toFixed(4):((data.greeks.gamma)*100).toFixed(4):0}</div>
    //             </Col>
    //             <Col className={`${delta}  chainValue optionChainHeader p-0`} name="delta" id="delta">
    //                 <div className="callChainValue">{data.greeks.delta?data.greeks.delta.toFixed(2):0}</div>
    //             </Col>
    //             <Col  className="optionChainHeader p-0">
    //                 <div style={{"position":"relative"}}>
    //                      <div className="percentageBar callBar" style={{"width":barIoData}}></div>
    //                      <div className={`chainValue callChainValue`}>0</div>
    //                 </div>    
    //             </Col>
    //             <Col  className="optionChainHeader p-0" style={{"width":"140px"}}>
    //                 <div className={`chainValue callChainValue`}>0</div>
    //             </Col>
    //             <Col  className="optionChainHeader p-0">
    //                 <div style={{"position":"relative"}}>
    //                     <div className="percentageBar callBar" style={{"width":barData}}></div>
    //                     <div className={`chainValue callChainValue`}>0</div>
    //                 </div>
    //             </Col>
    //             <Col  className="optionChainHeader " style={{"width":"140px"}}>
    //                 <div className={`chainValue callChainValue`} style={{"paddingLeft":"0px"}}>0</div>
    //             </Col>
    //         </Row>
    //     </td>

    //     )
    // }

    return(
        <VisibilitySensor onChange={onChange} partialVisibility={true}>
    <td id="head_border_bsc">
        <div id="buy_sell_call">
                <div className="bsc_buttons">
                    <BuySellButton buy={OpenBuyWindow} sell={OpenSellWindow} chart={OpenChartWindow}/>
                </div>
        </div>
        <Row className='flex-nowrap p-0'>
            <Col className={`${vega} chainValue optionChainHeader p-0`} name="vega" id="vega">
                <div className="callChainValue">{data.greeks.vega}</div>
            </Col>
            <Col className={`${theta}  chainValue optionChainHeader p-0`} name="theta" id="theta">
                <div className="callChainValue">{data.greeks.theta}</div>        
            </Col>
            <Col className={`${gamma}  chainValue optionChainHeader p-0`} name="gamma" id="gamma">
                <div className="callChainValue">{data.greeks.gamma}</div>
            </Col>
            <Col className={`${delta}  chainValue optionChainHeader p-0`} name="delta" id="delta">
                <div className="callChainValue">{data.option.delta}</div>
            </Col>
            <Col  className="optionChainHeader p-0">
                <div style={{"position":"relative"}}>
                     <div className="percentageBar callBar" style={{"width":barIoData}}></div>
                     <div className={`chainValue callChainValue`}>{numDifferentiation(subData?subData.oi:'0')}</div>
                </div>    
            </Col>
            <Col  className="optionChainHeader p-0" style={{"width":"140px"}}>
                <div className={`chainValue callChainValue`}>{changeOi?numDifferentiation(changeOi.toFixed(2)):'0'} <span className={`${oiChangeClass}`} style={{"fontSize":"10px"}}>{oiChangePercent}</span></div>
            </Col>
            <Col  className="optionChainHeader p-0">
                <div style={{"position":"relative"}}>
                    <div className="percentageBar callBar" style={{"width":barData}}></div>
                    <div className={`chainValue callChainValue`}>{numDifferentiation(subData?subData.volume:'0') }</div>
                </div>
            </Col>
            <Col  className="optionChainHeader " style={{"width":"140px"}}>
                <div className={`chainValue callChainValue`} style={{"paddingLeft":"0px"}}>{subData?subData.lp:'0'} <span className={`${ltpChangeClass}`} style={{"fontSize":"10px"}}>{ltpChangePercent}</span></div>
            </Col>
        </Row>
    </td>
    </VisibilitySensor>
    )
}

export default CallData