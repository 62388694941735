import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal'
import OtpInput from "react-otp-input";
import refresh from "../../assets/images/refresh-circle.svg";
import Spinner from "react-bootstrap/Spinner";
import Timer from "../common/timer";
import authService from "../../services/authService";
import {toast} from "react-toastify";


const OtpModel = forwardRef(({isLoading, optVerify,callForgetPassword}, ref) => {

    const [show, setShow] = useState(false);
    const [showResetBtn, setShowResetBtn] = useState(false);
    const [Otp, setOtp] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setOtp('')
        setShowResetBtn(false)
        setShow(true)
    };

    useImperativeHandle(ref, () => ({

        callChildMethodOpen() {
            handleShow()

        },

        callChildMethodClose() {
            handleClose()
        },

        toggleRestBtn(){
            showResend()
        }

    }));

    const showResend = () => {
        setShowResetBtn(!showResetBtn)
    }

    function resendPassword() {
        callForgetPassword(false)
    }

    return (
        <>
            <Modal contentClassName="modal-content2" centered show={show} backdrop="static" onHide={handleClose}>
                <Modal.Body>

                    <div className="d-flex flex-column model_padding justify-content-center align-items-center">

                        <button onClick={handleClose} className="align-self-end btn-close"/>

                        <h3 className="heading1 py-md-0 py-lg-2 align-self-start">OTP Verification</h3>

                        <div className="colorGrey pb-3 align-self-start">We have sent an OTP on your mobile & email. <br/>Please enter the OTP here to complete the verification</div>


                        <OtpInput
                            inputStyle="opt_box"
                            value={Otp}
                            onChange={setOtp}
                            numInputs={6}
                            isInputNum={true}
                            separator={<span/>}
                        />



                        <div className="colorBlue align-self-start py-2">

                            {showResetBtn ? <div className="d-flex flex-row c-pointer">
                                    {isLoading ? (
                                        <Spinner className="mr-2" as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                    ) : <span onClick={resendPassword}><img src={refresh} alt={''}/>Resend OTP</span> }
                            </div>
                                : <div className="d-flex flex-row justify-content-start align-items-center">
                                    <div className="colorGrey">OTP can be resent in:</div>
                                    <Timer initialSeconds={59} initialMinute={0} Otp={Otp} showResend={showResend} />
                                </div> }

                        </div>

                        <Button disabled={isLoading || Otp.length !== 6} className="blue_btn w-100 my-3" onClick={() => optVerify(Otp)} variant="primary" type="submit">
                            {isLoading && (
                                <Spinner className="mr-2" as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                            )}
                            Verify OTP
                        </Button>

                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
});


export default OtpModel;
