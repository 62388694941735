import React, {useEffect, useRef, useState} from 'react';
import Background from "../../BackGround/background";
import {Link, useNavigate} from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import MadeInLoveIndia from "../../common/madeInLoveIndia";
import {useDispatch, useSelector} from "react-redux";
import authService from "../../../services/authService";
import {toast} from "react-toastify";
import {setOtpData} from "../../../slices/authSlice";
import OtpModel from "../../ForgetPassword/otpModel";

function ForgotMpin(props) {


    const { user,mobile } = useSelector((state) => state.authUser);

    const [formState, setFormState] = useState({ dynamicValue: ''});
    const [ errors, setErrors ] = useState({})
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const childRef = useRef();


    useEffect(()=>{
      if(mobile === null)  dispatch(authService.GetUserProfile(user.user_id))
    },[])

    useEffect(()=>{
        if(mobile !== null) setFormState({ ...formState, dynamicValue: mobile });
    },[mobile])

    const onlyNumber = (event) => {
            if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
            }
    }

    function handleFormStateChange(event)  {
        const { name, value } = event.target;

        setFormState({ ...formState, [name]: value });

        if ( !!errors[name] ) setErrors({
            ...errors,
            [name]: null
        })
    }


    function handleOnClick(event) {
        event.preventDefault();

        // get our new errors
        const newErrors = findFormErrors()
        // Conditional logic:
        if ( Object.keys(newErrors).length > 0 ) {
            setErrors(newErrors)
        } else {
            callForgetPassword(true);
        }

    }

    function callForgetPassword(modelOpen) {
        setIsLoading(true)
        let data = {
            user_id: user.user_id,
            // mobileNo: formState.dynamicValue,
        }
        authService.ForgetMpinOtpSend(data)
            .then(value => {
                if(value.data.status === "success"){
                    toast.success(value.data.message)
                    modelOpen ?  OpenChildModel(): toggleRestBtn();
                }
            })
            .catch(error => {
                toast.error(`${error.response.data.message}`)
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }


    const findFormErrors = () => {
        const { dynamicValue } = formState
        const newErrors = {}
        // name errors
        if ( !dynamicValue || dynamicValue === '' ) newErrors.dynamicValue = 'Mobile Number is required!'
        else if ( dynamicValue.length !== 10 ) newErrors.dynamicValue = '10 digit Mobile Number is required!'

        return newErrors
    }

    const optVerify = (Otp) => {
        if(Otp.length === 6){
            if(mobile == null){
                toast.error(`Mobile num Not Found try again`)
                dispatch(authService.GetUserProfile(formState.user_id))
                return
            }
            setIsLoading(true);

            let data = {
                user_id: user.user_id,
                mobileNo: formState.dynamicValue,
                otp: Otp,
            }

            authService.MpinOtpVerify(data)
                .then(value => {
                    if(value.data.status === "success"){
                        toast.success(value.data.message)
                        let data = {data : value.data.data, user_id: user.user_id}
                        dispatch(setOtpData(data))
                        CloseChildModel()
                        navigate('/MPinSet', {state: {type: 'OTP'},replace: true});
                    }
                })
                .catch(error => {
                    toast.error(`${error.response.data.message}`)
                    setIsLoading(false);
                })
                .finally(() => {
                    setIsLoading(false);
                })
        }
    }



    const OpenChildModel = () => {
        childRef.current.callChildMethodOpen();
    }

    const CloseChildModel = () => {
        childRef.current.callChildMethodClose();
    }

    const toggleRestBtn = () => {
        childRef.current.toggleRestBtn();
    }


    return (
        <Background>

            <div className="h-100 p-lg-5 p-5 d-flex flex-column justify-content-between " >

                <div className="">

                    <div className="py-2">
                        <Link to="/Mpin"><i className="fas fa-arrow-left fas fa-lg"/></Link>
                    </div>

                    <h3 className="heading1 py-1">Forgot PIN</h3>

                    <div className="colorGrey pt-0">Recover Your pin for {user.user_id} Account will sent on your contact details:</div>


                </div>


                <div className="align-self-center flex-fill w-100">

                    <Form className="pt-3 w-100">
                        <Form.Group className="mb-2" controlId="formBasicEmail">
                            <Form.Label className="text1 w-100">
                                <div className="d-flex flex-row justify-content-between align-items-center">
                                    <div className="">Mobile Number</div>
                                    <div className="">&nbsp;</div>
                                </div>
                            </Form.Label>
                            <Form.Control type="text"
                                          name="dynamicValue"
                                          required
                                          onKeyPress={onlyNumber}
                                          isInvalid={ !!errors.dynamicValue }
                                          value={formState.dynamicValue}
                                          onChange={handleFormStateChange}
                                          placeholder="Enter Your Mobile Number" />
                            <Form.Control.Feedback type='invalid'>
                                { errors.dynamicValue }
                            </Form.Control.Feedback>
                        </Form.Group>

                    </Form>


                </div>


                <div className="">

                    <Button disabled={isLoading} className="blue_btn w-100 my-3" onClick={handleOnClick} variant="primary" type="submit">
                        {isLoading && (
                            <Spinner className="mr-2" as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                        )}
                        Get OTP
                    </Button>


                    <MadeInLoveIndia/>
                </div>



            </div>

            <OtpModel ref={childRef} optVerify={optVerify} isLoading={isLoading} callForgetPassword={callForgetPassword}/>


        </Background>
    );
}

export default ForgotMpin;
